import React, { useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;


interface DataObject {
    [key: string]: any;
}


// URL-safe Base64 인코딩 함수
const base64UrlEncode = (str: string): string => {
    return str.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
};


// URL-safe Base64 디코딩 함수
const base64UrlDecode = (str: string): string => {
    str = str.replace(/-/g, '+').replace(/_/g, '/');
    while (str.length % 4) {
        str += '=';
    }
    return str;
};


export function useCryptoData() {
    const handleEncrypt = (eObject: DataObject) => {
        const dataString = JSON.stringify(eObject);
        const encrypted = CryptoJS.AES.encrypt(dataString, SECRET_KEY as string).toString();
        const base64Encoded = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encrypted));
        return base64UrlEncode(base64Encoded);
    };

    const handleDecrypt = (dString: string) => {
        const base64Decoded = base64UrlDecode(dString);
        const encrypted = CryptoJS.enc.Base64.parse(base64Decoded).toString(CryptoJS.enc.Utf8);
        const bytes = CryptoJS.AES.decrypt(encrypted, SECRET_KEY as string);
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return decryptedData;
    };

    return { handleEncrypt, handleDecrypt }
};