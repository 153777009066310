import React, { useState, ChangeEvent, useRef, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  SelectChangeEvent,
} from "@mui/material";
import * as S from "../styles/AdminStyles";
import Pagination from "@mui/material/Pagination";
import { useSearchOptions } from "../../../utils/customHooks/useInputData";
import { YYYY_MM_DD, diffInDays, useQuickDatePicker } from "../../../utils/customHooks/useDatePicker";
import { useCryptoData } from "../../../utils/common/crypto";
import { useAdminList } from "../../../utils/apiHooks/useAdminData";

export default function Admin() {

  type DataItem = {
    no: number;
    id: string;
    name: string;
    accessDate: string;
    type: string;
    status: string;
    createDate: string;
    updateDate: string;
  };


  const [orderDirection, setOrderDirection] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState("no");
  const navigate = useNavigate();
  const [adminData, setAdminData] = useState([]);
  const [page, setPage] = useState(1);
  const [activeButton, setActiveButton] = useState("month");
  const { options, updateOptions } = useSearchOptions({
    orderby: orderBy,
    dateType: "create_date",
    scending: orderDirection.toUpperCase(),
    sDate: "",
    eDate: "",
    type: "",
    searchType: "id",
    search: "",
    limit: 15,
    page: 1,
  });



  const { changeDate } = useQuickDatePicker(updateOptions);
  const { handleEncrypt } = useCryptoData();
  const initialRender = useRef(true);


  const onSuccessAdminList = useCallback((data: any) => {

    const { results } = data.data;
    console.log(results);

    results.map((v: any, k: any) => {
      v.no = k + 1;
      return v;
    });

    setAdminData(results);
  }, []);

  const {
    mutate: getAdminList,
    data: adminListData,
    isSuccess,
  } = useAdminList(onSuccessAdminList);

  useEffect(() => {
    if (isSuccess) {
      console.log("adminListData : ", adminListData);
    }
  }, [isSuccess, adminListData]);

  useEffect(() => {
    updateOptions("page", page);
  }, [page]);


  const handlePageChange = () => {};

  const handleRowClick = async (pk: string, id: string) => {
    const userPkData = {
      pk,
      id
    };
    const encryptedData = await handleEncrypt(userPkData);

    const newUrl = `/update-admin/${encryptedData}`;
    navigate(newUrl);
  };


  // 검색 버튼
  const search = () => {
    const diff = diffInDays(options.sDate, options.eDate);

    if (options.sDate === "" || options.eDate === "") {
      alert("조회 기간을 선택해주세요");
    } else if (diff > 365) {
      alert("조회 가능 기간은 최대 1년입니다");
    } else {
      const ops = { ...options };
      getAdminList(ops);
    }
  }


  // 보기 개수 변경
  const changeLimit = async (e: SelectChangeEvent<unknown>) => {
    await updateOptions("limit", Number((e.target as HTMLSelectElement).value));

    search();
  };

  const handlePeriodChange = (type: string) => {
    setActiveButton(type);
    changeDate(type);
  };


  useEffect(() => {
    changeDate("month");
  }, []);

  // 초기 렌더링 시에만 리스트 자동 호출
  useEffect(() => {
    if (initialRender.current && options.sDate && options.eDate) {
      initialRender.current = false;
      const ops = { ...options };
      getAdminList(ops);
    }
  }, [options, getAdminList]);

  return (
    <S.Container>
      <S.Typography>운영자관리 {">"} 운영자조회/수정</S.Typography>
      <S.Paper elevation={0}>
        <S.Grid
          container
          spacing={1}
          alignItems="center"
          style={{ padding: "0 20px", margin: "0" }}
        >
          <S.InputLabel>조회기간</S.InputLabel>
          <S.Grid item xs={6}>
            {" "}
            <Box
              sx={{
                mr: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <S.Select
                style={{ width: "8em" }}
                defaultValue="create_date"
                onChange={(e) =>
                  updateOptions(
                    "dateType",
                    (e.target as HTMLSelectElement).value
                  )
                }>
                <S.MenuItem value="create_date">등록일</S.MenuItem>
                <S.MenuItem value="update_date">수정일</S.MenuItem>
              </S.Select>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                mr: 2,
              }}
            >
              <S.Input
                type="date"
                id="start-date"
                name="start-date"
                style={{ width: "180px" }}
                value={YYYY_MM_DD(options.sDate)}
                onChange={(e) =>
                  updateOptions("sDate", String(new Date(e.target.value)))
                }
              />
              ~
              <S.Input
                type="date"
                id="end-date"
                name="end-date"
                style={{ width: "180px" }}
                value={YYYY_MM_DD(options.eDate)}
                onChange={(e) =>
                  updateOptions("eDate", String(new Date(e.target.value)))
                }
              />
            </Box>
          </S.Grid>
          <S.Grid item>
            <S.NormalButton
              variant="contained"
              sx={{ mr: 1 }}
              // active={activeButton === "day"}
              onClick={() => handlePeriodChange("day")}>
              오늘
            </S.NormalButton>
            <S.NormalButton variant="contained" sx={{ mr: 1 }}
              // active={activeButton === "week"}
              onClick={() => handlePeriodChange("week")}
            >
              1주일
            </S.NormalButton>
            <S.NormalButton variant="contained" sx={{ mr: 1 }}
              // active={activeButton === "month"}
              onClick={() => handlePeriodChange("month")}
            >
              1개월
            </S.NormalButton>
            <S.NormalButton variant="contained" sx={{ height: "2.5em" }}
              // active={activeButton === "quarter"}
              onClick={() => handlePeriodChange("quarter")}
            >
              3개월
            </S.NormalButton>
          </S.Grid>
        </S.Grid>
        <S.Grid
          container
          spacing={1}
          alignItems="center"
          style={{ padding: "0 20px", margin: "0" }}
        >
          <S.InputLabel>권한</S.InputLabel>
          <S.Grid item xs={8}>
            <S.RadioGroup row onChange={(e) => updateOptions("type", e.target.value)} defaultValue={""}>
              <FormControlLabel value="" control={<Radio />} label="전체" />
              <FormControlLabel
                value="99"
                control={<Radio />}
                label="일반관리자"
              />
              <FormControlLabel
                value="98"
                control={<Radio />}
                label="질병관리사"
              />
              <FormControlLabel
                value="97"
                control={<Radio />}
                label="슈퍼관리자"
              />
            </S.RadioGroup>
          </S.Grid>
        </S.Grid>
        <S.Grid
          container
          spacing={1}
          alignItems="center"
          style={{ padding: "0 20px", margin: "0" }}
        >
          <S.InputLabel>검색</S.InputLabel>
          <S.Grid item xs={10}>
            <S.Select defaultValue="id" sx={{ width: "8em", height: "2.5em", mr: 1 }}
              onChange={(e) =>
                updateOptions(
                  "searchType",
                  (e.target as HTMLSelectElement).value
                )
              }>
              <S.MenuItem value="id">아이디</S.MenuItem>
              <S.MenuItem value="name">이름</S.MenuItem>
            </S.Select>
            <S.Input type="text" onChange={(e) => updateOptions("search", e.target.value)} />
          </S.Grid>
        </S.Grid>
        <S.Grid container spacing={1} alignItems="center">
          <S.Grid
            item
            xs={12}
            style={{
              alignItems: "center",
              justifyContent: "center",
              margin: "0",
            }}
          >
            <S.SearchButton
              variant="contained"
              color="primary"
              style={{ width: "130px" }}
              onClick={() => search()}
            >
              검색
            </S.SearchButton>
          </S.Grid>
        </S.Grid>
      </S.Paper>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
          marginTop: "30px",
        }}
      >
        <div>총 {isSuccess && adminListData.data.totalCnt}건</div>
        <div
          style={{
            height: "2.8em",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <S.Select defaultValue={15} sx={{ mr: 1 }} onChange={(e) => changeLimit(e)}>
            <S.MenuItem value={15}>15개씩 보기</S.MenuItem>
            <S.MenuItem value={50}>50개씩 보기</S.MenuItem>
            <S.MenuItem value={100}>100개씩 보기</S.MenuItem>
          </S.Select>
          <S.AddButton
            variant="contained"
            onClick={() => navigate("/add-admin")}
          >
            운영자 등록
          </S.AddButton>
        </div>
      </div>
      <S.Paper elevation={0}>
        <S.TableContainer>
          <S.Table>
            <S.TableHead>
              <S.TableHeadRow>
                <S.TableHeadCell align="center">
                  <S.TableSortLabel
                    active={orderBy === "no"}
                    hideSortIcon={false}
                    direction={
                      orderBy === "no"
                        ? ["asc", "desc"].includes(orderDirection)
                          ? orderDirection
                          : "asc"
                        : "asc"
                    }
                    // onClick={() => handleSortRequest("no")}
                  >
                    No
                  </S.TableSortLabel>
                </S.TableHeadCell>
                <S.TableHeadCell align="center">
                  <S.TableSortLabel
                    active={orderBy === "id"}
                    hideSortIcon={false}
                    direction={
                      orderBy === "id"
                        ? ["asc", "desc"].includes(orderDirection)
                          ? orderDirection
                          : "asc"
                        : "asc"
                    }
                    // onClick={() => handleSortRequest("user_id")}
                  >
                    아이디
                  </S.TableSortLabel>
                </S.TableHeadCell>
                <S.TableHeadCell align="center">
                  <S.TableSortLabel
                    active={orderBy === "name"}
                    hideSortIcon={false}
                    direction={
                      orderBy === "name"
                        ? ["asc", "desc"].includes(orderDirection)
                          ? orderDirection
                          : "asc"
                        : "asc"
                    }
                    // onClick={() => handleSortRequest("name")}
                  >
                    이름
                  </S.TableSortLabel>
                </S.TableHeadCell>
                <S.TableHeadCell align="center">최근접속일</S.TableHeadCell>
                <S.TableHeadCell align="center">권한</S.TableHeadCell>
                <S.TableHeadCell align="center">계정상태</S.TableHeadCell>
                <S.TableHeadCell align="center">
                  <S.TableSortLabel
                    active={orderBy === "cDate"}
                    hideSortIcon={false}
                    direction={
                      orderBy === "cDate"
                        ? ["asc", "desc"].includes(orderDirection)
                          ? orderDirection
                          : "asc"
                        : "asc"
                    }
                    // onClick={() => handleSortRequest("registDate")}
                  >
                    등록일시
                  </S.TableSortLabel>
                </S.TableHeadCell>
                <S.TableHeadCell align="center">
                  <S.TableSortLabel
                    active={orderBy === "uDate"}
                    hideSortIcon={false}
                    direction={
                      orderBy === "uDate"
                        ? ["asc", "desc"].includes(orderDirection)
                          ? orderDirection
                          : "asc"
                        : "asc"
                    }
                    // onClick={() => handleSortRequest("updateDate")}
                  >
                    수정일시
                  </S.TableSortLabel>
                </S.TableHeadCell>
              </S.TableHeadRow>
            </S.TableHead>
            <S.TableBody>
              {isSuccess && adminData.length > 0 ? (
                adminData.map((row: any, index: any) => (
                  <S.TableRow
                    key={index}
                    onClick={() => handleRowClick(row.userPK, row.id)}
                  >
                    <S.TableCell align="center">{row.no}</S.TableCell>
                    <S.TableCell>{row.id}</S.TableCell>
                    <S.TableCell align="center">{row.name}</S.TableCell>
                    <S.TableCell align="center">{row.accessDate}</S.TableCell>
                    <S.TableCell align="center">{row.typeName}</S.TableCell>
                    <S.TableCell align="center">{row.status}</S.TableCell>
                    <S.TableCell align="center">{row.createDate}</S.TableCell>
                    <S.TableCell align="center">{row.updateDate}</S.TableCell>
                  </S.TableRow>
                ))
              ) : (
                <S.TableRow>
                  <S.TableCell
                    align="center"
                      colSpan={8}
                      style={{ height: "110px" }}
                  >
                    검색 결과가 없습니다
                  </S.TableCell>
                </S.TableRow>
              )}
            </S.TableBody>
          </S.Table>
        </S.TableContainer>
      </S.Paper>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "30px",
          marginBottom: "30px",
        }}
      >
        <Pagination count={
          isSuccess && adminListData.data.totalPage
            ? adminListData.data.totalPage
            : 1} color="standard" onChange={handlePageChange}
          page={page} />
      </Box>
    </S.Container>
  );
}
