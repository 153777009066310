import React, { useState, useCallback, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { FormControlLabel, Radio } from "@mui/material";
import * as S from "../styles/QnaDetailStyles";
import {
  useQuestionDetail,
  useQuestionUpdate,
} from "../../../utils/apiHooks/useQuestionData";
import { useSearchOptions } from "../../../utils/customHooks/useInputData";
import { Popup } from "../../../components/popup/Popup";

interface Question {
  idx?: number;
  createDate?: string;
  answerDate?: string;
  email?: string;
  name?: string;
  title?: string;
  status?: string;
  content?: string;
  file?: string;
  fileName?: string;
  answer?: string;
  attachment?: string;
}

export default function QnaDetail() {
  const { idx } = useParams<{ idx: string }>();
  const [questionDetail, setQuestionDetail] = useState<Question | null>(null);
  const [isSaveBtnRemove, setIsSaveBtnRemove] = useState(false);
  const { options, updateOptions } = useSearchOptions({
    // idx: "", 고정값
    status: "",
    answer: "",
  });
  const navigate = useNavigate();
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [popupOptions, setPopupOptions] = useState({
    content: "",
    type: "confirm",
    callback: () => {
      console.log("popup closed!");
    },
    isOpenPopup,
    setIsOpenPopup,
  });

  // ---------- useMutation : 1:1문의 상세 가져오기 ----------
  const onSuccessQuestionDetail = useCallback((data: any) => {
    const { result } = data.data;

    const formattedResults: Question = {
      ...result,
      createDate: result.createDate,
      answerDate: result.answerDate,
      email: result.email,
      name: result.name,
      status: result.status,
      title: result.title,
      content: result.content,
      file: result.attachment,
      // fileName: ,
      answer: result.answer,
    };

    setQuestionDetail(formattedResults);
    setIsSaveBtnRemove(formattedResults.status === "Y" ? true : false);
  }, []);

  const onErrorQuestionDetail = (error: any) => {
    console.log("error: ", error);
  };

  const { mutate: getQuestionDetail } = useQuestionDetail(
    onSuccessQuestionDetail,
    onErrorQuestionDetail
  );

  // ---------- useMutation : 문의답변 업데이트 -------------
  const onSuccessQuestionUpdate = useCallback((data: any) => {
    console.log(data);
    setPopupOptions((prevOptions) => ({
      ...prevOptions,
      content: "수정이 완료되었습니다.",
      type: "info",
      callback: () => navigate("/qna"),
    }));
    setIsOpenPopup(true);
  }, []);

  const onErrorQuestionUpdate = useCallback((error: any) => {
    console.log(error);
  }, []);

  const { mutate: updateQuestion } = useQuestionUpdate(
    onSuccessQuestionUpdate,
    onErrorQuestionUpdate
  );

  // -------------------------------------------------------

  const handleStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateOptions("status", e.target.value);
  };

  const handleAnswerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateOptions("answer", e.target.value);
  };

  const handleBackClick = () => {
    navigate("/qna");
  };

  const save = () => {
    const ops = { ...options, idx: Number(idx) };

    if (options.answer.length <= 0) {
      setPopupOptions({
        ...popupOptions,
        content: "문의 답변을 입력해주세요.",
        type: "info",
        callback: () => console.log("문의 답변을 입력해주세요."),
      });
      setIsOpenPopup(true);
    } else {
      setPopupOptions({
        ...popupOptions,
        content: "해당 내용으로 수정하시겠습니까?",
        type: "confirm",
        callback: () => updateQuestion(ops),
      });
      setIsOpenPopup(true);
    }
  };

  // ------------------ useEffect ------------------
  useEffect(() => {
    if (idx) {
      getQuestionDetail(Number(idx));
      console.log(questionDetail);
    }
  }, [idx, getQuestionDetail]);

  useEffect(() => {
    if (questionDetail?.status) {
      updateOptions("status", questionDetail.status);
    }
    if (questionDetail?.answer) {
      updateOptions("answer", questionDetail.answer);
    }
  }, [questionDetail]);

  return (
    <S.Container>
      <S.Typography>문의관리 {">"} 1:1문의</S.Typography>
      <S.TableContainer>
        <S.Table aria-label="simple table">
          <S.TableBody>
            <S.TableRow>
              <S.TableCell>등록일시</S.TableCell>
              <S.TableCell colSpan={3}>
                {questionDetail?.createDate}
              </S.TableCell>
            </S.TableRow>
            <S.TableRow>
              <S.TableCell style={{ width: "15%" }}>답변여부</S.TableCell>
              <S.TableCell style={{ width: "35%", padding: "0 0 0 10px" }}>
                <S.RadioGroup
                  row
                  value={options?.status || "N"}
                  onChange={handleStatusChange}
                >
                  <FormControlLabel
                    style={{ maxHeight: "30px" }}
                    value="N"
                    control={<Radio />}
                    label="미완료"
                  />
                  <FormControlLabel
                    style={{ maxHeight: "30px" }}
                    value="Y"
                    control={<Radio />}
                    label="완료"
                  />
                </S.RadioGroup>
              </S.TableCell>
              <S.TableCell>답변일시</S.TableCell>
              <S.TableCell>{questionDetail?.answerDate}</S.TableCell>
            </S.TableRow>
            <S.TableRow>
              <S.TableCell>이메일주소</S.TableCell>
              <S.TableCell>{questionDetail?.email}</S.TableCell>
              <S.TableCell>이름</S.TableCell>
              <S.TableCell>{questionDetail?.name}</S.TableCell>
            </S.TableRow>
            <S.TableRow>
              <S.TableCell>문의제목</S.TableCell>
              <S.TableCell colSpan={3}>{questionDetail?.title}</S.TableCell>
            </S.TableRow>
            <S.TableRow style={{ height: "150px" }}>
              <S.TableCell>문의내용</S.TableCell>
              <S.titleCell colSpan={3}>{questionDetail?.content}</S.titleCell>
            </S.TableRow>
            <S.TableRow>
              <S.TableCell>첨부파일</S.TableCell>
              <S.TableCell colSpan={3}>
                {questionDetail?.attachment && (
                  <S.Image src={questionDetail.attachment} alt="attachment" />
                )}

                {/* <S.FileName>seokseok_20230608115530.jpg</S.FileName> */}
              </S.TableCell>
            </S.TableRow>
            <S.TableRow>
              <S.TableCell>문의답변</S.TableCell>
              <S.TableCell colSpan={3} style={{ width: "80%" }}>
                <S.TextField
                  multiline
                  rows={4}
                  placeholder=""
                  value={options?.answer ? options.answer : ""}
                  variant="outlined"
                  onChange={handleAnswerChange}
                ></S.TextField>
              </S.TableCell>
            </S.TableRow>
          </S.TableBody>
        </S.Table>
      </S.TableContainer>
      <S.BtnBox>
        <S.NormalButton onClick={handleBackClick}>목록</S.NormalButton>
        <S.SaveButton
          onClick={() => save()}
          style={{ display: isSaveBtnRemove ? "none" : "block" }}
        >
          수정저장
        </S.SaveButton>
      </S.BtnBox>
      <Popup
        {...popupOptions}
        isOpenPopup={isOpenPopup}
        setIsOpenPopup={setIsOpenPopup}
      />
    </S.Container>
  );
}
