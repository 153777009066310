import { useMutation, useQuery } from "@tanstack/react-query";
import { request } from "./axios-utils";
import { AxiosResponse, AxiosError } from "axios";

export interface ErrorStructure {
  message: string;
  code: number;
}

// 엑셀 다운로드
const excelDownload = (downloadOptions: Object) => {
  return request({
    url: "/common/download/excel",
    method: "POST",
    data: { ...downloadOptions },
    responseType: "blob",
  });
};

export const useExcelDownload = (
  onSuccess: any,
  onError: (error: AxiosError<ErrorStructure>) => void
) => {
  return useMutation({
    mutationFn: excelDownload,
    onSuccess,
    onError,
  });
};
