import React, { useState, useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Box, SelectChangeEvent } from "@mui/material";
import * as S from "../styles/NoticeStyles";
import Pagination from "@mui/material/Pagination";
import { useSearchOptions } from "../../../utils/customHooks/useInputData";
import {
  YYYY_MM_DD,
  diffInDays,
  useQuickDatePicker,
} from "../../../utils/customHooks/useDatePicker";
import { useCryptoData } from "../../../utils/common/crypto";
import { useNoticeList } from "../../../utils/apiHooks/useBoardData";

export default function Notice() {
  const [orderDirection, setOrderDirection] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState("no");
  const navigate = useNavigate();
  const [noticeList, setNoticeList] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [totalCnt, setTotalCnt] = useState(0);
  const [page, setPage] = useState(1);
  const [activeButton, setActiveButton] = useState("month");
  const { options, updateOptions } = useSearchOptions({
    sDate: "",
    eDate: "",
    type: "",
    fixing: "",
    status: "",
    searchType: "",
    search: "",
    limit: 15,
    page: 1,
  });

  const { changeDate } = useQuickDatePicker(updateOptions);
  const { handleEncrypt } = useCryptoData();
  const initialRender = useRef(true);

  const onSuccessBoardList = useCallback((data: any) => {
    const { results } = data.data;

    results.map((v: any, k: any) => {
      v.no = k + 1;
      return v;
    });

    setNoticeList(results);
    setTotalPage(data.data.totalPage);
    setTotalCnt(data.data.totalCnt);
  }, []);

  const {
    mutate: getNoticeList,
    data: noticeListData,
    isSuccess,
  } = useNoticeList(onSuccessBoardList);

  type DataItem = {
    no: number;
    type: string;
    title: string;
    fix: string;
    post: string;
    registDate: string;
  };

  function isKeyOfDataItem(key: any): key is keyof DataItem {
    return ["no", "type", "title", "fix", "post", "registDate"].includes(key);
  }

  const handleSortRequest = (cellId: string) => {
    if (!isKeyOfDataItem(cellId)) return; // cellId가 DataItem의 키가 아니면 함수를 종료

    const isAsc = orderBy === cellId && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
    const sortedData = noticeList.sort((a, b) => {
      if (isAsc) {
        return a[cellId] < b[cellId] ? -1 : 1;
      } else {
        return a[cellId] > b[cellId] ? -1 : 1;
      }
    });
    setNoticeList([...sortedData]);
  };

  const handleRowClick = async (idx: String) => {
    const encryptedData = await handleEncrypt({ idx });

    const newUrl = `/board/${encryptedData}`;
    navigate(newUrl);
  };

  const search = () => {
    const diff = diffInDays(options.sDate, options.eDate);

    if (options.sDate === "" || options.eDate === "") {
      alert("조회 기간을 선택해주세요");
    } else if (diff > 365) {
      alert("조회 가능 기간은 최대 1년입니다");
    } else {
      const ops = { ...options };
      getNoticeList(ops);
    }
  };

  useEffect(() => {
    updateOptions("page", page);
  }, [page]);

  // 보기 개수 변경
  const changeLimit = async (e: SelectChangeEvent<unknown>) => {
    await updateOptions("limit", Number((e.target as HTMLSelectElement).value));

    search();
  };

  // 페이지 변경
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    search();
  };

  const handlePeriodChange = (type: string) => {
    setActiveButton(type);
    changeDate(type);
  };

  useEffect(() => {
    changeDate("month");
  }, []);

  // 초기 렌더링 시에만 리스트 자동 호출
  useEffect(() => {
    if (initialRender.current && options.sDate && options.eDate) {
      initialRender.current = false;
      const ops = { ...options };
      getNoticeList(ops);
    }
  }, [options, getNoticeList]);

  return (
    <S.Container>
      <S.Typography>게시판 공지 관리</S.Typography>
      <S.Paper elevation={0}>
        <S.Grid
          container
          spacing={1}
          alignItems="center"
          style={{ padding: "0 20px", margin: "0" }}
        >
          <S.InputLabel>조회기간</S.InputLabel>
          <S.Grid item xs={5}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                mr: 2,
              }}
            >
              <S.Input
                type="date"
                id="start-date"
                name="start-date"
                style={{ width: "190px" }}
                value={YYYY_MM_DD(options.sDate)}
                onChange={(e) =>
                  updateOptions("sDate", String(new Date(e.target.value)))
                }
              />
              ~
              <S.Input
                type="date"
                id="end-date"
                name="end-date"
                style={{ width: "190px" }}
                value={YYYY_MM_DD(options.eDate)}
                onChange={(e) =>
                  updateOptions("eDate", String(new Date(e.target.value)))
                }
              />
            </Box>
          </S.Grid>
          <S.Grid item>
            <S.NormalButton
              variant="contained"
              sx={{ mr: 1 }}
              active={activeButton === "day"}
              onClick={() => handlePeriodChange("day")}
            >
              오늘
            </S.NormalButton>
            <S.NormalButton
              variant="contained"
              sx={{ mr: 1 }}
              active={activeButton === "week"}
              onClick={() => handlePeriodChange("week")}
            >
              1주일
            </S.NormalButton>
            <S.NormalButton
              variant="contained"
              sx={{ mr: 1 }}
              active={activeButton === "month"}
              onClick={() => handlePeriodChange("month")}
            >
              1개월
            </S.NormalButton>
            <S.NormalButton
              variant="contained"
              sx={{ height: "2.5em" }}
              active={activeButton === "quarter"}
              onClick={() => handlePeriodChange("quarter")}
            >
              3개월
            </S.NormalButton>
          </S.Grid>
        </S.Grid>
        <S.Grid
          container
          spacing={1}
          alignItems="center"
          style={{ padding: "0 20px", margin: "0" }}
        >
          <S.InputLabel>공지분류</S.InputLabel>
          <S.Grid item xs={2}>
            <S.Select
              defaultValue="1"
              sx={{ width: "10em", height: "2.5em", mr: 1 }}
              onChange={(e) =>
                updateOptions(
                  "type",
                  (e.target as HTMLSelectElement).value === "1"
                    ? ""
                    : (e.target as HTMLSelectElement).value
                )
              }
            >
              <S.MenuItem value="1">전체</S.MenuItem>
              <S.MenuItem value="알림">알림</S.MenuItem>
              <S.MenuItem value="업데이트">업데이트</S.MenuItem>
              <S.MenuItem value="시스템점검">시스템점검</S.MenuItem>
              <S.MenuItem value="이벤트">이벤트</S.MenuItem>
            </S.Select>
          </S.Grid>
          <S.InputLabel style={{ width: "115px" }}>
            최상단 노출여부
          </S.InputLabel>
          <S.Grid item xs={2}>
            <S.Select
              sx={{ width: "10em", height: "2.5em", mr: 1 }}
              onChange={(e) =>
                updateOptions(
                  "fixing",
                  (e.target as HTMLSelectElement).value === "1"
                    ? ""
                    : (e.target as HTMLSelectElement).value
                )
              }
              defaultValue="1"
            >
              <S.MenuItem value="1">전체</S.MenuItem>
              <S.MenuItem value="Y">적용</S.MenuItem>
              <S.MenuItem value="N">미적용</S.MenuItem>
            </S.Select>
          </S.Grid>
          <S.InputLabel>게시여부</S.InputLabel>
          <S.Grid item xs={2}>
            <S.Select
              sx={{ width: "10em", height: "2.5em", mr: 1 }}
              onChange={(e) =>
                updateOptions(
                  "status",
                  (e.target as HTMLSelectElement).value === "1"
                    ? ""
                    : (e.target as HTMLSelectElement).value
                )
              }
              defaultValue="1"
            >
              <S.MenuItem value="1">전체</S.MenuItem>
              <S.MenuItem value="Y">게시</S.MenuItem>
              <S.MenuItem value="N">미게시</S.MenuItem>
            </S.Select>
          </S.Grid>
        </S.Grid>
        <S.Grid
          container
          spacing={1}
          alignItems="center"
          style={{ padding: "0 20px", margin: "0" }}
        >
          <S.InputLabel>검색</S.InputLabel>
          <S.Grid item xs={10}>
            <S.Select
              defaultValue="1"
              sx={{ width: "10em", height: "2.5em", mr: 1 }}
              onChange={(e) =>
                updateOptions(
                  "searchType",
                  (e.target as HTMLSelectElement).value === "1"
                    ? ""
                    : (e.target as HTMLSelectElement).value
                )
              }
            >
              <S.MenuItem value="1">전체</S.MenuItem>
              <S.MenuItem value="title">제목</S.MenuItem>
              <S.MenuItem value="content">내용</S.MenuItem>
            </S.Select>
            <S.Input
              type="text"
              onChange={(e) => updateOptions("search", e.target.value)}
            />
          </S.Grid>
        </S.Grid>
        <S.Grid container spacing={1} alignItems="center">
          <S.Grid
            item
            xs={12}
            style={{
              alignItems: "center",
              justifyContent: "center",
              margin: "0",
            }}
          >
            <S.SearchButton
              variant="contained"
              color="primary"
              style={{ width: "130px" }}
              onClick={() => search()}
            >
              검색
            </S.SearchButton>
          </S.Grid>
        </S.Grid>
      </S.Paper>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
          marginTop: "30px",
        }}
      >
        <div>총 {isSuccess && noticeListData.data.totalCnt}건</div>
        <div
          style={{
            height: "2.8em",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <S.RowSelect defaultValue={15} sx={{ mr: 1 }}>
            <S.MenuItem value={15}>15개씩 보기</S.MenuItem>
            <S.MenuItem value={50}>50개씩 보기</S.MenuItem>
            <S.MenuItem value={100}>100개씩 보기</S.MenuItem>
          </S.RowSelect>
          <S.AddButton
            variant="contained"
            style={{ width: "110px" }}
            onClick={() => navigate("add-board")}
          >
            공지등록
          </S.AddButton>
        </div>
      </div>
      <S.Paper elevation={0}>
        <S.TableContainer>
          <S.Table>
            <S.TableHead>
              <S.TableHeadRow>
                <S.TableHeadCell align="center">
                  <S.TableSortLabel
                    active={orderBy === "no"}
                    hideSortIcon={false}
                    direction={
                      orderBy === "no"
                        ? ["asc", "desc"].includes(orderDirection)
                          ? orderDirection
                          : "asc"
                        : "asc"
                    }
                    onClick={() => handleSortRequest("no")}
                  >
                    No
                  </S.TableSortLabel>
                </S.TableHeadCell>
                <S.TableHeadCell align="center">공지분류</S.TableHeadCell>
                <S.TableHeadCell align="center">제목</S.TableHeadCell>
                <S.TableHeadCell align="center">
                  최상단 노출여부
                </S.TableHeadCell>
                <S.TableHeadCell align="center">게시여부</S.TableHeadCell>
                <S.TableHeadCell align="center">
                  <S.TableSortLabel
                    active={orderBy === "registDate"}
                    hideSortIcon={false}
                    direction={
                      orderBy === "registDate"
                        ? ["asc", "desc"].includes(orderDirection)
                          ? orderDirection
                          : "asc"
                        : "asc"
                    }
                    onClick={() => handleSortRequest("registDate")}
                  >
                    등록일시
                  </S.TableSortLabel>
                </S.TableHeadCell>
              </S.TableHeadRow>
            </S.TableHead>
            <S.TableBody>
              {isSuccess && noticeList.length > 0 ? (
                noticeList.map((row: any, index: number) => (
                  <S.TableRow
                    key={index}
                    onClick={() => handleRowClick(row.idx)}
                  >
                    <S.TableCell align="center">{row.no}</S.TableCell>
                    <S.TableCell align="center">{row.type}</S.TableCell>
                    <S.TableCell align="center">{row.title}</S.TableCell>
                    <S.TableCell align="center">
                      {row.fixing}
                    </S.TableCell>
                    <S.TableCell align="center">
                      {row.posting}
                    </S.TableCell>
                    <S.TableCell align="center">{row.createDate}</S.TableCell>
                  </S.TableRow>
                ))
              ) : (
                <S.TableRow>
                  <S.TableCell
                    align="center"
                    colSpan={7}
                    style={{ height: "110px" }}
                  >
                    검색 결과가 없습니다
                  </S.TableCell>
                </S.TableRow>
              )}
            </S.TableBody>
          </S.Table>
        </S.TableContainer>
      </S.Paper>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "30px",
          marginBottom: "30px",
        }}
      >
        <Pagination
          count={isSuccess && totalPage ? totalPage : 1}
          color="standard"
          onChange={handlePageChange}
          page={page}
        />
      </Box>
    </S.Container>
  );
}
