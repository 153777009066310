import React, { useState, useEffect } from "react";
import GlobalStyles from "@mui/joy/GlobalStyles";
import { useNavigate, useLocation } from "react-router-dom";
import "@mui/material/styles";
import Box from "@mui/joy/Box";
import Chip from "@mui/joy/Chip";
import Divider from "@mui/joy/Divider";
import IconButton from "@mui/joy/IconButton";
import { createTheme, ThemeProvider, Typography } from "@mui/material";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton, { listItemButtonClasses } from "@mui/joy/ListItemButton";
import ListItemContent from "@mui/joy/ListItemContent";
// import Typography from "@mui/material/Typography";/
import Sheet from "@mui/joy/Sheet";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { closeSidebar } from "../../utils/common/lnbUtils";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    lnbTitle: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    lnbTitle?: React.CSSProperties;
  }
}

// Palette 타입 확장
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    lnbTitle: true;
  }
}

function Toggler({
  defaultExpanded = true,
  renderToggle,
  children,
}: {
  defaultExpanded?: boolean;
  children: React.ReactNode;
  renderToggle: (params: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }) => React.ReactNode;
}) {
  const [open, setOpen] = React.useState(defaultExpanded);

  return (
    <React.Fragment>
      {renderToggle({ open, setOpen })}
      <Box
        sx={{
          display: "grid",
          gridTemplateRows: open ? "1fr" : "0fr",
          transition: "0.2s ease",
          "& > *": {
            overflow: "hidden",
          },
        }}
      >
        {children}
      </Box>
    </React.Fragment>
  );
}

export default function Lnb({ activeMenu }: { activeMenu: string }) {
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  // 컴포넌트가 마운트되면 첫 번째 항목을 활성화
  useEffect(() => {
    const path = location.pathname.split("/")[1];
    console.log("path: " + path);
    switch (path) {
      case "":
      case "member":
        setSelectedItem("member");
        break;
      case "withdrawn":
        setSelectedItem("withdrawn");
        break;
      case "ai-prediction":
        setSelectedItem("ai-prediction");
        break;
      case "board":
        setSelectedItem("notice");
        break;
      case "qna":
        setSelectedItem("qna");
        break;
      case "comment-report":
        setSelectedItem("comment-report");
        break;
      case "post-report":
        setSelectedItem("post-report");
        break;
      default:
        setSelectedItem("admin");
        break;
    }
  }, [location.pathname]);

  const handleItemClick = (item: string) => {
    console.log("item: ", item);
    setSelectedItem(item);
    navigate(`/${item}`);
  };

  // 테마 생성
  const theme = createTheme({
    typography: {
      // 새로운 variant 추가
      lnbTitle: {
        fontSize: "1rem",
        fontWeight: 700,
        lineHeight: 1.6,
        letterSpacing: "0.0075em",
      },
    },
  });

  const getSubmenus = () => {
    switch (activeMenu) {
      case "member":
      case "withdrawn":
        return (
          <>
            <ListItem>
              <ListItemButton
                onClick={() => handleItemClick("member")}
                sx={
                  selectedItem === "member"
                    ? { bgcolor: "#e2e2e2", fontWeight: 600 }
                    : {}
                }
              >
                회원조회/수정
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton
                onClick={() => handleItemClick("withdrawn")}
                sx={
                  selectedItem === "withdrawn"
                    ? { bgcolor: "#e2e2e2", fontWeight: 600 }
                    : {}
                }
              >
                탈퇴회원관리
              </ListItemButton>
            </ListItem>
          </>
        );
      case "content":
        return (
          <ListItem>
            <ListItemButton
              onClick={() => handleItemClick("ai-prediction")}
              sx={
                selectedItem === "ai-prediction"
                  ? { bgcolor: "#e2e2e2", fontWeight: 600 }
                  : {}
              }
            >
              AI검사관리
            </ListItemButton>
          </ListItem>
        );
      case "board":
        return (
          <ListItem>
            <ListItemButton
              onClick={() => handleItemClick("board")}
              sx={
                selectedItem === "notice"
                  ? { bgcolor: "#e2e2e2", fontWeight: 600 }
                  : {}
              }
            >
              공지관리
            </ListItemButton>
          </ListItem>
        );
      case "qna":
      case "comment-report":
      case "post-report":
        return (
          <>
            <ListItem>
              <ListItemButton
                onClick={() => handleItemClick("qna")}
                sx={
                  selectedItem === "qna"
                    ? { bgcolor: "#e2e2e2", fontWeight: 600 }
                    : {}
                }
              >
                1:1문의
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton
                onClick={() => handleItemClick("comment-report")}
                sx={
                  selectedItem === "comment-report"
                    ? { bgcolor: "#e2e2e2", fontWeight: 600 }
                    : {}
                }
              >
                댓글신고
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton
                onClick={() => handleItemClick("post-report")}
                sx={
                  selectedItem === "post-report"
                    ? { bgcolor: "#e2e2e2", fontWeight: 600 }
                    : {}
                }
              >
                게시물신고
              </ListItemButton>
            </ListItem>
          </>
        );
      default:
        return (
          <ListItem>
            <ListItemButton
              onClick={() => handleItemClick("admin")}
              sx={
                selectedItem === "admin"
                  ? { bgcolor: "#e2e2e2", fontWeight: 600 }
                  : {}
              }
            >
              운영자조회/수정
            </ListItemButton>
          </ListItem>
        );
    }
  };

  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: { xs: "fixed", md: "sticky" },
        transform: {
          xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))",
          md: "none",
        },
        transition: "transform 0.4s, width 0.4s",
        zIndex: 10000,
        height: "90dvh",
        width: "var(--Sidebar-width)",
        top: 0,
        p: 2,
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        borderRight: "1px solid",
        borderColor: "#e2e2e2",
        backgroundColor: "#f9f9f9",
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ":root": {
            "--Sidebar-width": "220px",
            [theme.breakpoints.up("lg")]: {
              "--Sidebar-width": "240px",
            },
          },
        })}
      />
      <List
        size="sm"
        sx={{
          gap: 1,
          "--List-nestedInsetStart": "30px",
          "--ListItem-radius": (theme) => theme.vars.radius.sm,
        }}
      >
        <ListItem nested>
          <Toggler
            renderToggle={({ open, setOpen }) => (
              <ListItemButton onClick={() => setOpen(!open)}>
                <KeyboardArrowDownIcon
                  sx={{ transform: open ? "rotate(0deg)" : "rotate(-90deg)" }}
                />
                {/* <AssignmentRoundedIcon /> */}
                <ListItemContent>
                  <ThemeProvider theme={theme}>
                    <Typography variant="lnbTitle">
                      {activeMenu === "member" || activeMenu === "withdrawn"
                        ? "회원관리"
                        : activeMenu === "content"
                        ? "콘텐츠관리"
                        : activeMenu === "board"
                        ? "게시판 관리"
                        : activeMenu === "qna"
                        ? "문의 관리"
                        : "관리자 관리"}
                    </Typography>
                  </ThemeProvider>
                </ListItemContent>
              </ListItemButton>
            )}
          >
            <List sx={{ gap: 0.5 }}>{getSubmenus()}</List>
          </Toggler>
        </ListItem>
      </List>
      <Divider />
    </Sheet>
  );
}
