import React, { useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";
import Lnb from "./Lnb";

export default function Layout() {
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState("member");

  useEffect(() => {
    if (location.pathname === "/") {
      setActiveMenu("member");
    }
  }, [location]);

  return (
    <>
      <Header activeMenu={activeMenu} setActiveMenu={setActiveMenu} />
      <main style={{ display: "flex", flexDirection: "row" }}>
        <Lnb activeMenu={activeMenu} />
        <Outlet />
      </main>
      {/* <Footer /> */}
    </>
  );
}
