// StyledComponents.js
import styled from "styled-components";
import * as M from "@mui/material";

export const Container = styled(M.Container)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 100%;
  }
`;

export const PopupContainer = styled(M.Card)`
  && {
    width: 26vw;
    min-height: 30vh;
    // background-color: #f0f8ff;
  }
`;

export const BackDrop = styled(M.Backdrop)`
  && {
    color: #fff;
  }
`;

export const CardHeader = styled(M.CardHeader)`
  && {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid #d3d3d3;
    // background-color:#f0f8ff;
  }
`;

export const CardContent = styled(M.CardContent)`
  && {
    padding: 16px;
    text-align: center;
    margin-top: 4vh;
  }
`;

export const Typography = styled(M.Typography)`
  && {
    font-size: 18px;
  }
`;

export const CardActions = styled(M.CardActions)`
  && {
    display: flex;
    justify-content: space-evenly;
    margin-top: 3vh;
  }
`;

export const ButtonCancel = styled(M.Button)`
  && {
    color: black;
    width: 6vw;
    height: 4vh;
    font-size: 16px;
  }
`;

export const ButtonConfirm = styled(M.Button)`
  && {
    width: 6vw;
    height: 4vh;
    font-size: 16px;
    background-color: #8989db;
    color: white;
  }
  &:hover {
    color: black;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
`;
