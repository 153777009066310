import styled from "styled-components";

export const Controls = styled("div")`
    display: flex;
    border: 1px solid #ccc;
    border-top: 0;
    padding: 10px;
`;

export const ControlsRight = styled("button")`
    margin-left: auto;
`

export const State = styled("div")`
    margin: 10px 0;
    font-family: monospace;
`

export const StateTitle = styled("div")`
    color: #999;
  text-transform: uppercase;
`

export const StyledEditorContainer = styled.div`
  .ql-toolbar {
    // width: 100%;
  }
  .ql-editor {
    // height: 300px;
    height:26vh;
    width: 50vw;
  }
`;