import React from "react";
import * as S from "./styles/PopupStyles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

interface PopupProps {
  content?: string;
  callback?: () => any;
  type?: string; // 옵셔널 프롭, 특정 문자열만 허용
  isOpenPopup: boolean;
  setIsOpenPopup: (open: boolean) => void;
  imageUrl?: string;
}

// EX. 팝업 props 예시

// const popupOptions = {
//     content: '정말 삭제하시겠습니까?',
//     type: "confirm",
//     callback: () => { console.log('popup closed!') }, // 팝업 확인 버튼 클릭 후 실행할 콜백함수
//     isOpenPopup, // 팝업 open 상태 여부 const [ isOpenPopup, setIsOpenPopup ] = useState(false);
//     setIsOpenPopup
//   };

/**
 *
 * @param props
 * type: string - 팝업 타입 info / confirm / image
 *
 * _content: string - 팝업 내용
 * @returns
 */
export const Popup: React.FC<PopupProps> = React.memo(
  ({
    content,
    callback,
    type = "info",
    isOpenPopup,
    setIsOpenPopup,
    imageUrl,
  }) => {
    const closePopup = () => {
      setIsOpenPopup(false);
    };

    const openCallback = () => {
      setIsOpenPopup(false);

      callback && callback();
    };

    console.log("imageUrl", imageUrl);

    return (
      <>
        {isOpenPopup && (
          <S.Container>
            <S.PopupContainer elevation={2}>
              <S.CardHeader
                subheader="Alert"
                action={
                  <IconButton aria-label="settings" onClick={closePopup}>
                    <CloseIcon />
                  </IconButton>
                }
              />
              <S.CardContent>
                {imageUrl ? (
                  <S.Image src={imageUrl} alt="popup image" />
                ) : (
                  <S.Typography>{content}</S.Typography>
                )}
              </S.CardContent>
              <S.CardActions>
                {type === "confirm" && (
                  <S.ButtonCancel onClick={closePopup}>취소</S.ButtonCancel>
                )}
                {type === "image" ? (
                  ""
                ) : (
                  <S.ButtonConfirm onClick={openCallback}>확인</S.ButtonConfirm>
                )}
              </S.CardActions>
            </S.PopupContainer>
          </S.Container>
        )}
      </>
    );
  }
);
