import React, { useEffect, useState } from "react"
import dayjs from "dayjs";



export function YYYY_MM_DD(date: Date | string) {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = (`0${d.getMonth() + 1}`).slice(-2);
    const day = (`0${d.getDate()}`).slice(-2);

    return `${year}-${month}-${day}`;
};



export function useQuickDatePicker(_updateOptions?: any) {
    const [sDate, setSDate] = useState('');
    const [eDate, setEDate] = useState('');

    function changeDate(dateType: string) {
        const tDay = dayjs(new Date()).format('YYYY-MM-DD');
        setEDate(tDay);

        switch (dateType) {
            case 'day':
                setSDate(tDay);
                break;

            case 'week':
                setSDate(dayjs(tDay).subtract(1, 'week').format('YYYY-MM-DD'));
                break;

            case 'month':
                setSDate(dayjs(tDay).subtract(1, 'month').format('YYYY-MM-DD'));
                break;

            case 'quarter':
                setSDate(dayjs(tDay).subtract(3, 'month').format('YYYY-MM-DD'));
                break;

            default:
                setSDate('');
                break;
        };
    }

    useEffect(() => {
        _updateOptions('sDate', sDate);
        _updateOptions('eDate', eDate);

    }, [_updateOptions, sDate, eDate])


    return { sDate, eDate, changeDate };
};


export function diffInDays(sDate: string | Date, eDate: string | Date) {
    let startDate = dayjs(sDate);
    let endDate = dayjs(eDate);

    const diff = endDate.diff(startDate, 'days');

    return diff;
}
