import { useMutation, useQuery } from "@tanstack/react-query";
import { request } from "./axios-utils";
import { AxiosResponse, AxiosError } from "axios";

export interface ErrorStructure {
  message: string;
  code: number;
}

// 검사신청 목록
const predictionList = (predictionSearchOptions: Object) => {
  return request({
    url: "/disease/list",
    method: "POST",
    data: { ...predictionSearchOptions },
  });
};

export const usePredictionList = (
  onSuccess: any,
  onError: (error: AxiosError<ErrorStructure>) => void
) => {
  return useMutation({
    mutationFn: predictionList,
    onSuccess,
    onError,
  });
};

// 검사 상세
const predictionDetail = (idx: Number) => {
  return request({
    url: `/disease/detail/${idx}`,
    method: "GET",
  });
};

export const usePredictionDetail = (onSuccess: any) => {
  return useMutation({
    mutationFn: predictionDetail,
    onSuccess,
  });
};

// 검사 업데이트
const predictionUpdate = (upDateData: Object) => {
  return request({
    url: "/disease/update",
    method: "PUT",
    data: { ...upDateData },
  });
};

export const usePredictionUpdate = (onSuccess: any, onError: any) => {
  return useMutation({
    mutationFn: predictionUpdate,
    onSuccess,
  });
};
