// StyledComponents.js
import styled from "styled-components";
import * as M from "@mui/material";

export const Container = styled(M.Container)`
  && {
    padding: 0 20px;
  }
`;

export const TableContainer = styled(M.TableContainer)``;

export const Typography = styled(M.Typography)`
  && {
    font-size: 23px;
    margin: 20px 0 15px 0;
    font-weight: 700;
  }
`;

export const Paper = styled(M.Paper)`
  && {
    margin-bottom: 20px;
    min-height: 200px;
    background-color: #f9f9f9;
    border: 1px solid #ededed;
  }
`;

export const Input = styled(M.Input)`
  width: 100%;
  height: 2.5em;
  border-radius: 4px;
  border: 1px solid lightgrey;
  padding: 0 10px;

  &&::before {
    content: none; // 기본 밑줄 제거
  }

  &&::after {
    content: none; // 포커스 시 밑줄 제거
  }
`;

export const InputLabel = styled(M.InputLabel)`
  && {
    width: 100px;
    margin-right: 15px;
    font-family: NanumGothic;
    font-size: 15px;
    color: #000000;
    font-weight: 700;
  }
`;

export const Select = styled(M.Select)`
  && {
    height: 2.2em;
    background-color: #ffffff;
  }
`;

export const RadioGroup = styled(M.RadioGroup)`
  && {
    align-items: center;
  }
`;

export const MenuItem = styled(M.MenuItem)`
  && {
    width: 180px;
  }
`;

export const NormalButton = styled(M.Button)`
  && {
    height: 2.5em;
    border: none;
    background-color: #e0e0e0;
    color: #000000;
    box-shadow: none;
    margin-right: 5px;
    width: 80px;
  }

  &&:hover {
    background-color: #969696; // 호버 시 배경색 변경
    color: white; // 호버 시 글자색 변경
  }
`;

export const SaveButton = styled(M.Button)`
  && {
    height: 2.5em;
    border: none;
    background-color: #8989db;
    color: #ffffff;
    width: 80px;
  }

  &&:hover {
    background-color: #6565d1; // 호버 시 배경색 변경
  }
`;

export const Table = styled(M.Table)``;

export const TableBody = styled(M.TableBody)``;

export const TableRow = styled(M.TableRow)`
  && {
    background-color: #ffffff;
  }

  && {
    height: 50px;
    border: 1px solid #ebebebeb;
  }
`;

export const TableCell = styled(M.TableCell)`
  && {
    height: 20px;
    padding: 10px;
  }

  &:nth-of-type(odd) {
    background-color: #f9f9f9;
    font-weight: bold;
  }
`;

export const TableCellWithButton = styled(TableCell)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: none;
    height: 100%;
  }
`;

export const TextField = styled(M.TextField)`
  && {
    width: 100%;
    .MuiInputBase-root {
      border-radius: 4px;
      background-color: #fff; // 배경색 변경
    }
    .MuiOutlinedInput-input {
      padding: 12px; // 패딩 조정
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: lightblue; // 테두리 색상 변경
    }
    .MuiInputLabel-root {
      color: grey; // 라벨 색상 변경
    }
    .Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-width: 2px; // 포커스 시 테두리 두께 변경
        border-color: darkblue; // 포커스 시 테두리 색상 변경
      }
      .MuiInputLabel-root {
        color: darkblue; // 포커스 시 라벨 색상 변경
      }
    }
  }
`;

export const BtnBox = styled(M.Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  margin-top: 20px;
`;

