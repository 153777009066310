import React, { useEffect, useRef } from 'react';
import Quill from 'quill';
import { StyledEditorContainer } from './styles/QuillStyles';


interface MyEditorProps {
    initialValue: string;
    setContents: (contents: string) => void;
}


/**
 * 
 * initialValue : 초기값
 * setContents : onChange시 변경값
 * */
const MyEditor: React.FC<MyEditorProps> = ({ initialValue, setContents }) => {
    const editorRef = useRef<HTMLDivElement | null>(null);
    const quillRef = useRef<Quill | null>(null);

    useEffect(() => {
        if (editorRef.current) {

            if (quillRef.current) {
                quillRef.current.off('text-change');
                editorRef.current.innerHTML = '';
            }

            const quill = new Quill(editorRef.current, {
                theme: 'snow',
            });

            quillRef.current = quill;

            console.log("initialValue: ", initialValue);

            quill.setText(initialValue);

            quill.on('text-change', () => {
                const text = quill.getText();
                setContents(text);
            });

            return () => {
                // Cleanup
                // if (quillRef.current) {
                //     quillRef.current.off('text-change');
                //     quillRef.current = null;
                // }
                if (quillRef.current) {
                    quillRef.current.off('text-change');
                    quillRef.current = null;
                    if (editorRef.current) {
                        editorRef.current.innerHTML = ''; // 툴바와 에디터 내용을 제거
                    }
                }
            };
        }
    }, [setContents])


    useEffect(() => {
        if (quillRef.current) {
            quillRef.current.setText(initialValue);
        }
    }, [initialValue]);

    return (
        <StyledEditorContainer>
            <div ref={editorRef} style={{}} />
        </StyledEditorContainer>
    )
};

export default MyEditor;