import React, { useState, useEffect } from "react";
import Box from "@mui/joy/Box";
import IconButton from "@mui/joy/IconButton";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useLogout } from "../../utils/apiHooks/useAuthData";
import useLocalStorage from "../../utils/customHooks/useLoginCheck";

import LanguageRoundedIcon from "@mui/icons-material/LanguageRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";

import * as S from "./styles/HeaderStyles";

export default function Header({
  activeMenu,
  setActiveMenu,
}: {
  activeMenu: string;
  setActiveMenu: (menu: string) => void;
}) {
  const [isLoggedIn, setIsLoggedIn] = useLocalStorage("isLoggedIn", false);
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  // 버튼을 클릭할 때 호출할 함수
  const handleButtonClick = (menuType: string) => {
    setActiveMenu(menuType);
    navigate("/admin");
  };

  const onSuccessLogout = (data: any) => {
    console.log("Logout successful!", data);

    localStorage.removeItem("token");
    window.dispatchEvent(new Event("storageChange"));
    setIsLoggedIn(false);
    navigate("/login");
  };

  const onErrorLogout = (error: any) => {
    console.error("Logout failed!", error.response.data);
  };

  const {
    mutate: logoutMutate,
    data,
    isSuccess,
  } = useLogout(onSuccessLogout, onErrorLogout);

  const handleLogout = () => {
    logoutMutate(undefined, {
      onSuccess: onSuccessLogout,
      onError: onErrorLogout,
    });
  };

  const handleLogin = () => {
    // 로그인 페이지로 이동
    navigate("/login");
  };

  useEffect(() => {
    const path = location.pathname.split("/")[1]; // URL의 첫 번째 세그먼트 추출
    switch (path) {
      case "member":
      case "withdrawn":
        setActiveMenu("member");
        break;
      case "ai-prediction":
        setActiveMenu("content");
        break;
      case "board":
        setActiveMenu("board");
        break;
      case "qna":
      case "comment-report":
      case "post-report":
        setActiveMenu("qna");
        break;
      default:
        setActiveMenu(""); // 경로가 매칭되지 않는 경우 기본 메뉴로 설정
        break;
    }
  }, [location, setActiveMenu]);

  useEffect(() => {
    const checkLoginStatus = () => {
      const token = localStorage.getItem("token");
      setIsLoggedIn(!!token); // 토큰 존재 여부에 따라 isLoggedIn 상태 업데이트
    };

    // 컴포넌트 마운트 시 로그인 상태 체크
    checkLoginStatus();

    // Custom event 리스너 추가
    window.addEventListener("storageChange", checkLoginStatus);

    // 기존의 storage 이벤트 리스너도 유지
    window.addEventListener("storage", checkLoginStatus);

    return () => {
      window.removeEventListener("storageChange", checkLoginStatus);
      window.removeEventListener("storage", checkLoginStatus);
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        justifyContent: "space-between",
        height: "50px",
        borderBottom: "1px solid #e2e2e2",
        backgroundColor: "#f9f9f9",
      }}
    >
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{ display: { xs: "none", sm: "flex" } }}
      >
        <IconButton
          size="md"
          color="neutral"
          component={Link}
          to="/"
          sx={{
            display: { xs: "none", sm: "inline-flex" },
          }}
          style={{ marginLeft: "10px" }}
        >
          <LanguageRoundedIcon />
        </IconButton>
        <Button
          variant="plain"
          color="neutral"
          onClick={() => handleButtonClick("member")}
          component={Link}
          to="/member"
          size="md"
          sx={{
            alignSelf: "center",
            bgcolor: activeMenu === "member" ? "#e2e2e2" : "",
          }}
        >
          회원관리
        </Button>
        <Button
          variant="plain"
          color="neutral"
          onClick={() => handleButtonClick("content")}
          component={Link}
          to="/ai-prediction"
          size="sm"
          sx={{
            alignSelf: "center",
            bgcolor: activeMenu === "content" ? "#e2e2e2" : "",
          }}
        >
          콘텐츠 관리
        </Button>
        <Button
          variant="plain"
          color="neutral"
          onClick={() => handleButtonClick("board")}
          component={Link}
          to="/board"
          size="sm"
          sx={{
            alignSelf: "center",
            bgcolor: activeMenu === "board" ? "#e2e2e2" : "",
          }}
        >
          게시판 관리
        </Button>
        <Button
          variant="plain"
          color="neutral"
          onClick={() => handleButtonClick("qna")}
          component={Link}
          to="/qna"
          size="sm"
          sx={{
            alignSelf: "center",
            bgcolor: activeMenu === "qna" ? "#e2e2e2" : "",
          }}
        >
          문의 관리
        </Button>
      </Stack>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1.5,
          alignItems: "center",
        }}
      >
        <S.AdminButton
          onClick={() => handleButtonClick("admin")}
          sx={{ alignSelf: "center" }}
        >
          운영자 관리
        </S.AdminButton>
        <S.LogoutButton onClick={isLoggedIn ? handleLogout : handleLogin}>
          <LogoutRoundedIcon />
          {isLoggedIn ? "로그아웃" : "로그인"}
        </S.LogoutButton>
      </Box>
    </Box>
  );
}
