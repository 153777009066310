import React, {
  useState,
  useEffect,
  ChangeEvent,
  useCallback,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import * as S from "../styles/CommentReportStyles";
import Pagination from "@mui/material/Pagination";
import {
  YYYY_MM_DD,
  diffInDays,
  useQuickDatePicker,
} from "../../../utils/customHooks/useDatePicker";
import { SelectChangeEvent } from "@mui/material/Select";
import { useSearchOptions } from "../../../utils/customHooks/useInputData";
import { useReportRepleList } from "../../../utils/apiHooks/useReportData";
import { Popup } from "../../../components/popup/Popup";
import { successResponseDataList } from "../../../interfaces/axios";
import { ResReportReple } from "../../../interfaces/reportInterface";

export default function CommentReport() {
  const [reportRepleData, setReportRepleData] = useState<ResReportReple[]>([]);
  const [processStatus, setProcessStatus] = useState("all");
  const [resultStatus, setResultStatus] = useState("all");
  const [orderDirection, setOrderDirection] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState("no");
  const navigate = useNavigate();
  const [totalPage, setTotalPage] = useState(1);
  const [totalCnt, setTotalCnt] = useState(0);
  const [page, setPage] = useState(1);
  const [activeButton, setActiveButton] = useState("month");
  const initialRender = useRef(true);
  const { options, updateOptions } = useSearchOptions({
    sDate: "",
    eDate: "",
    dateType: "create_date",
    status: "all",
    searchType: "email",
    search: "",
    limit: 15,
    page,
  });

  const { changeDate } = useQuickDatePicker(updateOptions);


  // useMutation :
  const onSuccessReportedRepleList = useCallback((data: successResponseDataList<ResReportReple>) => {
    const { results } = data.data;

    const formattedResults: ResReportReple[] = results.map((v: any, k: number) => ({
      ...v,
      no: k + 1,
      idx: v.idx,
      email: v.email,
      title: v.name,
      count: v.count,
      status: v.status,
      createDate: v.createDate,
      answerDate: v.answerDate,
    }));

    setReportRepleData(formattedResults);


    // setTotalPage(data.data.totalPage);
    // setTotalCnt(data.data.totalCnt);
  }, []);

  const onErrorReportedRepleList = (error: any) => {
    console.log(error.data.message);
    if (
      error.data.message === "Unauthorized" ||
      error.data.message === "Forbidden"
    ) {
      alert("권한이 없습니다.");
    }
  };

  const {
    mutate: getReportedRepleList,
    data: reportedRepleOptions,
    isSuccess,
  } = useReportRepleList(onSuccessReportedRepleList, onErrorReportedRepleList);

  // const handleSortRequest = (cellId: string) => {

  //   const isAsc = orderBy === cellId && orderDirection === "asc";
  //   setOrderDirection(isAsc ? "desc" : "asc");
  //   setOrderBy(cellId);
  //   const sortedData = reportRepleData.sort((a, b) => {
  //     if (isAsc) {
  //       return a[cellId] < b[cellId] ? -1 : 1;
  //     } else {
  //       return a[cellId] > b[cellId] ? -1 : 1;
  //     }
  //   });
  //   setReportRepleData([...sortedData]);
  // };

  // 페이지 변경
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    search();
  };

  // 검색 버튼
  const search = () => {
    const diff = diffInDays(options.sDate, options.eDate);

    if (options.sDate === "" || options.eDate === "") {
      alert("조회 기간을 선택해주세요");
    } else if (diff > 365) {
      alert("조회 가능 기간은 최대 1년입니다");
    } else {
      let ops;
      options.status === "all"
        ? (ops = { ...options, status: "" })
        : (ops = { ...options });
      // getQuestionList(ops);
    }
  };

  const handleRowClick = (idx: number) => {
    const newUrl = `/comment-report/${idx}`;
    navigate(newUrl);
  };

  const handleRowCnt = (e: SelectChangeEvent<unknown>) => {
    const newLimit = Number(e.target.value);
    updateOptions("limit", newLimit);
    let ops;
    options.status === "all"
      ? (ops = { ...options, status: "", limit: newLimit })
      : (ops = { ...options, limit: newLimit });
    // getQuestionList(ops);
  };

  const handleSDateChange = (e: any) => {
    const dateString = e.target.value;
    const isoFormattedDate = convertToISO8601(dateString);
    updateOptions("sDate", isoFormattedDate);
  };

  const handleEDateChange = (e: any) => {
    const dateString = e.target.value;
    const isoFormattedDate = convertToISO8601(dateString);
    updateOptions("eDate", isoFormattedDate);
  };

  const convertToISO8601 = (dateString: any) => {
    const date = new Date(dateString);
    const isoString = date.toISOString();
    return isoString;
  };

  const handlePeriodChange = (type: string) => {
    setActiveButton(type);
    changeDate(type);
  };

  useEffect(() => {
    const today = new Date();
    const oneMonthAgo = new Date(today);
    oneMonthAgo.setMonth(today.getMonth() - 1);

    updateOptions("sDate", YYYY_MM_DD(oneMonthAgo));
    updateOptions("eDate", YYYY_MM_DD(today));
    changeDate("month");
  }, []);

  // 초기 렌더링 시에만 리스트 자동 호출
  useEffect(() => {
    if (initialRender.current && options.sDate && options.eDate) {
      let ops;
      initialRender.current = false;

      ops = options.status === "all" ? { ...options, status: "" } : { ...options };
      getReportedRepleList(ops);
    }
  }, [options, getReportedRepleList]);

  return (
    <S.Container>
      <S.Typography>문의관리 {">"} 댓글신고</S.Typography>
      <S.Paper elevation={0}>
        <S.Grid
          container
          spacing={1}
          alignItems="center"
          style={{ padding: "0 20px", margin: "0" }}
        >
          <S.InputLabel>조회기간</S.InputLabel>
          <S.Grid item xs={6}>
            {" "}
            <Box
              sx={{
                mr: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <S.Select style={{ width: "8em" }} value={options.dateType} onChange={(e) => updateOptions("dateType", (e.target as HTMLInputElement).value)}>
                <S.MenuItem value="create_date">신고일</S.MenuItem>
                <S.MenuItem value="update_date">처리일</S.MenuItem>
              </S.Select>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                mr: 2,
              }}
            >
              <S.Input
                type="date"
                id="sDate"
                name="sDate"
                style={{ width: "190px" }}
                value={YYYY_MM_DD(options.sDate)}
                onChange={handleSDateChange}
              />
              ~
              <S.Input
                type="date"
                id="eDate"
                name="eDate"
                style={{ width: "190px", marginRight: "15px" }}
                value={YYYY_MM_DD(options.eDate)}
                onChange={handleEDateChange}
              />
            </Box>
          </S.Grid>
          <S.Grid item>
            <S.NormalButton
              variant="contained"
              sx={{ mr: 1 }}
              active={activeButton === "day"}
              onClick={() => handlePeriodChange("day")}
            >
              오늘
            </S.NormalButton>
            <S.NormalButton
              variant="contained"
              sx={{ mr: 1 }}
              active={activeButton === "week"}
              onClick={() => handlePeriodChange("week")}
            >
              1주일
            </S.NormalButton>
            <S.NormalButton
              variant="contained"
              sx={{ mr: 1 }}
              active={activeButton === "month"}
              onClick={() => handlePeriodChange("month")}
            >
              1개월
            </S.NormalButton>
            <S.NormalButton
              variant="contained"
              sx={{ height: "2.5em" }}
              active={activeButton === "quarter"}
              onClick={() => handlePeriodChange("quarter")}
            >
              3개월
            </S.NormalButton>
          </S.Grid>
        </S.Grid>
        <S.Grid
          container
          spacing={1}
          alignItems="center"
          style={{ padding: "0 20px", margin: "0" }}
        >
          <S.InputLabel>신고상태</S.InputLabel>
          <S.Grid item xs={3}>
            <S.Select
              value={options.status}
              sx={{ width: "150px", height: "2.5em" }}
              onChange={(e) =>
                updateOptions("status", (e.target as HTMLSelectElement).value)
              }
            >
              <S.MenuItem value="all">전체</S.MenuItem>
              <S.MenuItem value="pending">접수</S.MenuItem>
              <S.MenuItem value="Y">처리완료</S.MenuItem>
              <S.MenuItem value="N">비처리</S.MenuItem>
            </S.Select>
          </S.Grid>
        </S.Grid>
        <S.Grid
          container
          spacing={1}
          alignItems="center"
          style={{ padding: "0 20px", margin: "0" }}
        >
          <S.InputLabel onClick={getReportedRepleList}>검색</S.InputLabel>
          <S.Grid item xs={10}>
            <S.Select
              value={options.searchType}
              sx={{ width: "10em", height: "2.5em", mr: 1 }}
              onChange={(e) =>
                updateOptions(
                  "searchType",
                  (e.target as HTMLSelectElement).value
                )
              }
            >
              <S.MenuItem value="email">이메일</S.MenuItem>
              <S.MenuItem value="name">이름</S.MenuItem>
            </S.Select>
            <S.Input
              type="text"
              onChange={(e) => updateOptions("search", e.target.value)}
            />
          </S.Grid>
        </S.Grid>
        <S.Grid container spacing={1} alignItems="center">
          <S.Grid
            item
            xs={12}
            style={{
              alignItems: "center",
              justifyContent: "center",
              margin: "0",
            }}
          >
            <S.SearchButton
              variant="contained"
              color="primary"
              style={{ width: "130px" }}
              onClick={() => getReportedRepleList({ ...options, status: options.status === "all" ? "" : options.status })}
            >
              검색
            </S.SearchButton>
          </S.Grid>
        </S.Grid>
      </S.Paper>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
          marginTop: "30px",
        }}
      >
        <div>총 {isSuccess ? reportedRepleOptions.data.results.length : 0}건</div>
        <div>
          <S.Select defaultValue={15} sx={{ mr: 1 }} onChange={handleRowCnt}>
            <S.MenuItem value={15}>15개씩 보기</S.MenuItem>
            <S.MenuItem value={50}>50개씩 보기</S.MenuItem>
            <S.MenuItem value={100}>100개씩 보기</S.MenuItem>
          </S.Select>
        </div>
      </div>
      <S.Paper elevation={0}>
        <S.TableContainer>
          <S.Table>
            <S.TableHead>
              <S.TableHeadRow>
                <S.TableHeadCell align="center">
                  <S.TableSortLabel
                    active={orderBy === "no"}
                    hideSortIcon={false}
                    direction={
                      orderBy === "no"
                        ? ["asc", "desc"].includes(orderDirection)
                          ? orderDirection
                          : "asc"
                        : "asc"
                    }
                    // onClick={() => handleSortRequest("no")}
                  >
                    No
                  </S.TableSortLabel>
                </S.TableHeadCell>
                <S.TableHeadCell align="center">
                  <S.TableSortLabel
                    active={orderBy === "createDate"}
                    hideSortIcon={false}
                    direction={
                      orderBy === "createDate"
                        ? ["asc", "desc"].includes(orderDirection)
                          ? orderDirection
                          : "asc"
                        : "asc"
                    }
                    // onClick={() => handleSortRequest("createDate")}
                  >
                    피신고자 이메일
                  </S.TableSortLabel>
                </S.TableHeadCell>
                <S.TableHeadCell align="center">
                  <S.TableSortLabel
                    active={orderBy === "createDate"}
                    hideSortIcon={false}
                    direction={
                      orderBy === "createDate"
                        ? ["asc", "desc"].includes(orderDirection)
                          ? orderDirection
                          : "asc"
                        : "asc"
                    }
                    // onClick={() => handleSortRequest("createDate")}
                  >
                    이름
                  </S.TableSortLabel>
                </S.TableHeadCell>
                <S.TableHeadCell align="center">신고건수</S.TableHeadCell>
                <S.TableHeadCell align="center">신고상태</S.TableHeadCell>
                <S.TableHeadCell align="center">
                  <S.TableSortLabel
                    active={orderBy === "answerDate"}
                    hideSortIcon={false}
                    direction={
                      orderBy === "answerDate"
                        ? ["asc", "desc"].includes(orderDirection)
                          ? orderDirection
                          : "asc"
                        : "asc"
                    }
                    // onClick={() => handleSortRequest("answerDate")}
                  >
                    최초 신고일시
                  </S.TableSortLabel>
                </S.TableHeadCell>
                <S.TableHeadCell align="center">
                  <S.TableSortLabel
                    active={orderBy === "earliestCreateDate"}
                    hideSortIcon={false}
                    direction={
                      orderBy === "earliestCreateDate"
                        ? ["asc", "desc"].includes(orderDirection)
                          ? orderDirection
                          : "asc"
                        : "asc"
                    }
                    // onClick={() => handleSortRequest("answerDate")}
                  >
                    처리일시
                  </S.TableSortLabel>
                </S.TableHeadCell>
              </S.TableHeadRow>
            </S.TableHead>
            <S.TableBody>
              {/* {isSuccess && questionData.length > 0 ? ( */}
              {reportRepleData.length > 0 ? (
                reportRepleData.slice(0, 10).map((row, index) => (
                  <S.TableRow
                    key={index}
                    onClick={() => handleRowClick(row.idx)}
                  >
                    <S.TableCell align="center">{index + 1}</S.TableCell>
                    <S.TableCell align="center">{row.email}</S.TableCell>
                    <S.TableCell>{row.name}</S.TableCell>
                    <S.TableCell>{row.cIdxCount}</S.TableCell>
                    <S.TableCell align="center">
                      {row.status}
                    </S.TableCell>
                    <S.TableCell align="center">{row.earliestCreateDate}</S.TableCell>
                    <S.TableCell align="center">{row.updateDate}</S.TableCell>
                  </S.TableRow>
                ))
              ) : (
                <S.TableRow>
                  <S.TableCell
                    align="center"
                      colSpan={7}
                    style={{ height: "110px" }}
                  >
                    검색 결과가 없습니다
                  </S.TableCell>
                </S.TableRow>
              )}
            </S.TableBody>
          </S.Table>
        </S.TableContainer>
      </S.Paper>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "30px",
          marginBottom: "30px",
        }}
      >
        <Pagination
          // count={isSuccess && totalPage ? totalPage : 1}
          count={totalPage ? totalPage : 1}
          color="standard"
          onChange={handlePageChange}
          page={page}
        />
      </Box>
    </S.Container>
  );
}
