import React, { useState, ChangeEvent, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as S from "../styles/AddNoticeStyles";
import {
  useNoticeDetail,
  useUpdateNotice,
} from "../../../utils/apiHooks/useBoardData";
import { useCryptoData } from "../../../utils/common/crypto";
import MyEditor from "../../../components/common/EditorQuill";
import { useInputs } from "../../../utils/customHooks/useInputData";
import CircularIndeterminate from "../../../components/common/ProgressBar";
import { usePopupOptions } from "../../../utils/customHooks/usePopupOptions";
import { Popup } from "../../../components/popup/Popup";

interface ResultData {
  type: string;
  title: string;
  content: string;
  fixing: string;
  posting: string;
  resultData: string;
}
export default function UpdateNotice() {
  const noticePk = useLocation().pathname.split("/")[2];
  const { handleDecrypt } = useCryptoData();
  const [content, setContent] = useState("");
  const [resultData, setResultData] = useState<ResultData | null>(null);
  const navigate = useNavigate();
  const pk = handleDecrypt(noticePk).idx;

  const {
    refetch: getNoticeDetail,
    data: noticeDetail,
    isSuccess,
    isLoading,
  } = useNoticeDetail(pk);

  const { setIsOpenPopup, popupOptions, setPopup } = usePopupOptions();

  const onSuccessUpdateNotice = (res: any) => {
    if (res.status) {
      setPopup("content", "수정이 완료되었습니다.");
      setPopup("type", "info");
      setPopup("callback", () => navigate("/board"));

      setIsOpenPopup(true);
    }
  };

  const { mutate: updateNotice } = useUpdateNotice(onSuccessUpdateNotice);

  const save = () => {
    const data = {
      idx: String(pk),
      type: resultData?.type,
      title: resultData?.title,
      content,
      fixing: resultData?.fixing,
      posting: resultData?.posting === "게시" ? "Y" : "N",
    };

    setPopup("content", "수정하시겠습니까?");
    setPopup("type", "confirm");
    setPopup("callback", () => updateNotice(data));

    setIsOpenPopup(true);
  };

  useEffect(() => {
    getNoticeDetail();
  }, []);

  useEffect(() => {
    noticeDetail && setResultData(noticeDetail.data.result);
  }, [isSuccess, noticeDetail]);

  return isSuccess && resultData && !isLoading ? (
    <S.Container>
      <S.Typography>게시판관리 {">"} 공지관리</S.Typography>
      <S.TableContainer>
        <S.Table aria-label="simple table">
          <S.TableBody>
            <S.TableRow>
              <S.TableCell>공지분류</S.TableCell>
              <S.TableCell>
                <S.Select
                  value={resultData.type}
                  style={{ width: "180px" }}
                  onChange={(e) => {
                    setResultData({
                      ...resultData,
                      type: (e.target as HTMLSelectElement).value,
                    });
                  }}
                >
                  <S.MenuItem value="알림">알림</S.MenuItem>
                  <S.MenuItem value="업데이트">업데이트</S.MenuItem>
                  <S.MenuItem value="시스템점검">시스템점검</S.MenuItem>
                  <S.MenuItem value="이벤트">이벤트</S.MenuItem>
                </S.Select>
              </S.TableCell>
            </S.TableRow>
            <S.TableRow>
              <S.TableCell style={{ width: "15%" }}>제목</S.TableCell>
              <S.TableCell style={{ width: "85%" }}>
                <S.Input
                  value={resultData.title}
                  onChange={(e) => {
                    setResultData({
                      ...resultData,
                      title: (e.target as HTMLInputElement).value,
                    });
                  }}
                ></S.Input>
              </S.TableCell>
            </S.TableRow>
            <S.TableRow
              style={{
                height: "340px",
              }}
            >
              <S.TableCell>내용</S.TableCell>
              <S.TableCell
                style={{
                  height: "320px",
                  overflow: "auto",
                }}
              >
                <MyEditor
                  initialValue={resultData.content}
                  setContents={setContent}
                />
              </S.TableCell>
            </S.TableRow>
            <S.TableRow>
              <S.TableCell>게시여부</S.TableCell>
              <S.TableCell style={{ display: "flex", flexDirection: "row" }}>
                <S.Select
                  style={{ width: "180px" }}
                  value={resultData.posting}
                  onChange={(e) => {
                    setResultData({
                      ...resultData,
                      posting: (e.target as HTMLSelectElement).value,
                    });
                  }}
                >
                  <S.MenuItem value="미게시">미게시</S.MenuItem>
                  <S.MenuItem value="게시">게시</S.MenuItem>
                </S.Select>
                <S.Text>
                  <S.Checkbox
                    sx={{
                      color: "#b7b7b7",
                      "&.Mui-checked": {
                        color: "#6565d1",
                      },
                    }}
                    checked={resultData.fixing === "Y" ? true : false}
                    onChange={(e) => {
                      setResultData({
                        ...resultData,
                        fixing: (e.target as HTMLInputElement).checked
                          ? "Y"
                          : "N",
                      });
                    }}
                  />
                  메인/목록 최상단 노출
                </S.Text>
              </S.TableCell>
            </S.TableRow>
          </S.TableBody>
        </S.Table>
      </S.TableContainer>
      <S.BtnBox>
        {/* <div style={{ justifyContent: "flex-start" }}>
          <S.DeleteButton>삭제</S.DeleteButton>
        </div> */}
        <S.NormalButton onClick={() => navigate("/board")}>목록</S.NormalButton>
        <S.SaveButton onClick={() => save()}>수정저장</S.SaveButton>
      </S.BtnBox>
      <Popup {...popupOptions} />
    </S.Container>
  ) : (
    <CircularIndeterminate />
  );
}
