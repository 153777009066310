import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import * as S from "./styles/ProgressBarStyles";

export default function CircularIndeterminate() {
    return (
        <S.CircularContainer>
            <Box sx={{ display: 'flex' }}>
            <CircularProgress />
        </Box>
        </S.CircularContainer>

    );
}