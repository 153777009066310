import React, { useState, useCallback, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { FormControlLabel, Radio, Box, InputLabel } from "@mui/material";
import * as S from "../styles/CommentReportDetailStyles";
import { useReportedRepleDetail, useUpdateReport } from "../../../utils/apiHooks/useReportData";
import { useSearchOptions } from "../../../utils/customHooks/useInputData";
import { Popup } from "../../../components/popup/Popup";
import { successResponseData } from "../../../interfaces/axios";
import { ResReportedRepleDetails } from "../../../interfaces/reportInterface";

// interface DetailData {
//   idx: string;
//   repleIdx: string;
//   userCode: string;
//   email: string;
//   name: string;
//   title: string;
//   reple: string;
//   cIdxCount: string;
//   earliestCreateDate: string;
//   status: string;
//   adminMemo: string;
//   adminId: string | null;
//   updateDate: string | null;
// }

// type ResponseType = {
//   status: boolean;
//   message: string;
//   data: { result: DetailData }; // `data` 부분은 유연하게 처리
// };

export default function CommentReportDetail() {
  const { idx } = useParams<{ idx: string }>();
  const [detailData, setDetailData] = useState<ResReportedRepleDetails | null>(null);
  const [isSaveBtnRemove, setIsSaveBtnRemove] = useState(false);
  const { options } = useSearchOptions({
    idx: "",
    status: "",
    adminId: "",
    adminMemo: ""
  });
  const navigate = useNavigate();
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [popupOptions, setPopupOptions] = useState({
    content: "",
    type: "confirm",
    callback: () => {
      console.log("popup closed!");
    },
    isOpenPopup,
    setIsOpenPopup,
  });

  // 입력 수정 핸들러
  const handleDataFetch = (data: ResReportedRepleDetails): void => {
    setDetailData(data);
  }


  // ---------- useMutation : 댓글 신고 상세 가져오기 ----------
  const onSuccessReportedRepleDetail = (data: successResponseData<ResReportedRepleDetails>) => {
    const { result } = data.data;
    handleDataFetch(result)
  }

  const onErrorReportedRepleDetail = (error: any) => {
    console.log("error: ", error);
  };

  const { mutate: getReportRepleDetail } = useReportedRepleDetail(
    onSuccessReportedRepleDetail,
    onErrorReportedRepleDetail
  );


  // ---------- useMutation : 댓글 신고 업데이트 -------------
  const onSuccessQuestionUpdate = useCallback((data: any) => {
    setPopupOptions((prevOptions) => ({
      ...prevOptions,
      content: "수정이 완료되었습니다.",
      type: "info",
      callback: () => navigate("/comment-report"),
    }));
    setIsOpenPopup(true);
  }, [navigate]);

  const onErrorQuestionUpdate = useCallback((error: any) => {
    console.log(error);
  }, []);

  const { mutate: updateReport } = useUpdateReport(
    onSuccessQuestionUpdate,
    onErrorQuestionUpdate
  );


  const handleBackClick = () => {
    navigate("/comment-report");
  };

  const save = () => {
    const updateData = { ...options };

    if (detailData && options) {
      Object.keys(options).forEach((key): void => {
        const k = key as keyof typeof options;
        updateData[k] = detailData[k] ?? options[k];
      })
    };

    if (!!updateData) {
      setPopupOptions({
        ...popupOptions,
        content: "해당 내용으로 수정하시겠습니까?",
        type: "confirm",
        callback: () => updateReport(updateData),
      });
      setIsOpenPopup(true);
    }
  };

  // ------------------ useEffect ------------------
  useEffect(() => {
    if (idx) {
      getReportRepleDetail(idx)
    }
  }, [idx, getReportRepleDetail]);


  return (
    <S.Container>
      {
        !!detailData && <><S.Typography>문의관리 {">"} 댓글신고</S.Typography>
          <Box>
            <InputLabel
              style={{
                marginTop: "20px",
                marginBottom: "10px",
                width: "300px",
                color: "#000000",
                fontWeight: "700",
              }}
            >
              • 피신고 회원계정 정보
            </InputLabel>
          </Box>
          <S.TableContainer>
            <S.Table aria-label="simple table">
              <S.TableBody>
                <S.TableRow>
                  <S.TableCell style={{ width: "15%" }}>이메일</S.TableCell>
                  <S.TableCell style={{ width: "35%" }}>
                    {detailData.email}
                  </S.TableCell>
                  <S.TableCell style={{ width: "15%" }}>등록일시</S.TableCell>
                  <S.TableCell style={{ width: "35%" }}>
                    {detailData.updateDate}
                  </S.TableCell>
                </S.TableRow>
                <S.TableRow>
                  <S.TableCell style={{ width: "15%" }}>이름</S.TableCell>
                  <S.TableCell colSpan={2}>{detailData.name}</S.TableCell>
                </S.TableRow>
              </S.TableBody>
            </S.Table>
            <Box>
              <InputLabel
                style={{
                  marginTop: "60px",
                  marginBottom: "10px",
                  width: "300px",
                  color: "#000000",
                  fontWeight: "700",
                }}
              >
                • 댓글 신고내역
              </InputLabel>
            </Box>
            <S.Table>
              <S.TableBody>
                <S.TableRow>
                  <S.TableCell style={{ width: "15%" }}>제목</S.TableCell>
                  <S.TableCell colSpan={2}>
                    {detailData.title}
                  </S.TableCell>
                </S.TableRow>
                <S.TableRow>
                  <S.TableCell style={{ width: "15%" }}>신고댓글</S.TableCell>
                  <S.TableCell colSpan={2}>{detailData.reple}</S.TableCell>
                </S.TableRow>
                <S.TableRow>
                  <S.TableCell style={{ width: "15%" }}>신고건수</S.TableCell>
                  <S.TableCell style={{ width: "35%" }}>{detailData.cIdxCount}</S.TableCell>
                  <S.TableCell style={{ width: "15%" }}>최초 신고일시</S.TableCell>
                  <S.TableCell style={{ width: "35%" }}>{detailData.earliestCreateDate}</S.TableCell>
                </S.TableRow>
              </S.TableBody>
            </S.Table>
            <Box>
              <InputLabel
                style={{
                  marginTop: "60px",
                  marginBottom: "10px",
                  width: "300px",
                  color: "#000000",
                  fontWeight: "700",
                }}
              >
                • 관리정보
              </InputLabel>
            </Box>
            <S.Table aria-label="simple table">
              <S.TableBody>
                <S.TableRow>
                  <S.TableCell>신고상태</S.TableCell>
                  <S.TableCell colSpan={3} style={{ width: "80%" }}>
                    <S.Select style={{ width: "180px" }} value={detailData.status} onChange={(e) => handleDataFetch({ ...detailData, status: e.target.value as string })}>
                      <S.MenuItem value="pending">접수</S.MenuItem>
                      <S.MenuItem value="Y">처리완료</S.MenuItem>
                      <S.MenuItem value="N">비처리</S.MenuItem>
                    </S.Select>
                  </S.TableCell>
                </S.TableRow>
                <S.TableRow>
                  <S.TableCell>관리자 메모</S.TableCell>
                  <S.TableCell colSpan={3} style={{ width: "80%" }}>
                    <S.TextField
                      multiline
                      rows={4}
                      variant="outlined"
                      defaultValue={detailData.adminMemo}
                      onChange={(e) => handleDataFetch({ ...detailData, adminMemo: e.target.value as string })}
                    ></S.TextField>
                  </S.TableCell>
                </S.TableRow>
                <S.TableRow>
                  <S.TableCell style={{ width: "15%" }}>처리일시</S.TableCell>
                  <S.TableCell style={{ width: "35%", padding: "0 0 0 10px" }}>
                    {detailData.updateDate}
                  </S.TableCell>
                  <S.TableCell style={{ width: "15%" }}>처리자</S.TableCell>
                  <S.TableCell style={{ width: "35%" }}>{detailData.adminId}</S.TableCell>
                </S.TableRow>
              </S.TableBody>
            </S.Table>
          </S.TableContainer>
          <S.BtnBox>
            <S.NormalButton onClick={handleBackClick}>목록</S.NormalButton>
            <S.SaveButton
              onClick={() => save()}
              style={{ display: isSaveBtnRemove ? "none" : "block" }}
            >
              수정저장
            </S.SaveButton>
          </S.BtnBox>
          <Popup
            {...popupOptions}
            isOpenPopup={isOpenPopup}
            setIsOpenPopup={setIsOpenPopup}
          />
        </>
      }
    </S.Container>
  );
}
