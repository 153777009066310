import axios from "axios";
const token = localStorage.getItem("token");

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "http://localhost:8000",
  // baseURL: "/",
  headers: { token: token },
});

console.log("REACT_APP_API_URL : ", process.env.REACT_APP_API_URL);

const onSuccess = (response: any) => {
  return response.data;
};

const onError = (error: any) => {
  const message = error.response.data.message;
  console.log("login error : ", message);
  if (message === "jwt expired") {
    localStorage.removeItem("token");
    window.location.href = "/login";
  }
  throw error.response;
};

export const request = ({ ...options }) => {
  return client(options).then(onSuccess).catch(onError);
};

export const getFormDataHeader = (token: String) => {
  return {
    "Content-Type": "multipart/form-data;",
    token,
  };
};
