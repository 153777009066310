import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Box, FormControlLabel, Radio, SelectChangeEvent } from "@mui/material";
import * as S from "../styles/WithdrawnStyles";
import Pagination from "@mui/material/Pagination";
import { useWithDrawMemberList } from "../../../utils/apiHooks/useMemberData";
import { useSearchOptions } from "../../../utils/customHooks/useInputData";
import {
  YYYY_MM_DD,
  diffInDays,
  useQuickDatePicker,
} from "../../../utils/customHooks/useDatePicker";
import { useCryptoData } from "../../../utils/common/crypto";
import { useExcelDownload } from "../../../utils/apiHooks/useExcelData";

export default function WithdrawnMember() {
  const [orderDirection, setOrderDirection] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState("no");
  const [withDrawMemberList, setWithDrawMemberList] = useState([]);
  const navigate = useNavigate();
  const [totalPage, setTotalPage] = useState(1);
  const [totalCnt, setTotalCnt] = useState(0);
  const [page, setPage] = useState(1);
  const [activeButton, setActiveButton] = useState("month");
  const { options, updateOptions } = useSearchOptions({
    dateType: "create_date",
    sDate: "",
    eDate: "",
    userType: 3,
    searchType: "email",
    search: "",
    limit: 15,
  });

  const { changeDate } = useQuickDatePicker(updateOptions);
  const { handleEncrypt } = useCryptoData();
  const initialRender = useRef(true);

  type DataItem = {
    no: number;
    email: string;
    name: string;
    memberType: string;
    approvalDate: string;
    withdrawalDate: string;
  };

  function isKeyOfDataItem(key: string): key is keyof DataItem {
    return [
      "no",
      "email",
      "name",
      "memberType",
      "approvalDate",
      "withdrawalDate",
    ].includes(key);
  }

  const handleSortRequest = (cellId: keyof DataItem) => {
    if (!isKeyOfDataItem(cellId)) return; // cellId가 DataItem의 키가 아니면 함수를 종료

    const isAsc = orderBy === cellId && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
    const sortedData = withDrawMemberList.sort((a, b) => {
      if (isAsc) {
        return a[cellId] < b[cellId] ? -1 : 1;
      } else {
        return a[cellId] > b[cellId] ? -1 : 1;
      }
    });
    setWithDrawMemberList([...sortedData]);
  };

  const handleRowClick = async (pk: string, email: string) => {
    const userPkData = {
      pk,
      email,
    };
    const encryptedData = await handleEncrypt(userPkData);

    const newUrl = `/withdrawn/${encryptedData}`;
    navigate(newUrl);
  };

  const search = () => {
    const diff = diffInDays(options.sDate, options.eDate);

    if (options.sDate === "" || options.eDate === "") {
      alert("조회 기간을 선택해주세요");
    } else if (diff > 365) {
      alert("조회 가능 기간은 최대 1년입니다");
    } else {
      const ops = { ...options };
      getWithDrawnList(ops);
    }
  };

  const onSuccessGetWithDrawn = useCallback((data: any) => {
    const { results } = data.data;
    results.map((v: any, k: any) => {
      v.no = k + 1;
      return v;
    });

    setWithDrawMemberList(results);
  }, []);

  const {
    mutate: getWithDrawnList,
    isSuccess,
    data: withDrawList,
  } = useWithDrawMemberList(onSuccessGetWithDrawn);

  // 보기 개수 변경
  const changeLimit = async (e: SelectChangeEvent<unknown>) => {
    await updateOptions("limit", Number((e.target as HTMLSelectElement).value));

    search();
  };

  // useMutation : 엑셀 다운로드
  const onSuccessExcelDownload = (data: any) => {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "download.xlsx";
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
  };

  const onErrorExcelDownload = (error: any) => {
    console.log(error.data.message);
  };

  const {
    mutate: downloadExcel,
    data,
    isSuccess: isDownloadSuccess,
  } = useExcelDownload(onSuccessExcelDownload, onErrorExcelDownload);

  // 엑셀 다운로드
  const download = () => {
    const downloadOptions = {
      category: "deletedUser",
      condition: {
        sDate: options.sDate,
        eDate: options.eDate,
        type: options.userType,
        searchType: options.searchType,
        search: options.search,
      },
    };

    downloadExcel(downloadOptions);
  };

  // 페이지 변경
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    search();
  };

  const handlePeriodChange = (type: string) => {
    setActiveButton(type);
    changeDate(type);
  };

  useEffect(() => {
    changeDate("month");
  }, []);

  // 초기 렌더링 시에만 리스트 자동 호출
  useEffect(() => {
    if (initialRender.current && options.sDate && options.eDate) {
      initialRender.current = false;
      const ops = { ...options };
      getWithDrawnList(ops);
    }
  }, [options, getWithDrawnList]);

  return (
    <S.Container>
      <S.Typography>회원관리 {">"} 탈퇴회원관리</S.Typography>
      <S.Paper elevation={0}>
        <S.Grid
          container
          spacing={1}
          alignItems="center"
          style={{ padding: "0 20px", margin: "0" }}
        >
          <S.InputLabel>조회기간</S.InputLabel>
          <S.Grid item xs={6}>
            {" "}
            <Box
              sx={{
                width: "25%",
                mr: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <S.Select
                defaultValue="create_date"
                onChange={(e) =>
                  updateOptions(
                    "dateType",
                    (e.target as HTMLSelectElement).value
                  )
                }
              >
                <S.MenuItem value="create_date">가입일</S.MenuItem>
                <S.MenuItem value="update_date">수정일</S.MenuItem>
              </S.Select>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                mr: 2,
              }}
            >
              <S.Input
                type="date"
                id="start-date"
                name="start-date"
                style={{ width: "180px" }}
                value={YYYY_MM_DD(options.sDate)}
                onChange={(e) =>
                  updateOptions("sDate", String(new Date(e.target.value)))
                }
              />
              ~
              <S.Input
                type="date"
                id="end-date"
                name="end-date"
                style={{ width: "180px" }}
                value={YYYY_MM_DD(options.eDate)}
                onChange={(e) =>
                  updateOptions("eDate", String(new Date(e.target.value)))
                }
              />
            </Box>
          </S.Grid>
          <S.Grid item>
            <S.NormalButton
              variant="contained"
              sx={{ mr: 1 }}
              active={activeButton === "day"}
              onClick={() => handlePeriodChange("day")}
            >
              오늘
            </S.NormalButton>
            <S.NormalButton
              variant="contained"
              sx={{ mr: 1 }}
              active={activeButton === "week"}
              onClick={() => handlePeriodChange("week")}
            >
              1주일
            </S.NormalButton>
            <S.NormalButton
              variant="contained"
              sx={{ mr: 1 }}
              active={activeButton === "month"}
              onClick={() => handlePeriodChange("month")}
            >
              1개월
            </S.NormalButton>
            <S.NormalButton
              variant="contained"
              sx={{ height: "2.5em" }}
              active={activeButton === "quarter"}
              onClick={() => handlePeriodChange("quarter")}
            >
              3개월
            </S.NormalButton>
          </S.Grid>
        </S.Grid>
        <S.Grid
          container
          spacing={1}
          alignItems="center"
          style={{ padding: "0 20px", margin: "0" }}
        >
          <S.InputLabel>회원타입</S.InputLabel>
          <S.Grid item xs={3}>
            <S.RadioGroup
              row
              onChange={(e) =>
                updateOptions("userType", Number(e.target.value))
              }
              defaultValue=""
            >
              <FormControlLabel
                value=""
                control={<Radio />}
                label="전체"
                defaultChecked
              />
              <FormControlLabel value="2" control={<Radio />} label="일반" />
            </S.RadioGroup>
          </S.Grid>
        </S.Grid>
        <S.Grid
          container
          spacing={1}
          alignItems="center"
          style={{ padding: "0 20px", margin: "0" }}
        >
          <S.InputLabel>검색</S.InputLabel>
          <S.Grid item xs={10}>
            <S.Select
              defaultValue="email"
              onChange={(e) =>
                updateOptions(
                  "searchType",
                  (e.target as HTMLSelectElement).value
                )
              }
            >
              <S.MenuItem value="email">이메일</S.MenuItem>
              <S.MenuItem value="name">이름</S.MenuItem>
            </S.Select>
            <S.Input
              type="text"
              onChange={(e) => updateOptions("search", e.target.value)}
            />
          </S.Grid>
        </S.Grid>
        <S.Grid container spacing={1} alignItems="center">
          <S.Grid
            item
            xs={12}
            style={{
              alignItems: "center",
              justifyContent: "center",
              margin: "0",
            }}
          >
            <S.SearchButton
              variant="contained"
              color="primary"
              style={{ width: "130px" }}
              onClick={() => search()}
            >
              검색
            </S.SearchButton>
          </S.Grid>
        </S.Grid>
      </S.Paper>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
          marginTop: "30px",
        }}
      >
        <div>총 {isSuccess && withDrawList.data.totalCnt}건</div>
        <div
          style={{
            height: "2.8em",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <S.RowSelect
            defaultValue={15}
            sx={{ mr: 1 }}
            onChange={(e) => changeLimit(e)}
          >
            <S.MenuItem value={15}>15개씩 보기</S.MenuItem>
            <S.MenuItem value={50}>50개씩 보기</S.MenuItem>
            <S.MenuItem value={100}>100개씩 보기</S.MenuItem>
          </S.RowSelect>
          <S.NormalButton variant="contained" onClick={download} active={false}>
            Excel 다운로드
          </S.NormalButton>
        </div>
      </div>
      <S.Paper elevation={0}>
        <S.TableContainer>
          <S.Table>
            <S.TableHead>
              <S.TableHeadRow>
                <S.TableHeadCell align="center">
                  <S.TableSortLabel
                    active={orderBy === "no"}
                    hideSortIcon={false}
                    direction={
                      orderBy === "no"
                        ? ["asc", "desc"].includes(orderDirection)
                          ? orderDirection
                          : "asc"
                        : "asc"
                    }
                    onClick={() => handleSortRequest("no")}
                  >
                    No
                  </S.TableSortLabel>
                </S.TableHeadCell>
                <S.TableHeadCell align="center">
                  <S.TableSortLabel
                    active={orderBy === "email"}
                    hideSortIcon={false}
                    direction={
                      orderBy === "email"
                        ? ["asc", "desc"].includes(orderDirection)
                          ? orderDirection
                          : "asc"
                        : "asc"
                    }
                    onClick={() => handleSortRequest("email")}
                  >
                    이메일주소
                  </S.TableSortLabel>
                </S.TableHeadCell>
                <S.TableHeadCell align="center">
                  <S.TableSortLabel
                    active={orderBy === "name"}
                    hideSortIcon={false}
                    direction={
                      orderBy === "name"
                        ? ["asc", "desc"].includes(orderDirection)
                          ? orderDirection
                          : "asc"
                        : "asc"
                    }
                    onClick={() => handleSortRequest("name")}
                  >
                    이름
                  </S.TableSortLabel>
                </S.TableHeadCell>
                <S.TableHeadCell align="center">회원타입</S.TableHeadCell>
                <S.TableHeadCell align="center">
                  <S.TableSortLabel
                    active={orderBy === "applicationDate"}
                    hideSortIcon={false}
                    direction={
                      orderBy === "applicationDate"
                        ? ["asc", "desc"].includes(orderDirection)
                          ? orderDirection
                          : "asc"
                        : "asc"
                    }
                    onClick={() => handleSortRequest("approvalDate")}
                  >
                    가입승인일시
                  </S.TableSortLabel>
                </S.TableHeadCell>
                <S.TableHeadCell align="center">
                  <S.TableSortLabel
                    active={orderBy === "withdrawalDate"}
                    hideSortIcon={false}
                    direction={
                      orderBy === "approvalDate"
                        ? ["asc", "desc"].includes(orderDirection)
                          ? orderDirection
                          : "asc"
                        : "asc"
                    }
                    onClick={() => handleSortRequest("withdrawalDate")}
                  >
                    탈퇴일시
                  </S.TableSortLabel>
                </S.TableHeadCell>
              </S.TableHeadRow>
            </S.TableHead>
            <S.TableBody>
              {isSuccess && withDrawMemberList.length > 0 ? (
                withDrawMemberList.map((data: any, key: number) => (
                  <S.TableRow
                    key={key}
                    onClick={() => handleRowClick(data.userPK, data.email)}
                  >
                    <S.TableCell align="center">{key + 1}</S.TableCell>
                    <S.TableCell>{data.email}</S.TableCell>
                    <S.TableCell align="center">{data.name}</S.TableCell>
                    <S.TableCell align="center">{data.typeName}</S.TableCell>
                    <S.TableCell align="center">
                      {data.permissionDate}
                    </S.TableCell>
                    <S.TableCell align="center">{data.deletedDate}</S.TableCell>
                  </S.TableRow>
                ))
              ) : (
                <S.TableRow>
                  <S.TableCell
                    align="center"
                    colSpan={7}
                    style={{ height: "110px" }}
                  >
                    검색 결과가 없습니다
                  </S.TableCell>
                </S.TableRow>
              )}
            </S.TableBody>
          </S.Table>
        </S.TableContainer>
      </S.Paper>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "30px",
          marginBottom: "30px",
        }}
      >
        <Pagination
          count={isSuccess && totalPage ? totalPage : 1}
          color="standard"
          onChange={handlePageChange}
          page={page}
        />
      </Box>
    </S.Container>
  );
}
