import { useMutation } from "@tanstack/react-query";
import { request } from './axios-utils';



// 회원 목록
const memberList = (memberSearchOptions: any) => {
    console.log("검색검색 : ", memberSearchOptions);

    return request({
        url: "/user/list",
        method: "POST",
        data: { ...memberSearchOptions },
    });
};

export const useMemberList = (
    onSuccess: any
) => {
    return useMutation({
        mutationFn: memberList,
        onSuccess
    })
}



// 회원 상세
const memberDetail = (pkData: any) => {
    return request({
        url: "/user/detail",
        method: "POST",
        data: { ...pkData }
    });
}

export const useMemberDetail = (onSuccess: any) => {
    return useMutation({
        mutationFn: memberDetail,
        onSuccess
    })
}



// 회원 정보 수정
const memberUpdate = (upDateData: any) => {
    console.log("upDateData: ", upDateData);

    return request({
        url: "/user/update",
        method: "PUT",
        data: { ...upDateData }
    });
}

export const useMemberUpdate = (onSuccess: any) => {
    return useMutation({
        mutationFn: memberUpdate,
        onSuccess
    })
}



// 탈퇴 회원 목록
const withDrawMemberList = (searchData: any) => {
    return request({
        url: "/user/deleted/list",
        method: "POST",
        data: { ...searchData },
    });
};


export const useWithDrawMemberList = (
    onSuccess: any
) => {
    return useMutation({
        mutationFn: withDrawMemberList,
        onSuccess
    })
}



// 회원 상세
const withDrawMemberDetail = (pkData: any) => {
    return request({
        url: "/user/deleted/detail",
        method: "POST",
        data: { ...pkData }
    });
}

export const useWithDrawMemberDetail = (onSuccess: any) => {
    return useMutation({
        mutationFn: withDrawMemberDetail,
        onSuccess
    })
}