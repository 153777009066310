import React, { useState, ChangeEvent, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, FormControlLabel, Radio, InputLabel } from "@mui/material";
import * as S from "../styles/WithdrawnDetailStyles";
import { useCryptoData } from "../../../utils/common/crypto";
import { useWithDrawMemberDetail } from "../../../utils/apiHooks/useMemberData";
import CircularIndeterminate from "../../../components/common/ProgressBar";

interface detailDataInterface {
  email: string;
  name: string;
  typeName: string;
  permissionDate: string;
  deletedReason: string;
  deletedDate: string;
};

export default function WithdrawnDetail() {
  const userPkData = useLocation().pathname.split("/")[2];
  const [adminMemo, setAdminMemo] = useState("메모를 입력해주세요.");
  const [detailData, setDetailData] = useState<detailDataInterface | null>(null);
  const { handleDecrypt } = useCryptoData();
  const navigate = useNavigate();


  const handleAdminMemoChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAdminMemo(event.target.value);
  };

  const onSuccessGetWithdrawDetail = (res: any) => {
    setDetailData(res.data.result);
  }

  const { mutate: getWithDrawDetail, isSuccess } = useWithDrawMemberDetail(onSuccessGetWithdrawDetail);


  useEffect(() => {
    const decryptedData = handleDecrypt(userPkData);
    if (Object.keys(decryptedData).length > 0) getWithDrawDetail(decryptedData);
  }, []);

  return (
    isSuccess && detailData ? <S.Container>
      <S.Typography>회원관리 {">"} 탈퇴회원관리</S.Typography>
      <Box>
        <InputLabel
          style={{
            marginTop: "20px",
            marginBottom: "10px",
            width: "150px",
            color: "#000000",
            fontWeight: "700",
          }}
        >
          • 회원계정 정보
        </InputLabel>
      </Box>
      <S.TableContainer>
        <S.Table aria-label="simple table">
          <S.TableBody>
            <S.TableRow>
              <S.TableCell style={{ width: "20%" }}>이메일</S.TableCell>
              <S.TableCell
                style={{ cursor: "pointer", textDecoration: "underline" }}
              >
                {detailData.email}
              </S.TableCell>
              <S.TableCell style={{ width: "20%" }}>이름</S.TableCell>
              <S.TableCell>{detailData.name}</S.TableCell>
            </S.TableRow>
            <S.TableRow>
              <S.TableCell>회원타입</S.TableCell>
              <S.TableCellWithButton>{detailData.typeName}</S.TableCellWithButton>
              <S.TableCell>가입승인일시</S.TableCell>
              <S.TableCell>{detailData.permissionDate}</S.TableCell>
            </S.TableRow>
          </S.TableBody>
        </S.Table>
      </S.TableContainer>
      <Box>
        <InputLabel
          style={{
            marginTop: "50px",
            marginBottom: "10px",
            width: "150px",
            color: "#000000",
            fontWeight: "700",
          }}
        >
          • 관리정보
        </InputLabel>
        <S.TableContainer>
          <S.Table aria-label="simple table">
            <S.TableBody>
              <S.TableRow>
                <S.TableCell style={{ width: "20%" }}>탈퇴사유</S.TableCell>
                <S.TableCell style={{ width: "80%" }}>
                  {detailData.deletedReason}
                </S.TableCell>
              </S.TableRow>
              <S.TableRow>
                <S.TableCell>탈퇴일시</S.TableCell>
                <S.TableCell>{detailData.deletedDate}</S.TableCell>
              </S.TableRow>
            </S.TableBody>
          </S.Table>
        </S.TableContainer>
      </Box>
      <S.BtnBox>
        <S.NormalButton onClick={() => navigate("/withdrawn")}>목록</S.NormalButton>
        <S.SaveButton>수정저장</S.SaveButton>
      </S.BtnBox>
    </S.Container> : <CircularIndeterminate />
  );
}
