import { useState, useCallback } from 'react';

export function useInputs(initialValue: any) {
    const [value, setValue] = useState(initialValue);
    const handler = useCallback((e: any) => {
        setValue(e.target.value);
    }, []);
    return [value, handler, setValue];
}

export function useSearchOptions<T>(initialOptions: T) {
    const [options, setOptions] = useState<T>(initialOptions);

    const updateOptions = useCallback(<K extends keyof T>(key: K, value: T[K] | null) => {
        setOptions(op => ({
            ...op,
            [key]: value,
        }))
    }, [])

    return { options, updateOptions }
}
