import React from "react";
import "./App.css";
import routes from "./routes/routes";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import AppProviders from "./context";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const router = createBrowserRouter(routes);
// const queryClient = new QueryClient();

function App() {
  return (
    // <QueryClientProvider client={queryClient}>
    <AppProviders>
      <RouterProvider router={router} />
    </AppProviders>
    // </QueryClientProvider>
  );
}

export default App;
