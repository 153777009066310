import React from "react";
import { request } from './axios-utils';
import { Mutation, useMutation, useQuery } from "@tanstack/react-query";

// 어드민 목록
const getAdminList = (adminOptions: Object) => {
    return request({
        url: "/admin/list",
        method: "POST",
        data: { ...adminOptions }
    })
}

export const useAdminList = (
    onSuccess: any
) => {
    return useMutation({
        mutationFn: getAdminList,
        onSuccess
    })
}


// 어드민 등록
const addAdmin = (AdminData: any): Promise<any> => {
    console.log(AdminData);
    return request({
        url: "/admin/create",
        method: "POST",
        data: { ...AdminData }
    })
}

export const useAddAdmin = (onSuccess: any) => {
    return useMutation({
        mutationFn: addAdmin,
        onSuccess
    })
}



// 어드민 상세
const getAdminDetail = async (AdminPk: any): Promise<any> => {
    return await request({
        url: "/admin/detail",
        method: "POST",
        data: { ...AdminPk }
    })
}

export const useAdminDetail = (onSuccess: any) => {
    return useMutation({
        mutationFn: getAdminDetail,
        onSuccess
    })
}


// 어드민 수정
const getAdminUpdate = (adminData: any): Promise<any> => {
    console.log(adminData);

    return request({
        url: "/admin/update",
        method: "PUT",
        data: { ...adminData }
    })
}

export const useUpdateAdmin = (onSuccess: any) => {
    return useMutation({
        mutationFn: getAdminUpdate,
        onSuccess
    })
}

