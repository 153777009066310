import React from "react";

import Layout from "../components/common/Layout";
import NotFound from "../components/common/NotFound";

import Login from "../pages/Login";
import Member from "../pages/Member/pages/Member";
import MemberDetail from "../pages/Member/pages/MemberDetail";
import Withdrawn from "../pages/Member/pages/Withdrawn";
import WithdrawnDetail from "../pages/Member/pages/WithdrawnDetail";
import AIPrediction from "../pages/Contents/pages/AIPrediction";
import AIDetail from "../pages/Contents/pages/AIDetail";
import Notice from "../pages/Board/pages/Notice";
import AddNotice from "../pages/Board/pages/AddNotice";
import UpdateNotice from "../pages/Board/pages/UpdateNotice";
import Qna from "../pages/QnA/pages/Qna";
import QnaDetail from "../pages/QnA/pages/QnaDetail";
import CommentReport from "../pages/QnA/pages/CommentReport";
import CommentReportDetail from "../pages/QnA/pages/CommentReportDetail";
import PostReport from "../pages/QnA/pages/PostReport";
import PostReportDetail from "../pages/QnA/pages/PostReportDetail";
import Admin from "../pages/Admin/pages/Admin";
import AddAdmin from "../pages/Admin/pages/AddAdmin";
import UpdateAdmin from "../pages/Admin/pages/updateAdmin";
import ProtectedRoute from "../components/common/ProtectedRoute";

const routes = [
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <Layout />
      </ProtectedRoute>
    ),
    errorElement: <NotFound />,
    children: [
      { index: true, element: <Member /> },
      { path: "member", element: <Member /> },
      { path: "member/:userCode", element: <MemberDetail /> },
      { path: "withdrawn", element: <Withdrawn /> },
      { path: "withdrawn/:userCode", element: <WithdrawnDetail /> },
      { path: "ai-prediction", element: <AIPrediction /> },
      { path: "ai-prediction/:idx", element: <AIDetail /> },
      { path: "board", element: <Notice /> },
      { path: "board/add-board", element: <AddNotice /> },
      { path: "board/:id", element: <UpdateNotice /> },
      { path: "qna", element: <Qna /> },
      { path: "qna/:idx", element: <QnaDetail /> },
      { path: "comment-report", element: <CommentReport /> },
      { path: "comment-report/:idx", element: <CommentReportDetail /> },
      { path: "post-report", element: <PostReport /> },
      { path: "post-report/:idx", element: <PostReportDetail /> },
      { path: "admin", element: <Admin /> },
      { path: "add-admin", element: <AddAdmin /> },
      { path: "update-admin/:id", element: <UpdateAdmin /> },
      //   ],
      // },
    ],
  },
  {
    path: "/login",
    element: <Login />, // 로그인 페이지는 직접 접근 가능
    errorElement: <NotFound />,
  },
];

export default routes;
