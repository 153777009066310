import React, { useCallback, useEffect, useState } from "react";



/**
 * usePopupOptions 사용법
 * 
 * const { isOpenPopup, setIsOpenPopup, popupOptions, setPopup } = usePopupOptions(); 로 불러와서 사용
 * 
 * -- setPoup() : 팝업 내용, 타입등 설정 ex. setPopup("content", "저장하시겠습니까?");
 * -- popupOptions : 팝업 컴포넌트에 넘겨줘야하는 props ex. <Popup {...popupOptions} />
 * -- isOpenPopup, setIsOpenPopup 은 팝업 오픈 상태값과 핸들러
 * 
 */
export function usePopupOptions() {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState({
        content: '',
        type: "info",
        callback: Function,
        isOpenPopup: open,
        setIsOpenPopup: setOpen
    });


    const setPopup = useCallback((key: string, val: any) => {
        setOptions(op => ({
            ...op,
            [key]: val,
        }))
    }, []);

    useEffect(() => {
        setPopup("isOpenPopup", open);
    }, [open, setPopup]);

    return { isOpenPopup: open, setIsOpenPopup: setOpen, popupOptions: options, setPopup };
};



