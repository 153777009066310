import React, {
  useState,
  useEffect,
  ChangeEvent,
  useCallback,
  useRef,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, FormControlLabel, Radio } from "@mui/material";
import * as S from "../styles/AIPredictionStyles";
import Pagination from "@mui/material/Pagination";
import {
  YYYY_MM_DD,
  diffInDays,
  useQuickDatePicker,
} from "../../../utils/customHooks/useDatePicker";
import { SelectChangeEvent } from "@mui/material/Select";
import { useSearchOptions } from "../../../utils/customHooks/useInputData";
import { useCryptoData } from "../../../utils/common/crypto";
import { usePredictionList } from "../../../utils/apiHooks/useDiseaseData";
import { useExcelDownload } from "../../../utils/apiHooks/useExcelData";
import { usePredictions } from "../../../context/PredictionContext";
import { Popup } from "../../../components/popup/Popup";

interface Prediction {
  no: number;
  idx: number;
  email: string;
  name: string;
  content: string;
  complete: boolean;
  testResult: string;
  createDate: string;
}

export default function AIPrediction() {
  const { predictions, setPredictions, clearPredictions } = usePredictions();
  const [predictionData, setPredictionData] = useState<Prediction[]>([]);
  const [processStatus, setProcessStatus] = useState("all");
  const [resultStatus, setResultStatus] = useState("all");
  const [orderDirection, setOrderDirection] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState("no");
  const navigate = useNavigate();
  const location = useLocation();
  const [totalPage, setTotalPage] = useState(1);
  const [totalCnt, setTotalCnt] = useState(0);
  const [page, setPage] = useState(1);
  const [activeButton, setActiveButton] = useState("month");
  const initialRender = useRef(true);
  const { options, updateOptions } = useSearchOptions({
    sDate: "",
    eDate: "",
    complete: "",
    testResult: "",
    searchType: "content",
    search: "",
    limit: 15,
    page,
  });
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [popupOptions, setPopupOptions] = useState({
    content: "권한이 없습니다.",
    type: "info",
    callback: () => {
      console.log("popup closed!");
    },
    isOpenPopup,
    setIsOpenPopup,
  });

  const { changeDate } = useQuickDatePicker(updateOptions);

  type DataItem = {
    idx: number;
    content: string;
    email: string;
    name: string;
    complete: string;
    testResult: string;
    createDate: string;
  };

  // useMutation : 검사신청 목록 가져오기
  const onSuccessPredictionList = useCallback((data: any) => {
    const { results } = data.data;

    const formattedResults: Prediction[] = results.map((v: any, k: number) => ({
      ...v,
      no: k + 1,
      idx: v.idx,
      content: v.content,
      email: v.email,
      name: v.name,
      complete: v.complete,
      testResult: v.testResult,
      createDate: v.createDate,
    }));

    setPredictionData(formattedResults);
    setPredictions(formattedResults);

    console.log(data.data);

    setTotalPage(data.data.totalPage);
    setTotalCnt(data.data.totalCnt);
    // sessionStorage.setItem("predictionData", JSON.stringify(formattedResults));
  }, []);

  const onErrorPredictionList = (error: any) => {
    console.log(error.data.message);
    if (
      error.data.message === "Unauthorized" ||
      error.data.message === "Forbidden"
    ) {
      alert("권한이 없습니다.");
    }
  };

  const {
    mutate: getPredictionList,
    data: predictionListData,
    isSuccess,
  } = usePredictionList(onSuccessPredictionList, onErrorPredictionList);

  // useMutation : 엑셀 다운로드
  const onSuccessExcelDownload = (data: any) => {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "download.xlsx";
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
  };

  const onErrorExcelDownload = (error: any) => {
    console.log(error.data.message);
  };

  const {
    mutate: downloadExcel,
    data,
    isSuccess: isDownloadSuccess,
  } = useExcelDownload(onSuccessExcelDownload, onErrorExcelDownload);

  function isKeyOfDataItem(key: any): key is keyof DataItem {
    return [
      "no",
      "idx",
      "email",
      "name",
      "complete",
      "testResult",
      "createDate",
    ].includes(key);
  }

  const handleSortRequest = (cellId: string) => {
    if (!isKeyOfDataItem(cellId)) return; // cellId가 DataItem의 키가 아니면 함수를 종료

    const isAsc = orderBy === cellId && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
    const sortedData = predictions.sort((a, b) => {
      if (isAsc) {
        return a[cellId] < b[cellId] ? -1 : 1;
      } else {
        return a[cellId] > b[cellId] ? -1 : 1;
      }
    });
    setPredictions([...sortedData]);
  };

  const handleRowClick = async (pk: number) => {
    console.log("pk: ", pk);
    const newUrl = `/ai-prediction/${pk}`;
    navigate(newUrl);
  };

  // 페이지 변경
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    search();
  };

  // 검색
  const search = () => {
    const diff = diffInDays(options.sDate, options.eDate);

    if (options.sDate === "" || options.eDate === "") {
      alert("조회 기간을 선택해주세요");
    } else if (diff > 365) {
      alert("조회 가능 기간은 최대 1년입니다");
    } else {
      const ops = { ...options };
      getPredictionList(ops);
    }
  };

  // 엑셀 다운로드
  const download = () => {
    const downloadOptions = {
      category: "disease",
      condition: {
        sDate: options.sDate,
        eDate: options.eDate,
        complete: options.complete,
        testReset: options.testResult,
        searchType: options.searchType,
        search: options.search,
      },
    };

    downloadExcel(downloadOptions);
  };

  const handleRowCnt = (e: SelectChangeEvent<unknown>) => {
    const newLimit = Number(e.target.value);
    updateOptions("limit", newLimit);
    const ops = { ...options, limit: newLimit };
    getPredictionList(ops);
  };

  const handlePeriodChange = (type: string) => {
    setActiveButton(type);
    changeDate(type);
  };

  // ---------------------- useEffect ----------------------
  useEffect(() => {
    if (isSuccess) {
      console.log("predictionListData : ", predictionListData);
    }
  }, [isSuccess, predictionListData]);

  useEffect(() => {
    return () => {
      if (!location.pathname.startsWith("/ai-prediction")) {
        clearPredictions(); // /ai-prediction 이외의 페이지로 이동 시 predictions 초기화
      }
    };
  }, [location, clearPredictions]);

  useEffect(() => {
    const ops = { ...options };
    getPredictionList(ops);
  }, []);

  useEffect(() => {
    // const today = new Date();
    // const oneWeekAgo = new Date(today);
    // oneWeekAgo.setDate(today.getDate() - 7);

    // updateOptions("sDate", YYYY_MM_DD(oneWeekAgo));
    // updateOptions("eDate", YYYY_MM_DD(today));

    changeDate("month");
  }, []);

  // 초기 렌더링 시에만 리스트 자동 호출
  useEffect(() => {
    if (initialRender.current && options.sDate && options.eDate) {
      initialRender.current = false;
      const ops = { ...options };
      getPredictionList(ops);
    }
  }, [options, getPredictionList]);

  return (
    <S.Container>
      <S.Typography>콘텐츠관리 {">"} AI검사관리</S.Typography>
      <S.Paper elevation={0}>
        <S.Grid
          container
          spacing={1}
          alignItems="center"
          style={{ padding: "0 20px", margin: "0" }}
        >
          <S.InputLabel>조회기간</S.InputLabel>
          <S.Grid item xs={5}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                mr: 2,
              }}
            >
              <S.Input
                type="date"
                id="sDate"
                name="sDate"
                style={{ width: "190px" }}
                value={YYYY_MM_DD(options.sDate)}
                onChange={(e) =>
                  updateOptions("sDate", String(new Date(e.target.value)))
                }
              />
              ~
              <S.Input
                type="date"
                id="eDate"
                name="eDate"
                style={{ width: "190px" }}
                value={YYYY_MM_DD(options.eDate)}
                onChange={(e) =>
                  updateOptions("eDate", String(new Date(e.target.value)))
                }
              />
            </Box>
          </S.Grid>
          <S.Grid item>
            <S.NormalButton
              variant="contained"
              sx={{
                mr: 1,
              }}
              active={activeButton === "day"}
              onClick={() => handlePeriodChange("day")}
            >
              오늘
            </S.NormalButton>
            <S.NormalButton
              variant="contained"
              sx={{
                mr: 1,
              }}
              active={activeButton === "week"}
              onClick={() => handlePeriodChange("week")}
            >
              1주일
            </S.NormalButton>
            <S.NormalButton
              variant="contained"
              sx={{
                mr: 1,
              }}
              active={activeButton === "month"}
              onClick={() => handlePeriodChange("month")}
            >
              1개월
            </S.NormalButton>
            <S.NormalButton
              variant="contained"
              sx={{
                height: "2.5em",
              }}
              active={activeButton === "quarter"}
              onClick={() => handlePeriodChange("quarter")}
            >
              3개월
            </S.NormalButton>
          </S.Grid>
        </S.Grid>
        <S.Grid
          container
          spacing={1}
          alignItems="center"
          style={{ padding: "0 20px", margin: "0" }}
        >
          <S.InputLabel>처리상태</S.InputLabel>
          <S.Grid item xs={4}>
            <S.RadioGroup
              row
              defaultValue=""
              onChange={(e) => updateOptions("complete", e.target.value)}
            >
              <FormControlLabel value="" control={<Radio />} label="전체" />
              <FormControlLabel value="N" control={<Radio />} label="처리중" />
              <FormControlLabel
                value="Y"
                control={<Radio />}
                label="처리완료"
              />
            </S.RadioGroup>
          </S.Grid>
          <S.InputLabel>검사결과</S.InputLabel>
          <S.Grid item xs={4}>
            <S.RadioGroup
              row
              defaultValue=""
              onChange={(e) => updateOptions("testResult", e.target.value)}
            >
              <FormControlLabel value="" control={<Radio />} label="전체" />
              <FormControlLabel
                value="normal"
                control={<Radio />}
                label="정상"
              />
              <FormControlLabel
                value="abnormal"
                control={<Radio />}
                label="이상"
              />
            </S.RadioGroup>
          </S.Grid>
        </S.Grid>
        <S.Grid
          container
          spacing={1}
          alignItems="center"
          style={{ padding: "0 20px", margin: "0" }}
        >
          <S.InputLabel>검색</S.InputLabel>
          <S.Grid item xs={10}>
            <S.Select
              value={options.searchType}
              sx={{ width: "10em", height: "2.5em", mr: 1 }}
              onChange={(e) =>
                updateOptions(
                  "searchType",
                  (e.target as HTMLSelectElement).value
                )
              }
            >
              <S.MenuItem value="content">문의내용</S.MenuItem>
              <S.MenuItem value="email">이메일</S.MenuItem>
              <S.MenuItem value="name">이름</S.MenuItem>
            </S.Select>
            <S.Input
              type="text"
              onChange={(e) => updateOptions("search", e.target.value)}
            />
          </S.Grid>
        </S.Grid>
        <S.Grid container spacing={1} alignItems="center">
          <S.Grid
            item
            xs={12}
            style={{
              alignItems: "center",
              justifyContent: "center",
              margin: "0",
            }}
          >
            <S.SearchButton
              variant="contained"
              color="primary"
              style={{ width: "130px" }}
              onClick={() => search()}
            >
              검색
            </S.SearchButton>
          </S.Grid>
        </S.Grid>
      </S.Paper>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
          marginTop: "30px",
        }}
      >
        <div>총 {totalCnt}건</div>
        <div
          style={{
            height: "2.8em",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <S.RowSelect defaultValue={15} sx={{ mr: 1 }} onChange={handleRowCnt}>
            <S.MenuItem value={15}>15개씩 보기</S.MenuItem>
            <S.MenuItem value={50}>50개씩 보기</S.MenuItem>
            <S.MenuItem value={100}>100개씩 보기</S.MenuItem>
          </S.RowSelect>
          <S.NormalButton variant="contained" onClick={download} active={false}>
            Excel 다운로드
          </S.NormalButton>
        </div>
      </div>
      <S.Paper elevation={0}>
        <S.TableContainer>
          <S.Table>
            <S.TableHead>
              <S.TableHeadRow>
                <S.TableHeadCell align="center">
                  <S.TableSortLabel
                    active={orderBy === "no"}
                    hideSortIcon={false}
                    direction={
                      orderBy === "no"
                        ? ["asc", "desc"].includes(orderDirection)
                          ? orderDirection
                          : "asc"
                        : "asc"
                    }
                    onClick={() => handleSortRequest("no")}
                  >
                    No
                  </S.TableSortLabel>
                </S.TableHeadCell>
                <S.TableHeadCell align="center">문의내용</S.TableHeadCell>
                <S.TableHeadCell align="center">
                  <S.TableSortLabel
                    active={orderBy === "email"}
                    hideSortIcon={false}
                    direction={
                      orderBy === "email"
                        ? ["asc", "desc"].includes(orderDirection)
                          ? orderDirection
                          : "asc"
                        : "asc"
                    }
                    onClick={() => handleSortRequest("email")}
                  >
                    이메일주소
                  </S.TableSortLabel>
                </S.TableHeadCell>
                <S.TableHeadCell align="center">
                  <S.TableSortLabel
                    active={orderBy === "name"}
                    hideSortIcon={false}
                    direction={
                      orderBy === "name"
                        ? ["asc", "desc"].includes(orderDirection)
                          ? orderDirection
                          : "asc"
                        : "asc"
                    }
                    onClick={() => handleSortRequest("name")}
                  >
                    이름
                  </S.TableSortLabel>
                </S.TableHeadCell>
                <S.TableHeadCell align="center">처리상태</S.TableHeadCell>
                <S.TableHeadCell align="center">검사결과</S.TableHeadCell>
                <S.TableHeadCell align="center">
                  <S.TableSortLabel
                    active={orderBy === "createDate"}
                    hideSortIcon={false}
                    direction={
                      orderBy === "createDate"
                        ? ["asc", "desc"].includes(orderDirection)
                          ? orderDirection
                          : "asc"
                        : "asc"
                    }
                    onClick={() => handleSortRequest("createDate")}
                  >
                    등록일시
                  </S.TableSortLabel>
                </S.TableHeadCell>
              </S.TableHeadRow>
            </S.TableHead>
            <S.TableBody>
              {isSuccess && predictions.length > 0 ? (
                predictions.slice(0, 10).map((row, index) => (
                  <S.TableRow
                    key={index}
                    onClick={() => handleRowClick(row.idx)}
                  >
                    <S.TableCell align="center">{row.idx}</S.TableCell>
                    <S.TableCell>{row.content}</S.TableCell>
                    <S.TableCell align="center">{row.email}</S.TableCell>
                    <S.TableCell align="center">{row.name}</S.TableCell>
                    <S.TableCell align="center">{row.complete}</S.TableCell>
                    <S.TableCell align="center">{row.testResult}</S.TableCell>
                    <S.TableCell align="center">{row.createDate}</S.TableCell>
                  </S.TableRow>
                ))
              ) : (
                <S.TableRow>
                  <S.TableCell
                    align="center"
                    colSpan={7}
                    style={{ height: "110px" }}
                  >
                    검색 결과가 없습니다
                  </S.TableCell>
                </S.TableRow>
              )}
            </S.TableBody>
          </S.Table>
        </S.TableContainer>
      </S.Paper>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "30px",
          marginBottom: "30px",
        }}
      >
        <Pagination
          count={isSuccess && totalPage ? totalPage : 1}
          color="standard"
          onChange={handlePageChange}
          page={page}
        />
      </Box>
      <Popup {...popupOptions} />
    </S.Container>
  );
}
