import React, { useState, ChangeEvent, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, InputLabel, FormControlLabel, Radio, keyframes } from "@mui/material";
import * as S from "../styles/UpdateAdminStyles";
import { useCryptoData } from "../../../utils/common/crypto";
import { usePopupOptions } from "../../../utils/customHooks/usePopupOptions";
import { useAdminDetail, useUpdateAdmin } from "../../../utils/apiHooks/useAdminData";
import { Popup } from "../../../components/popup/Popup";
import CircularIndeterminate from "../../../components/common/ProgressBar";


interface objInterface {
  [key: string]: any
}
interface permissionInterface {
  menuName: string;
  menuCode: string;
  permission: string | null;
}

interface adminInterface {
  email: string;
  id: string;
  name: string;
  permissions: Array<permissionInterface>;
  phone: string;
  status: string;
  typeCode: string;
  typeName: string;
  updateDate: string;
  userPK: string;
  pw: string;
}

export default function UpdateAdmin() {
  const { handleDecrypt } = useCryptoData();
  const adminPkData = handleDecrypt(useLocation().pathname.split("/")[2]);
  const navigate = useNavigate();
  const [adminDetailData, setAdminDetailData] = useState<adminInterface>();
  const { setIsOpenPopup, popupOptions, setPopup } = usePopupOptions();


  const onSuccessGetAdminDetail = (res: any) => {
    setAdminDetailData(res.data.result);
    console.log(res.data.result);

  }

  const { mutate: getAdminDetail, isSuccess } = useAdminDetail(onSuccessGetAdminDetail);


  useEffect(() => {
    if (Object.keys(adminPkData).length > 0) getAdminDetail(adminPkData);
  }, []);


  // permission 값 가져오기
  const getPermission = (key: any) => {
    const data = adminDetailData && adminDetailData?.permissions.filter((v) => { return v.menuName === key });
    const permission = data && (data[0].permission === null || data[0].permission === "D" ? "none" : data[0].permission);

    return permission;
  };

  // permission 값 수정시
  const setPermission = (key: any, value: string) => {
    const newArr = adminDetailData && adminDetailData.permissions.map((v) => {
      if (v.menuName === key) v.permission = value;
      return v;
    });
    newArr && setAdminDetailData({ ...adminDetailData, permissions: newArr });
  };


  const onSuccessUpdateAdmin = (res: any) => {
    if (res.status) {
      setPopup("content", "수정이 완료되었습니다.");
      setPopup("type", "info");
      setPopup("callback", () => navigate("/admin"));

      setIsOpenPopup(true);
    }
  };

  const { mutate: updateAdmin } = useUpdateAdmin(onSuccessUpdateAdmin);

  const save = () => {
    const newObj: objInterface = {};

    adminDetailData?.permissions.map((v) => {
      newObj[v.menuCode.toString()] = v.permission === null ? "N" : v.permission;
    });



    const data = {
      pk: adminPkData.pk,
      id: adminDetailData?.id,
      type: adminDetailData?.typeCode,
      permissions: { ...newObj }
    }

    updateAdmin(data);
  }

  return (
    isSuccess && adminDetailData ? <S.Container>
      <S.Typography>운영자관리 {">"} 운영자조회/수정</S.Typography>
      <Box>
        <InputLabel
          style={{
            marginTop: "20px",
            marginBottom: "10px",
            width: "150px",
            color: "#000000",
            fontWeight: "700",
          }}
        >
          • 운영자계정 정보
        </InputLabel>
      </Box>
      <S.TableContainer>
        <S.Table aria-label="simple table">
          <S.TableBody>
            <S.TableRow>
              <S.TableCell style={{ width: "15%" }}>아이디</S.TableCell>
              <S.TableCell>
                <S.Input value={adminDetailData.id} onChange={(e) => { setAdminDetailData({ ...adminDetailData, id: (e.target as HTMLInputElement).value }) }} />
              </S.TableCell>
              <S.TableCell style={{ width: "15%" }}>이름</S.TableCell>
              <S.TableCell>
                <S.Input value={adminDetailData.name} onChange={(e) => { setAdminDetailData({ ...adminDetailData, name: (e.target as HTMLInputElement).value }) }} />
              </S.TableCell>
            </S.TableRow>
            <S.TableRow>
              <S.TableCell>비밀번호</S.TableCell>
              <S.TableCell>
                <S.Input value="*******" style={{ marginRight: "10px" }} />
                <S.NormalButton>수정</S.NormalButton>
              </S.TableCell>
              <S.TableCell>권한</S.TableCell>
              <S.TableCell>
                <S.Select value={adminDetailData.typeCode} onChange={(e) => setAdminDetailData({ ...adminDetailData, typeCode: (e.target as HTMLSelectElement).value })}>
                  <S.MenuItem value="select">선택</S.MenuItem>
                  <S.MenuItem value="97">일반관리자</S.MenuItem>
                  <S.MenuItem value="99">슈퍼관리자</S.MenuItem>
                  <S.MenuItem value="98">질병관리사</S.MenuItem>
                </S.Select>
              </S.TableCell>
            </S.TableRow>
            <S.TableRow>
              <S.TableCell>이메일</S.TableCell>
              <S.TableCell>
                <S.Input value={adminDetailData.email} />
              </S.TableCell>
              <S.TableCell>연락처</S.TableCell>
              <S.TableCell>
                <S.Input value={adminDetailData.phone} />
              </S.TableCell>
            </S.TableRow>
            <S.TableRow>
              <S.TableCell>계정상태</S.TableCell>
              <S.TableCell>
                <S.RadioGroup row defaultValue={adminDetailData.status}>
                  <FormControlLabel
                    value="비활성화"
                    control={<Radio />}
                    label="비활성화"
                  />
                  <FormControlLabel
                    value="활성화"
                    control={<Radio />}
                    label="활성화"
                  />
                </S.RadioGroup>
              </S.TableCell>
              <S.TableCell>수정일</S.TableCell>
              <S.TableCell>{adminDetailData.updateDate}</S.TableCell>
            </S.TableRow>
          </S.TableBody>
        </S.Table>
      </S.TableContainer>
      <Box>
        <InputLabel
          style={{
            marginTop: "20px",
            marginBottom: "10px",
            width: "150px",
            color: "#000000",
            fontWeight: "700",
          }}
        >
          • 운영자 권한 설정
        </InputLabel>
      </Box>
      <S.TableContainer>
        <S.Table aria-label="simple table">
          <S.TableBody>
            <S.TableRow
              style={{
                backgroundColor: "#f7f7f7",
                border: "1px solid #ebebebeb",
              }}
            >
              <S.AuthTitleCell
                style={{
                  width: "35%",
                }}
                colSpan={2}
              >
                접근메뉴
              </S.AuthTitleCell>
              <S.AuthTitleCell style={{ width: "15%", textAlign: "center" }}>
                권한구분
              </S.AuthTitleCell>
              <S.AuthTitleCell
                style={{ width: "35%", textAlign: "center" }}
                colSpan={2}
              >
                접근메뉴
              </S.AuthTitleCell>
              <S.AuthTitleCell style={{ width: "15%", textAlign: "center" }}>
                권한구분
              </S.AuthTitleCell>
            </S.TableRow>
            <S.TableRow>
              <S.AuthTableCell rowSpan={4} style={{ textAlign: "center" }}>
                콘텐츠관리
              </S.AuthTableCell>
              <S.AuthTableCell>AI검사관리</S.AuthTableCell>
              <S.AuthTableCell>
                <S.Select
                  value={getPermission("AI검사관리")}
                  onChange={(e) => setPermission("AI검사관리", (e.target as HTMLInputElement).value)}
                >
                  <S.MenuItem value="none">없음</S.MenuItem>
                  <S.MenuItem value="R">조회</S.MenuItem>
                  <S.MenuItem value="U">조회+수정</S.MenuItem>
                </S.Select>
              </S.AuthTableCell>
              <S.AuthTableCell rowSpan={4} style={{ textAlign: "center" }}>
                게시판관리
              </S.AuthTableCell>
              <S.AuthTableCell>공지관리</S.AuthTableCell>
              <S.AuthTableCell>
                <S.Select value={getPermission("공지관리")} onChange={(e) => setPermission("공지관리", (e.target as HTMLInputElement).value)}>
                  <S.MenuItem value="none">없음</S.MenuItem>
                  <S.MenuItem value="R">조회</S.MenuItem>
                  <S.MenuItem value="U">조회+수정</S.MenuItem>
                </S.Select>
              </S.AuthTableCell>
            </S.TableRow>
            <S.TableRow>
              <S.AuthTableCell>백과사전관리</S.AuthTableCell>
              <S.AuthTableCell>
                <S.Select value={getPermission("백과사전관리")} onChange={(e) => setPermission("백과사전관리", (e.target as HTMLInputElement).value)}>
                  <S.MenuItem value="none">없음</S.MenuItem>
                  <S.MenuItem value="R">조회</S.MenuItem>
                  <S.MenuItem value="U">조회+수정</S.MenuItem>
                </S.Select>
              </S.AuthTableCell>
              <S.AuthTableCell>배너관리</S.AuthTableCell>
              <S.AuthTableCell>
                <S.Select value={getPermission("배너관리")} onChange={(e) => setPermission("배너관리", (e.target as HTMLInputElement).value)}>
                  <S.MenuItem value="none">없음</S.MenuItem>
                  <S.MenuItem value="R">조회</S.MenuItem>
                  <S.MenuItem value="U">조회+수정</S.MenuItem>
                </S.Select>
              </S.AuthTableCell>
            </S.TableRow>
            <S.TableRow>
              <S.AuthTableCell>지도관리</S.AuthTableCell>
              <S.AuthTableCell>
                <S.Select value={getPermission("지도관리")} onChange={(e) => setPermission("지도관리", (e.target as HTMLInputElement).value)}>
                  <S.MenuItem value="none">없음</S.MenuItem>
                  <S.MenuItem value="R">조회</S.MenuItem>
                  <S.MenuItem value="U">조회+수정</S.MenuItem>
                </S.Select>
              </S.AuthTableCell>
              <S.AuthTableCell>FAQ관리</S.AuthTableCell>
              <S.AuthTableCell>
                <S.Select value={getPermission("FAQ관리")} onChange={(e) => setPermission("FAQ관리", (e.target as HTMLInputElement).value)}>
                  <S.MenuItem value="none">없음</S.MenuItem>
                  <S.MenuItem value="R">조회</S.MenuItem>
                  <S.MenuItem value="U">조회+수정</S.MenuItem>
                </S.Select>
              </S.AuthTableCell>
            </S.TableRow>
            <S.TableRow>
              <S.AuthTableCell>캘린더관리</S.AuthTableCell>
              <S.AuthTableCell>
                <S.Select value={getPermission("캘린더관리")} onChange={(e) => setPermission("캘린더관리", (e.target as HTMLInputElement).value)}>
                  <S.MenuItem value="none">없음</S.MenuItem>
                  <S.MenuItem value="R">조회</S.MenuItem>
                  <S.MenuItem value="U">조회+수정</S.MenuItem>
                </S.Select>
              </S.AuthTableCell>
              <S.AuthTableCell>약관관리</S.AuthTableCell>
              <S.AuthTableCell>
                <S.Select value={getPermission("약관관리")} onChange={(e) => setPermission("약관관리", (e.target as HTMLInputElement).value)}>
                  <S.MenuItem value="none">없음</S.MenuItem>
                  <S.MenuItem value="R">조회</S.MenuItem>
                  <S.MenuItem value="U">조회+수정</S.MenuItem>
                </S.Select>
              </S.AuthTableCell>
            </S.TableRow>
            <S.TableRow>
              <S.AuthTableCell rowSpan={2} style={{ textAlign: "center" }}>
                문의관리
              </S.AuthTableCell>
              <S.AuthTableCell>1:1문의</S.AuthTableCell>
              <S.AuthTableCell>
                <S.Select value={getPermission("1:1문의")} onChange={(e) => setPermission("1:1문의", (e.target as HTMLInputElement).value)}>
                  <S.MenuItem value="none">없음</S.MenuItem>
                  <S.MenuItem value="R">조회</S.MenuItem>
                  <S.MenuItem value="U">조회+수정</S.MenuItem>
                </S.Select>
              </S.AuthTableCell>
              <S.AuthTableCell style={{ textAlign: "center" }}>
                운영자관리
              </S.AuthTableCell>
              <S.AuthTableCell>운영자조회/수정</S.AuthTableCell>
              <S.AuthTableCell>
                <S.Select value={getPermission("운영자조회/수정")} onChange={(e) => setPermission("운영자조회/수정", (e.target as HTMLInputElement).value)}>
                  <S.MenuItem value="none">없음</S.MenuItem>
                  <S.MenuItem value="R">조회</S.MenuItem>
                  <S.MenuItem value="U">조회+수정</S.MenuItem>
                </S.Select>
              </S.AuthTableCell>
            </S.TableRow>
            <S.TableRow>
              <S.AuthTableCell>신고관리</S.AuthTableCell>
              <S.AuthTableCell>
                <S.Select value={getPermission("신고관리")} onChange={(e) => setPermission("신고관리", (e.target as HTMLInputElement).value)}>
                  <S.MenuItem value="none">없음</S.MenuItem>
                  <S.MenuItem value="R">조회</S.MenuItem>
                  <S.MenuItem value="U">조회+수정</S.MenuItem>
                </S.Select>
              </S.AuthTableCell>
            </S.TableRow>
            <S.TableRow>
              <S.AuthTableCell rowSpan={2} style={{ textAlign: "center" }}>
                회원관리
              </S.AuthTableCell>
              <S.AuthTableCell>회원조회/수정</S.AuthTableCell>
              <S.AuthTableCell>
                <S.Select value={getPermission("회원조회/수정")} onChange={(e) => setPermission("회원조회/수정", (e.target as HTMLInputElement).value)}>
                  <S.MenuItem value="none">없음</S.MenuItem>
                  <S.MenuItem value="R">조회</S.MenuItem>
                  <S.MenuItem value="U">조회+수정</S.MenuItem>
                </S.Select>
              </S.AuthTableCell>
            </S.TableRow>
            <S.TableRow>
              <S.AuthTableCell>탈퇴회원관리</S.AuthTableCell>
              <S.AuthTableCell>
                <S.Select value={getPermission("탈퇴회원관리")} onChange={(e) => setPermission("탈퇴회원관리", (e.target as HTMLInputElement).value)}>
                  <S.MenuItem value="none">없음</S.MenuItem>
                  <S.MenuItem value="R">조회</S.MenuItem>
                  <S.MenuItem value="U">조회+수정</S.MenuItem>
                </S.Select>
              </S.AuthTableCell>
            </S.TableRow>
          </S.TableBody>
        </S.Table>
      </S.TableContainer>
      <S.BtnBox>
        <S.NormalButton onClick={() => navigate('/admin')}>목록</S.NormalButton>
        <S.SaveButton onClick={() => save()}>저장</S.SaveButton>
      </S.BtnBox>
      <Popup {...popupOptions} />
    </S.Container> : <CircularIndeterminate />
  );
}
