import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  ReactNode,
} from "react";

// Prediction 인터페이스 정의
interface Prediction {
  no: number;
  idx: number;
  email: string;
  name: string;
  content: string;
  complete: boolean;
  testResult: string;
  createDate: string;
}

// Context 타입 정의
interface PredictionContextType {
  predictions: Prediction[];
  setPredictions: React.Dispatch<React.SetStateAction<Prediction[]>>;
  clearPredictions: () => void;
}

interface PredictionProviderProps {
  children: ReactNode;
}

const PredictionContext = createContext<PredictionContextType | undefined>(
  undefined
);

export const PredictionProvider: React.FC<PredictionProviderProps> = ({
  children,
}) => {
  const [predictions, setPredictions] = useState<Prediction[]>([]);

  const clearPredictions = useCallback(() => {
    setPredictions([]);
  }, []);

  return (
    <PredictionContext.Provider
      value={{ predictions, setPredictions, clearPredictions }}
    >
      {children}
    </PredictionContext.Provider>
  );
};

export const usePredictions = (): PredictionContextType => {
  const context = useContext(PredictionContext);
  if (context === undefined) {
    throw new Error("usePredictions must be used within a PredictionProvider");
  }
  return context;
};
