import React, { useState, useEffect } from "react";

import Sheet from "@mui/joy/Sheet";
import CssBaseline from "@mui/joy/CssBaseline";
import Typography from "@mui/joy/Typography";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Button from "@mui/joy/Button";
import { Popup } from "../components/popup/Popup";
import { useLogin } from "../../src/utils/apiHooks/useAuthData";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useLocalStorage from "../utils/customHooks/useLoginCheck";

function Login() {
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useLocalStorage<boolean>(
    "isLoggedIn",
    false
  );
  const navigate = useNavigate();

  const sheetSx = {
    width: 600,
    height: 370,
    mx: "auto",
    my: 4,
    p: 5,
    display: "flex",
    flexDirection: "column",
    gap: 2,
    borderRadius: "sm",
    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "15px",
    fontFamily: "NanumGothic",
    backgroundColor: "#fafafa",
    border: "1px solid #ded8e3",
  };

  const popupOptions = {
    content: "아이디, 비밀번호를 확인하세요.",
    type: "info",
    callback: () => {
      console.log("popup closed!");
    },
    isOpenPopup,
    setIsOpenPopup,
  };

  const onSuccessLogin = (data: any) => {
    console.log("Login successful!", data);
    localStorage.setItem("token", data.data.token);
    window.dispatchEvent(new Event("storageChange"));
    setIsLoggedIn(true);
    navigate("/");
    window.document.location.reload();
  };

  const onErrorLogin = (error: any) => {
    console.error("Login failed!", error?.data.message);
    setIsOpenPopup(true);
    setIsLoggedIn(false);
  };

  const {
    mutate: login,
    data,
    isSuccess,
  } = useLogin(onSuccessLogin, onErrorLogin);

  const handleLoginSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (userId && password) {
      console.log("inside try");
      const credentials = { id: userId, pw: password };
      login(credentials);
    } else {
      setIsOpenPopup(true);
    }
  };

  useEffect(() => {
    const handleStorageChange = () => {
      const token = localStorage.getItem("token");
      if (token) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    };

    window.addEventListener("storageChange", handleStorageChange);
    return () => {
      window.removeEventListener("storageChange", handleStorageChange);
    };
  }, []);

  return (
    <main>
      <CssBaseline />
      <div>
        <Typography
          level="h2"
          component="h1"
          sx={{ mt: 20, display: "flex", justifyContent: "center" }}
        >
          <b>AquaSafe Administrator</b>
        </Typography>
      </div>
      <Sheet
        component="form"
        sx={sheetSx}
        variant="outlined"
        onSubmit={handleLoginSubmit}
      >
        <FormControl
          sx={{
            width: "400px",
            height: "37px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: 4,
          }}
        >
          <FormLabel
            sx={{
              width: "120px",
              fontWeight: "600",
              height: "37px",
              fontSize: "15px",
            }}
          >
            아이디
          </FormLabel>
          <Input
            value={userId}
            name="aquaSafeAdminID"
            placeholder="아이디를 입력하세요"
            onChange={(e) => setUserId(e.target.value)}
            style={{ width: "300px", height: "37px" }}
          />
        </FormControl>
        <FormControl
          sx={{
            width: "400px",
            height: "37px",
            display: "flex",
            flexDirection: "row",
            gap: 4,
          }}
        >
          <FormLabel
            sx={{
              width: "120px",
              fontWeight: "600",
              height: "37px",
              fontSize: "15px",
            }}
          >
            비밀번호
          </FormLabel>
          <Input
            value={password}
            name="aquaSafeAdminPW"
            type="password"
            placeholder="비밀번호를 입력하세요"
            onChange={(e) => setPassword(e.target.value)}
            style={{ width: "300px", height: "37px" }}
          />
        </FormControl>
        <Button
          type="submit"
          sx={{
            mt: 6,
            height: 50,
            width: 400,
            backgroundColor: "#8989db",
            "&:hover": {
              backgroundColor: "#6565d1",
            },
            fontSize: "18px",
          }}
        >
          로그인
        </Button>
      </Sheet>
      <Popup {...popupOptions} />
    </main>
  );
}
export default Login;

/*
interface Permissions {
  aiManagement: string;
  encyclopediaManagement: string;
  mapManagement: string;
  calenderManagement: string;
  qnaManagement: string;
  reportManagement: string;
  memberManagement: string;
  withdrawManagement: string;
  noticeManagement: string;
  bannerManagement: string;
  faqManagement: string;
  termManagement: string;
  adminManagement: string;
}

*/
