import { useMutation, useQuery } from "@tanstack/react-query";
import { request } from "./axios-utils";
import { AxiosResponse, AxiosError } from "axios";

export interface ErrorStructure {
  message: string;
  code: number;
}

// 검사신청 목록
const questionList = (questionSearchOptions: Object) => {
  return request({
    url: "/question/list",
    method: "POST",
    data: { ...questionSearchOptions },
  });
};

export const useQuestionList = (
  onSuccess: any,
  onError: (error: AxiosError<ErrorStructure>) => void
) => {
  return useMutation({
    mutationFn: questionList,
    onSuccess,
    onError,
  });
};

// 검사 상세
const questionDetail = (idx: Number) => {
  return request({
    url: `/question/detail/${idx}`,
    method: "GET",
  });
};

export const useQuestionDetail = (
  onSuccess: any,
  onError: (error: AxiosError<ErrorStructure>) => void
) => {
  return useMutation({
    mutationFn: questionDetail,
    onSuccess,
    onError,
  });
};

// 검사 업데이트
const questionUpdate = (updateData: Object) => {
  return request({
    url: "/question/update",
    method: "PUT",
    data: { ...updateData },
  });
};

export const useQuestionUpdate = (
  onSuccess: any,
  onError: (error: AxiosError<ErrorStructure>) => void
) => {
  return useMutation({
    mutationFn: questionUpdate,
    onSuccess,
    onError,
  });
};
