import React, { useState, ChangeEvent } from "react";
import { useNavigate, useNavigation } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import * as S from "../styles/AddNoticeStyles";
import ReactQuill from "react-quill";
import MyEditor from "../../../components/common/EditorQuill";
import { useInputs } from "../../../utils/customHooks/useInputData";
import { useAddNotice } from "../../../utils/apiHooks/useBoardData";
import { usePopupOptions } from "../../../utils/customHooks/usePopupOptions";
import { Popup } from "../../../components/popup/Popup";

export default function AddNotice() {
  const [content, setContent] = useState('');
  const [type, useType, setType] = useInputs('알림');
  const [title, useTitle, setTitle] = useInputs('');
  const [fixing, useFixing, setFixing] = useInputs(undefined);
  const [posting, usePosting, setPosting] = useInputs('Y');
  const { setPopup, setIsOpenPopup, popupOptions } = usePopupOptions();
  const navigate = useNavigate();



  const onSuccessAddNotice = () => {
    setPopup("type", "info");
    setPopup("content", "등록이 완료되었습니다");
    setPopup("callback", () => navigate("/board"));

    setIsOpenPopup(true);
  };

  const { mutate: addNotice } = useAddNotice(onSuccessAddNotice);

  const save = () => {
    console.log("ddddddd");

    const data = {
      content,
      type,
      title,
      fixing: fixing === "on" ? "Y" : "N",
      posting
    }
    setPopup("type", "confirm");
    setPopup("content", "해당 내용으로 등록하시겠습니까?");
    setPopup("callback", () => addNotice(data));

    setIsOpenPopup(true);
  };

  return (
    <S.Container>
      <S.Typography>게시판관리 {">"} 공지관리</S.Typography>
      <S.TableContainer>
        <S.Table aria-label="simple table">
          <S.TableBody>
            <S.TableRow>
              <S.TableCell>공지분류</S.TableCell>
              <S.TableCell>
                <S.Select defaultValue="알림" style={{ width: "180px" }} onChange={useType}>
                  <S.MenuItem value="알림">알림</S.MenuItem>
                  <S.MenuItem value="업데이트">업데이트</S.MenuItem>
                  <S.MenuItem value="시스템점검">시스템점검</S.MenuItem>
                  <S.MenuItem value="이벤트">이벤트</S.MenuItem>
                </S.Select>
              </S.TableCell>
            </S.TableRow>
            <S.TableRow>
              <S.TableCell style={{ width: "15%" }}>제목</S.TableCell>
              <S.TableCell style={{ width: "85%" }}>
                <S.Input onChange={useTitle}></S.Input>
              </S.TableCell>
            </S.TableRow>
            <S.TableRow>
              <S.TableCell>내용</S.TableCell>
              <S.TableCell style={{ height: "330px", overflow: "auto" }}>
                <MyEditor
                  initialValue={content}
                  setContents={setContent}
                />
              </S.TableCell>
            </S.TableRow>
            <S.TableRow>
              <S.TableCell>게시여부</S.TableCell>
              <S.TableCell style={{ display: "flex", flexDirection: "row" }}>
                <S.Select defaultValue="N" style={{ width: "180px" }} onChange={usePosting}>
                  <S.MenuItem value="N">미게시</S.MenuItem>
                  <S.MenuItem value="Y">게시</S.MenuItem>
                </S.Select>
                <S.Text>
                  <S.Checkbox
                    sx={{
                      color: "#b7b7b7",
                      "&.Mui-checked": {
                        color: "#6565d1",
                      },
                    }}
                    onChange={useFixing}
                  />
                  메인/목록 최상단 노출
                </S.Text>
              </S.TableCell>
            </S.TableRow>
          </S.TableBody>
        </S.Table>
      </S.TableContainer>
      <S.BtnBox>
        <S.NormalButton>목록</S.NormalButton>
        <S.SaveButton onClick={() => save()}>저장</S.SaveButton>
      </S.BtnBox>
      <Popup {...popupOptions} />
    </S.Container>
  )
}
