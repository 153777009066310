import styled from "styled-components";
import * as M from "@mui/material";

export const Container = styled(M.Container)`
  && {
    width: 100%;
    height: 120px;
    background-color: #c4c4c4;
  }

  @media (min-width: 1200px) {
    && {
      max-width: none; // 1200px 이상에서는 max-width를 무시하도록 설정
    }
  }
`;

export const Typography = styled(M.Typography)`
  && {
    font-size: 23px;
    margin: 20px 0 15px 0;
    font-weight: 700;
  }
`;

export const AdminButton = styled(M.Button)`
  && {
    color: #000000;
    background-color: #e0e0e0;
    font-weight: 600;
    padding: 5px 13px;
  }

  &&:hover {
    background-color: #8989db;
    color: #ffffff;
  }
`;

export const LogoutButton = styled(M.Button)`
  && {
    color: #000000;
    background-color: #e0e0e0;
    font-weight: 600;
    padding: 5px 13px;
    margin-right: 10px;
  }

  &&:hover {
    background-color: #8989db;
    color: #ffffff;
  }
`;
