import React, { useCallback, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Box, FormControlLabel, Radio, SelectChangeEvent } from "@mui/material";
import * as S from "../styles/MemberStyles";
import Pagination from "@mui/material/Pagination";
import { useSearchOptions } from "../../../utils/customHooks/useInputData";
import {
  YYYY_MM_DD,
  diffInDays,
  useQuickDatePicker,
} from "../../../utils/customHooks/useDatePicker";
import { useMemberList } from "../../../utils/apiHooks/useMemberData";
import { useExcelDownload } from "../../../utils/apiHooks/useExcelData";
import { useCryptoData } from "../../../utils/common/crypto";

export default function Member() {
  type DataItem = {
    no: number;
    email: string;
    name: string;
    memberType: string;
    status: string;
    createDate: string;
    approvalDate: string;
  };

  const [orderDirection, setOrderDirection] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState("no");
  const navigate = useNavigate();
  const [memberData, setMemberData] = useState([]);
  const [page, setPage] = useState(1);
  const [activeButton, setActiveButton] = useState("month");
  const [isChangeDate, setIsChageDate] = useState(false);
  const { options, updateOptions } = useSearchOptions({
    dateType: "create_date",
    sDate: "",
    eDate: "",
    userType: 0,
    status: "",
    searchType: "email",
    search: "",
    limit: 15,
    page: 1,
    orderby: orderBy,
    scending: orderDirection.toUpperCase()
  });

  const { changeDate } = useQuickDatePicker(updateOptions);
  const { handleEncrypt } = useCryptoData();
  const changeDateRef = useRef(true);
  const searchTrigger = useRef<HTMLButtonElement>(null);
  const sDateRef = useRef<HTMLInputElement>(null);

  const onSuccessMemberList = useCallback((data: any) => {
    const { results } = data.data;

    results.map((v: any, k: any) => {
      v.no = k + 1;
      return v;
    });

    setMemberData(results);
  }, []);

  const {
    mutate: getUserList,
    data: memberListData,
    isSuccess,
  } = useMemberList(onSuccessMemberList);

  useEffect(() => {
    if (isSuccess) {
      console.log("memberListData : ", memberListData);
    }
  }, [isSuccess, memberListData]);

  useEffect(() => {
    updateOptions("page", page);
  }, [page]);

  function isKeyOfDataItem(key: any): key is keyof DataItem {
    return [
      "no",
      "email",
      "name",
      "typeCode",
      "status",
      "createDate",
      "permissionDate",
    ].includes(key);
  }

  const handleSortRequest = async (cellId: string, orderDirection: string) => {
    if (!isKeyOfDataItem(cellId)) return; // cellId가 DataItem의 키가 아니면 함수를 종료

    const direction = orderDirection === "asc" ? "desc" : "asc";

    setOrderDirection(direction);
    setOrderBy(cellId);

    await updateOptions("orderby", cellId);
    await updateOptions("scending", direction.toUpperCase());


    searchTrigger.current && searchTrigger.current.click();
  };



  const handleRowClick = async (pk: string, email: string) => {
    const userPkData = {
      pk,
      email,
    };
    const encryptedData = await handleEncrypt(userPkData);

    const newUrl = `/member/${encryptedData}`;
    navigate(newUrl);
  };

  // 페이지 변경
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    searchTrigger.current && searchTrigger.current.click();
    // search();
  };

  // 검색 버튼
  const search = () => {
    changeDateRef.current = true;
    const diff = diffInDays(options.sDate, options.eDate);

    if (
      options.sDate === ""
      || options.eDate === ""
      || options.sDate === "Invalid Date"
      || options.eDate === "Invalid Date"
    ) {
      alert("조회 기간을 선택해주세요");
    } else if (diff > 365) {
      alert("조회 가능 기간은 최대 1년입니다");
    } else {
      const ops = { ...options };

      getUserList(ops);
    }
  };

  // 보기 개수 변경
  const changeLimit = async (e: SelectChangeEvent<unknown>) => {
    await updateOptions("limit", Number((e.target as HTMLSelectElement).value));

    searchTrigger.current && searchTrigger.current.click();
  };

  // useMutation : 엑셀 다운로드
  const onSuccessExcelDownload = (data: any) => {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "download.xlsx";
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
  };

  const onErrorExcelDownload = (error: any) => {
    console.log(error.data.message);
  };

  const {
    mutate: downloadExcel,
    data,
    isSuccess: isDownloadSuccess,
  } = useExcelDownload(onSuccessExcelDownload, onErrorExcelDownload);

  // 엑셀 다운로드
  const download = () => {
    const downloadOptions = {
      category: "user",
      condition: {
        dateType: options.dateType,
        sDate: options.sDate,
        eDate: options.eDate,
        userType: options.userType,
        status: options.status,
        searchType: options.searchType,
        search: options.search,
      },
    };

    downloadExcel(downloadOptions);
  };


  const handlePeriodChange = (type: string) => {
    setActiveButton(type);
    changeDate(type);
  };


  // 날짜 할당
  useEffect(() => {
    changeDate("month");
  }, []);


  // 초기 렌더시에만 검색 호출 - 날짜 변경으로 검색 트리거X
  useEffect(() => {
    // searchTrigger ref가 돔을 참조한 이후 & 날짜 할당된 이후 검색 호출
    if (searchTrigger.current && !isChangeDate && options.eDate !== "" && options.sDate !== "") {
      searchTrigger.current.click();
      setIsChageDate(true);
    }
  }, [isChangeDate, options.eDate, options.sDate]);


  // useEffect(() => {
  //   searchTrigger.current && searchTrigger.current.click();
  // }, [orderBy, orderDirection]);



  return (
    <S.Container>
      <S.Typography>회원관리 {">"} 회원조회/수정</S.Typography>
      <S.Paper elevation={0}>
        <S.Grid
          container
          spacing={1}
          alignItems="center"
          style={{ padding: "0 20px", margin: "0" }}
        >
          <S.InputLabel>조회기간</S.InputLabel>
          <S.Grid item xs={6}>
            {" "}
            <Box
              sx={{
                mr: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <S.Select
                style={{ width: "8em" }}
                defaultValue="create_date"
                onChange={(e) =>
                  updateOptions(
                    "dateType",
                    (e.target as HTMLSelectElement).value
                  )
                }
              >
                <S.MenuItem value="create_date">가입일</S.MenuItem>
                <S.MenuItem value="update_date">수정일</S.MenuItem>
              </S.Select>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                mr: 2,
              }}
            >
              <S.Input
                type="date"
                id="sDate"
                name="sDate"
                style={{ width: "180px" }}
                value={YYYY_MM_DD(options.sDate)}
                ref={sDateRef}
                onChange={(e) => {
                  updateOptions("sDate", String(new Date(e.target.value)));
                }
                }
              />
              ~
              <S.Input
                type="date"
                id="eDate"
                name="eDate"
                style={{ width: "180px" }}
                value={YYYY_MM_DD(options.eDate)}
                onChange={(e) => {
                  updateOptions("eDate", String(new Date(e.target.value)));
                }
                }
              />
            </Box>
          </S.Grid>
          <S.Grid item>
            <S.NormalButton
              variant="contained"
              sx={{ mr: 1 }}
              active={activeButton === "day"}
              onClick={() => handlePeriodChange("day")}
            >
              오늘
            </S.NormalButton>
            <S.NormalButton
              variant="contained"
              sx={{ mr: 1 }}
              active={activeButton === "week"}
              onClick={() => handlePeriodChange("week")}
            >
              1주일
            </S.NormalButton>
            <S.NormalButton
              variant="contained"
              sx={{ mr: 1 }}
              active={activeButton === "month"}
              onClick={() => handlePeriodChange("month")}
            >
              1개월
            </S.NormalButton>
            <S.NormalButton
              variant="contained"
              sx={{ mr: 1 }}
              active={activeButton === "quarter"}
              onClick={() => handlePeriodChange("quarter")}
            >
              3개월
            </S.NormalButton>
          </S.Grid>
        </S.Grid>
        <S.Grid
          container
          spacing={1}
          alignItems="center"
          style={{ padding: "0 20px", margin: "0" }}
        >
          <S.InputLabel>회원타입</S.InputLabel>
          <S.Grid item xs={3}>
            <S.RadioGroup
              row
              onChange={(e) =>
                updateOptions("userType", Number(e.target.value))
              }
              defaultValue=""
            >
              <FormControlLabel
                value=""
                control={<Radio />}
                label="전체"
                defaultChecked
              />
              <FormControlLabel value="2" control={<Radio />} label="일반" />
            </S.RadioGroup>
          </S.Grid>
          <S.InputLabel>가입상태</S.InputLabel>
          <S.Grid item xs={3}>
            <S.Select
              sx={{ width: "150px", height: "2.5em" }}
              onChange={(e) =>
                updateOptions(
                  "status",
                  (e.target as HTMLSelectElement).value === "1"
                    ? ""
                    : (e.target as HTMLSelectElement).value
                )
              }
              defaultValue={"1"}
            >
              <S.MenuItem value="1">전체</S.MenuItem>
              <S.MenuItem value="P">신청중</S.MenuItem>
              <S.MenuItem value="Y">승인완료</S.MenuItem>
              <S.MenuItem value="N">승인거절</S.MenuItem>
            </S.Select>
          </S.Grid>
        </S.Grid>
        <S.Grid
          container
          spacing={1}
          alignItems="center"
          style={{ padding: "0 20px", margin: "0" }}
        >
          <S.InputLabel>검색</S.InputLabel>
          <S.Grid item xs={10}>
            <S.Select
              defaultValue="email"
              sx={{ width: "10em", height: "2.5em", mr: 1 }}
              onChange={(e) =>
                updateOptions(
                  "searchType",
                  (e.target as HTMLSelectElement).value
                )
              }
            >
              <S.MenuItem value="email">이메일</S.MenuItem>
              <S.MenuItem value="name">이름</S.MenuItem>
            </S.Select>
            <S.Input
              type="text"
              onChange={(e) => updateOptions("search", e.target.value)}
            />
          </S.Grid>
        </S.Grid>
        <S.Grid container spacing={1} alignItems="center">
          <S.Grid
            item
            xs={12}
            style={{
              alignItems: "center",
              justifyContent: "center",
              margin: "0",
            }}
          >
            <S.SearchButton
              variant="contained"
              color="primary"
              style={{ width: "130px" }}
              onClick={() => search()}
              ref={searchTrigger}
            >
              검색
            </S.SearchButton>
          </S.Grid>
        </S.Grid>
      </S.Paper>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
          marginTop: "30px",
        }}
      >
        <div>총 {isSuccess && memberListData.data.totalCnt}건</div>
        <div
          style={{
            height: "2.8em",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <S.RowSelect
            defaultValue={15}
            sx={{ mr: 1 }}
            onChange={(e) => changeLimit(e)}
          >
            <S.MenuItem value={15}>15개씩 보기</S.MenuItem>
            <S.MenuItem value={50}>50개씩 보기</S.MenuItem>
            <S.MenuItem value={100}>100개씩 보기</S.MenuItem>
          </S.RowSelect>
          <S.NormalButton variant="contained" onClick={download} active={false}>
            Excel 다운로드
          </S.NormalButton>
        </div>
      </div>
      <S.Paper elevation={0}>
        <S.TableContainer>
          <S.Table>
            <S.TableHead>
              <S.TableHeadRow>
                <S.TableHeadCell align="center">
                  <S.TableSortLabel
                    active={orderBy === "no"}
                    hideSortIcon={false}
                    direction={orderDirection}
                    onClick={() => handleSortRequest("no", orderDirection)}
                  >
                    No
                  </S.TableSortLabel>
                </S.TableHeadCell>
                <S.TableHeadCell align="center">
                  <S.TableSortLabel
                    active={orderBy === "email"}
                    hideSortIcon={false}
                    direction={
                      orderBy === "email"
                        ? ["asc", "desc"].includes(orderDirection)
                          ? orderDirection
                          : "asc"
                        : "asc"
                    }
                    onClick={() => handleSortRequest("email", orderDirection)}
                  >
                    이메일주소
                  </S.TableSortLabel>
                </S.TableHeadCell>
                <S.TableHeadCell align="center">
                  <S.TableSortLabel
                    active={orderBy === "name"}
                    hideSortIcon={false}
                    direction={
                      orderBy === "name"
                        ? ["asc", "desc"].includes(orderDirection)
                          ? orderDirection
                          : "asc"
                        : "asc"
                    }
                    onClick={() => handleSortRequest("name", orderDirection)}
                  >
                    이름
                  </S.TableSortLabel>
                </S.TableHeadCell>
                <S.TableHeadCell align="center">회원타입</S.TableHeadCell>
                <S.TableHeadCell align="center">가입상태</S.TableHeadCell>
                <S.TableHeadCell align="center">
                  <S.TableSortLabel
                    active={orderBy === "createDate"}
                    hideSortIcon={false}
                    direction={
                      orderBy === "createDate"
                        ? ["asc", "desc"].includes(orderDirection)
                          ? orderDirection
                          : "asc"
                        : "asc"
                    }
                    onClick={() => handleSortRequest("createDate", orderDirection)}
                  >
                    가입신청일시
                  </S.TableSortLabel>
                </S.TableHeadCell>
                <S.TableHeadCell align="center">
                  <S.TableSortLabel
                    active={orderBy === "permissionDate"}
                    hideSortIcon={false}
                    direction={
                      orderBy === "permissionDate"
                        ? ["asc", "desc"].includes(orderDirection)
                          ? orderDirection
                          : "asc"
                        : "asc"
                    }
                    onClick={() => handleSortRequest("permissionDate", orderDirection)}
                  >
                    가입승인일시
                  </S.TableSortLabel>
                </S.TableHeadCell>
              </S.TableHeadRow>
            </S.TableHead>
            <S.TableBody>
              {isSuccess && memberData.length > 0 ? (
                memberData.map((row: any, index: any) => (
                  <S.TableRow
                    key={index}
                    onClick={() => handleRowClick(row.userPK, row.email)}
                  >
                    <S.TableCell align="center">{row.no}</S.TableCell>
                    <S.TableCell>{row.email}</S.TableCell>
                    <S.TableCell align="center">{row.name}</S.TableCell>
                    <S.TableCell align="center">{row.typeName}</S.TableCell>
                    <S.TableCell align="center">{row.status}</S.TableCell>
                    <S.TableCell align="center">{row.createDate}</S.TableCell>
                    <S.TableCell align="center">
                      {row.permissionDate}
                    </S.TableCell>
                  </S.TableRow>
                ))
              ) : (
                <S.TableRow>
                  <S.TableCell
                    align="center"
                    colSpan={7}
                    style={{ height: "110px" }}
                  >
                    검색 결과가 없습니다
                  </S.TableCell>
                </S.TableRow>
              )}
            </S.TableBody>
          </S.Table>
        </S.TableContainer>
      </S.Paper>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "30px",
          marginBottom: "30px",
        }}
      >
        <Pagination
          count={
            isSuccess && memberListData.data.totalPage
              ? memberListData.data.totalPage
              : 1
          }
          color="standard"
          onChange={handlePageChange}
          page={page}
        />
      </Box>
    </S.Container>
  );
}
