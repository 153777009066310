import { useMutation } from "@tanstack/react-query";
import { request } from "./axios-utils";
import { OnSuccessReport, OnErrorReport, OnSuccessReportList, OnErrorReportList, SearchReportParameters, ResReportedContents, ResReportReple, ResReportedContentDetails, ResReportedRepleDetails } from "../../interfaces/reportInterface";
import { UpdateReportParameters } from "../../interfaces/reportInterface";
import { searchParams } from "../../interfaces";
import { successResponseUpdate } from "../../interfaces/axios";

const reportedRepleList = (options: Object) => {
    return request({
        url: "/report/reple/list",
        method: "POST",
        data: { ...options }
    })
}


export const useReportRepleList = (
    onSuccess: OnSuccessReportList<ResReportReple>,
    onError: OnErrorReportList
) => {
    return useMutation({
        mutationFn: reportedRepleList,
        onSuccess,
        onError
    })
}


const reportedRepleDetail = (idx: string) => {
    return request({
        url: `/report/reple/detail/${idx}`,
        method: "GET",
    })
}


export const useReportedRepleDetail = (onSuccess: OnSuccessReport<ResReportedRepleDetails>, onError: OnErrorReport) => {
    return useMutation({
        mutationFn: reportedRepleDetail,
        onSuccess,
        onError
    })
}


const reportedContentList = (data: SearchReportParameters) => {
    return request({
        url: "/report/content/list",
        method: "POST",
        data: data
    })
}


export const useReportedContentList = (onSuccess: OnSuccessReportList<ResReportedContents>, onError: OnErrorReportList) => {
    return useMutation({
        mutationFn: reportedContentList,
        onSuccess,
        onError
    })
}


const reportedContentDetail = (idx: string) => {
    return request({
        url: `/report/content/detail/${idx}`,
        method: "GET",
    })
}


export const useReportedContentDetail = (onSuccess: OnSuccessReport<ResReportedContentDetails>, onError: OnErrorReport) => {
    return useMutation({
        mutationFn: reportedContentDetail,
        onSuccess,
        onError
    })
}


const updateReport = (data: UpdateReportParameters) => {
    return request({
        url: `/report/update`,
        method: "PUT",
        data: data
    })
}

export const useUpdateReport = (onSuccess: OnSuccessReport<successResponseUpdate>, onError: OnErrorReport) => {
    return useMutation({
        mutationFn: updateReport,
        onSuccess,
        onError
    })
}