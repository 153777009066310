import { useMutation } from "@tanstack/react-query";
import { request } from "./axios-utils";
import { AxiosResponse, AxiosError } from "axios";

interface LoginResponse {
  status: boolean;
  message: string;
  data: {
    token: string;
  };
}

interface LogoutResponse {
  status: boolean;
  message: string;
}

export interface ErrorStructure {
  message: string;
  code: number;
}

const login = (credentials: { id: string; pw: string }) => {
  console.log("credentials", credentials);
  return request({
    url: "/auth/login",
    method: "POST",
    data: credentials,
  });
};

export const useLogin = (
  onSuccess: (data: LoginResponse[], context: unknown) => void,
  onError: (error: AxiosError<ErrorStructure>) => void
) => {
  return useMutation({
    mutationFn: login,
    // onMutate: async (variables) => {
    // mutation이 실행되기 전에 호출됨
    // context로 사용할 값을 반환함
    // const context = { previousToken: localStorage.getItem('token') };
    // Optimistic update: 로그인 전에 UI를 업데이트할 수 있음
    // 예: 로딩 상태를 표시하거나 사용자에게 메시지 표시
    // return context;
    // },
    onSuccess,
    onError,
  });
};

const logout = () => {
  return request({
    url: "/auth/logout",
    method: "GET",
  });
};

export const useLogout = (
  onSuccess: (data: LogoutResponse, context: unknown) => void,
  onError: (error: AxiosError<ErrorStructure>) => void
) => {
  return useMutation({
    mutationFn: logout,
    onSuccess,
    onError,
  });
};
