import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Box, InputLabel } from "@mui/material";
import * as S from "../styles/AIDetailStyles";
import {
  usePredictionDetail,
  usePredictionUpdate,
} from "../../../utils/apiHooks/useDiseaseData";
import { useSearchOptions } from "../../../utils/customHooks/useInputData";
import { usePredictions } from "../../../context/PredictionContext";
import { Popup } from "../../../components/popup/Popup";

interface Prediction {
  idx?: number;
  email?: string;
  name?: string;
  complete?: string;
  testResult?: string;
  type?: string;
  image?: string;
  content?: string;
  diseaseCode?: string;
  diseaseName?: string;
  resultImgPath?: string;
  resultImgName?: string;
  opinion?: string;
  prescription?: string;
  createDate?: string;
  completeDate?: string;
}

export default function AIDetail() {
  const { idx } = useParams<{ idx: string }>();
  const [predictionDetail, setPredictionDetail] = useState<Prediction | null>(
    null
  );
  const [completeStatus, setCompleteStatus] = useState("N");
  const [predictionResult, setPredictionResult] = useState("");
  const [uploadImage, setUploadImage] = useState("");
  const [resultImage, setResultImage] = useState("");
  const [uploadImageMime, setUploadImageMime] = useState("");
  const [popupImage, setPopupImage] = useState("");
  const { options, updateOptions } = useSearchOptions({
    // idx: "", 고정값
    complete: "",
    testResult: "",
    opinion: "",
    prescription: "",
  });
  const { setPredictions } = usePredictions();
  const [isSaveBtnDisable, setIsSaveBtnDisable] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [popupOptions, setPopupOptions] = useState({
    content: "",
    type: "confirm",
    callback: () => {
      console.log("popup closed!");
    },
    isOpenPopup,
    setIsOpenPopup,
    imageUrl: "",
  });

  const changeBlob = (base64: string, type: string) => {
    const byteString = atob(base64.split(",")[1]);
    const mimeString = base64.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeString });
    const url = URL.createObjectURL(blob);

    if (type === "upload") {
      setUploadImage(url.toString());
    } else {
      setResultImage(url.toString());
    }

    return () => {
      URL.revokeObjectURL(url);
    };
  };

  // ---------- useMutation : 검사신청 상세 가져오기 ----------
  const onSuccessPredictionDetail = useCallback((data: any) => {
    const { result } = data.data;

    const formattedResults: Prediction = {
      ...result,
      idx: result.idx,
      email: result.email,
      name: result.name,
      complete: result.complete,
      testResult: result.testResult,
      type: result.type,
      image: result.image,
      title: result.title,
      content: result.content,
      diseaseCode: result.diseaseCode,
      diseaseName: result.diseaseName,
      resultImgPath: result.resultImgPath,
      resultImgName: result.resultImgName,
      opinion: result.opinion,
      prescription: result.prescription,
      createDate: result.createDate,
      completeDate: result.completeDate,
    };

    setPredictionDetail(formattedResults);

    if (formattedResults?.complete === "Y") {
      updateOptions("complete", "Y");
    } else if (formattedResults?.complete === "N") {
      updateOptions("complete", "N");
    }

    if (formattedResults?.testResult === "normal") {
      updateOptions("testResult", "normal");
    } else if (formattedResults?.testResult === "abnormal") {
      updateOptions("testResult", "abnormal");
    } else {
      updateOptions("testResult", "select");
    }

    formattedResults?.complete === "Y" && setIsSaveBtnDisable(true);
  }, []);

  const { mutate: getPredictionDetail } = usePredictionDetail(
    onSuccessPredictionDetail
  );

  // ---------- useMutation : 검사신청 업데이트 -------------
  const onSuccessPredictionUpdate = useCallback((data: any) => {
    console.log(data);
    setPopupOptions((prevOptions) => ({
      ...prevOptions,
      content: "수정이 완료되었습니다.",
      type: "info",
      callback: () => navigate("/ai-prediction"),
    }));
    setIsOpenPopup(true);
  }, []);

  const onErrorPredictionUpdate = useCallback((error: any) => {
    console.log(error);
  }, []);

  const { mutate: updatePrediction } = usePredictionUpdate(
    onSuccessPredictionUpdate,
    onErrorPredictionUpdate
  );

  // -------------------------------------------------------

  const handleOpinionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPredictionDetail((prevDetail) => ({
      ...prevDetail,
      opinion: e.target.value,
    }));
    updateOptions("opinion", e.target.value);
  };

  const handlePrescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPredictionDetail((prevDetail) => ({
      ...prevDetail,
      prescription: e.target.value,
    }));
    updateOptions("prescription", e.target.value);
  };

  const save = () => {
    const ops = { ...options, idx: idx };

    console.log(options.testResult);
    if (options.testResult === "select") {
      setPopupOptions({
        ...popupOptions,
        content: "검사결과를 선택해주세요.",
        type: "info",
        imageUrl: "",
        callback: () => console.log("검사결과를 선택해주세요."),
      });
      setIsOpenPopup(true);
    } else if (options.complete === "Y" && !options.opinion) {
      setPopupOptions({
        ...popupOptions,
        content: "소견을 입력해야 처리완료가 가능합니다.",
        type: "info",
        imageUrl: "",
        callback: () => console.log("소견을 입력해주세요."),
      });
      setIsOpenPopup(true);
    } else {
      setPopupOptions({
        ...popupOptions,
        content: "해당 내용으로 수정하시겠습니까?",
        type: "confirm",
        imageUrl: "",
        callback: () => updatePrediction(ops),
      });
      setIsOpenPopup(true);
    }
  };

  const handleBackClick = () => {
    navigate("/ai-prediction");
  };

  const handleImageClick = (type: string) => () => {
    const imageUrl =
      type === "upload" ? uploadImage : type === "result" ? resultImage : "";
    setPopupOptions({
      ...popupOptions,
      content: "",
      type: "image",
      imageUrl: imageUrl,
      callback: () => setIsOpenPopup(false),
    });
    setIsOpenPopup(true);
  };

  // ------------------ useEffect ------------------
  useEffect(() => {
    if (idx) {
      getPredictionDetail(Number(idx));
    }
  }, [idx, getPredictionDetail]);

  useEffect(() => {
    if (predictionDetail?.image) {
      changeBlob(predictionDetail.image, "upload");
    }

    if (predictionDetail?.resultImgPath) {
      changeBlob(predictionDetail.resultImgPath, "result");
    }
  }, [predictionDetail]);

  // useEffect(() => {
  //   setIsSaveBtnDisable(predictionDetail.complete === "Y");
  // }, [options.complete]);

  return (
    <S.Container>
      <S.Typography>콘텐츠관리 {">"} AI검사관리</S.Typography>
      <Box>
        <InputLabel
          style={{
            marginTop: "20px",
            marginBottom: "10px",
            width: "150px",
            color: "#000000",
            fontWeight: "700",
          }}
        >
          • 상세
        </InputLabel>
      </Box>
      <S.TableContainer>
        <S.Table aria-label="simple table">
          <S.TableBody>
            <S.TableRow>
              <S.TableCell>문의내용</S.TableCell>
              <S.TableCell colSpan={3}>
                {predictionDetail?.content === null
                  ? "-"
                  : predictionDetail?.content}
              </S.TableCell>
            </S.TableRow>
            <S.TableRow>
              <S.TableCell style={{ width: "15%" }}>이메일주소</S.TableCell>
              <S.TableCell style={{ width: "35%" }}>
                {predictionDetail?.email}
              </S.TableCell>
              <S.TableCell>이름</S.TableCell>
              <S.TableCell>{predictionDetail?.name}</S.TableCell>
            </S.TableRow>
            <S.TableRow>
              <S.TableCell>처리결과</S.TableCell>
              <S.TableCell>
                <S.Select
                  value={options.complete}
                  style={{ width: "180px" }}
                  onChange={(e) =>
                    updateOptions(
                      "complete",
                      (e.target as HTMLSelectElement).value
                    )
                  }
                >
                  <S.MenuItem value="N">처리중</S.MenuItem>
                  <S.MenuItem value="Y">처리완료</S.MenuItem>
                </S.Select>
              </S.TableCell>
              <S.TableCell>처리일시</S.TableCell>
              <S.TableCell>{predictionDetail?.completeDate}</S.TableCell>
            </S.TableRow>
            <S.TableRow>
              <S.TableCell>검사결과</S.TableCell>
              <S.TableCell>
                <S.Select
                  value={options.testResult}
                  style={{ width: "180px" }}
                  onChange={(e) =>
                    updateOptions(
                      "testResult",
                      (e.target as HTMLSelectElement).value
                    )
                  }
                >
                  <S.MenuItem value="select">선택</S.MenuItem>
                  <S.MenuItem value="normal">정상</S.MenuItem>
                  <S.MenuItem value="abnormal">이상</S.MenuItem>
                </S.Select>
              </S.TableCell>
              <S.TableCell>등록일시</S.TableCell>
              <S.TableCell>{predictionDetail?.createDate}</S.TableCell>
            </S.TableRow>
            <S.TableRow>
              <S.TableCell>첨부파일</S.TableCell>
              <S.TableCell>
                <S.Image src={uploadImage} alt="" />
                <S.FileName
                  onClick={uploadImage ? handleImageClick("upload") : undefined}
                >
                  업로드파일명.jpg
                </S.FileName>
              </S.TableCell>
              <S.TableCell>분석결과</S.TableCell>
              <S.TableCell>
                <S.Image src={resultImage} alt="" />
                <S.FileName
                  onClick={resultImage ? handleImageClick("result") : undefined}
                >
                  {predictionDetail?.resultImgName}
                </S.FileName>
                <div style={{ marginTop: "10px" }}>
                  질병코드:{" "}
                  <span>
                    {predictionDetail?.diseaseCode}
                    {predictionDetail?.diseaseName
                      ? `(${predictionDetail.diseaseName})`
                      : ""}
                  </span>
                </div>
              </S.TableCell>
            </S.TableRow>
            <S.TableRow>
              <S.TableCell>질병소견</S.TableCell>
              <S.TableCell>
                <S.TextField
                  multiline
                  rows={4}
                  placeholder="질병 소견 입력"
                  value={
                    predictionDetail?.opinion ? predictionDetail?.opinion : ""
                  }
                  variant="outlined"
                  onChange={handleOpinionChange}
                ></S.TextField>
              </S.TableCell>
              <S.TableCell>처방</S.TableCell>
              <S.TableCell>
                <S.TextField
                  multiline
                  rows={4}
                  placeholder="처방전 입력"
                  value={
                    predictionDetail?.prescription
                      ? predictionDetail?.prescription
                      : ""
                  }
                  variant="outlined"
                  onChange={handlePrescriptionChange}
                ></S.TextField>
              </S.TableCell>
            </S.TableRow>
          </S.TableBody>
        </S.Table>
      </S.TableContainer>
      <S.BtnBox>
        <S.NormalButton onClick={handleBackClick}>목록</S.NormalButton>
        <S.SaveButton onClick={() => save()} disabled={isSaveBtnDisable}>
          수정저장
        </S.SaveButton>
      </S.BtnBox>
      <Popup
        {...popupOptions}
        isOpenPopup={isOpenPopup}
        setIsOpenPopup={setIsOpenPopup}
      />
    </S.Container>
  );
}
