import React, { useState, ChangeEvent, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Box, FormControlLabel, Radio, InputLabel } from "@mui/material";
import * as S from "../styles/MemberDetailStyles";
import { useInputs } from "../../../utils/customHooks/useInputData";
import { Popup } from "../../../components/popup/Popup";
import { useMemberDetail, useMemberUpdate } from "../../../utils/apiHooks/useMemberData";
import CircularIndeterminate from "../../../components/common/ProgressBar";
import { useCryptoData } from "../../../utils/common/crypto";
import { usePopupOptions } from "../../../utils/customHooks/usePopupOptions";

interface memberDetailDataInterface {
  email: string;
  name: string;
  birth: string;
  status: string;
  createDate: string;
  marketing: string;
  marketingDate: string;
  permissionDate: string;
  adminMemo: string;
  adminMemoDate: string;
  adminMemoId: string;
}

export default function MemberDetail() {
  const userPkData = useLocation().pathname.split("/")[2];
  const [userStatus, useUserStatus, setUserStatus] = useInputs("");
  const [adminMemo, useAdminMemo, setAdminMemo] = useInputs("");
  const [marketing, useMarketing, setMarketing] = useInputs("");
  const { handleDecrypt } = useCryptoData();
  const navigate = useNavigate();
  const [memberDetailData, setMemberDetailData] = useState<memberDetailDataInterface>();
  const { setIsOpenPopup, popupOptions, setPopup } = usePopupOptions();


  const update = () => {
    const data = {
      adminMemo,
      status: userStatus,
      pk: handleDecrypt(userPkData).pk,
      marketing: marketing,
      email: memberDetailData && memberDetailData.email
    };
    updateMemberDetail(data);
  };

  const onSuccessUpdateMemberDetail = (res: any) => {
    if (res.status) {
      setPopup("content", "수정이 완료되었습니다.");
      setPopup("type", "info");
      setPopup("callback", () => navigate("/member"));

      setIsOpenPopup(true);
    } else {
    }
  };

  const { mutate: updateMemberDetail } = useMemberUpdate(onSuccessUpdateMemberDetail);

  const onSuccessGetUserDetail = (res: any) => {
    setMemberDetailData(res.data.result);
  }

  const { mutate: getMemberDetail, isSuccess } = useMemberDetail(onSuccessGetUserDetail);


  useEffect(() => {
    const decryptedData = handleDecrypt(userPkData);
    if (Object.keys(decryptedData).length > 0) getMemberDetail(decryptedData);
  }, []);

  const onClickSave = () => {
    setPopup("content", "수정하시겠습니까?");
    setPopup("type", "confirm");
    setPopup("callback", () => update());

    setIsOpenPopup(true);
  }

  return (
    isSuccess && memberDetailData ? <S.Container>
      <S.Typography>회원관리 {">"} 회원조회/수정</S.Typography>
      <Box>
        <InputLabel
          style={{
            marginTop: "20px",
            marginBottom: "10px",
            width: "150px",
            color: "#000000",
            fontWeight: "700",
          }}
        >
          • 회원계정 정보
        </InputLabel>
      </Box>
      <S.TableContainer>
        <S.Table aria-label="simple table">
          <S.TableBody>
            <S.TableRow>
              <S.TableCell>이메일</S.TableCell>
              <S.TableCell>{memberDetailData.email}</S.TableCell>
              <S.TableCell>회원타입</S.TableCell>
              <S.TableCellWithButton>
                일반
                <S.NormalButton>수정</S.NormalButton>
              </S.TableCellWithButton>
            </S.TableRow>
            <S.TableRow>
              <S.TableCell>이름</S.TableCell>
              <S.TableCell style={{ width: "30%" }}>{memberDetailData.name}</S.TableCell>
              <S.TableCell style={{ width: "20%" }}>생년월일</S.TableCell>
              <S.TableCell style={{ width: "30%" }}>
                <S.Input
                  value={memberDetailData.birth}
                  style={{ width: "180px" }}
                ></S.Input>
              </S.TableCell>
            </S.TableRow>
            <S.TableRow>
              <S.TableCell>가입신청일시</S.TableCell>
              <S.TableCell>{memberDetailData.createDate}</S.TableCell>
              <S.TableCell>가입상태</S.TableCell>
              <S.TableCell>
                <S.Select defaultValue={memberDetailData.status} style={{ width: "180px" }} onChange={useUserStatus}>
                  <S.MenuItem value="P">대기중</S.MenuItem>
                  <S.MenuItem value="Y">승인완료</S.MenuItem>
                  <S.MenuItem value="N">승인거절</S.MenuItem>
                </S.Select>
              </S.TableCell>
            </S.TableRow>
            <S.TableRow>
              <S.TableCell>마케팅 수신동의</S.TableCell>
              <S.TableCell>
                <S.RadioGroup row defaultValue={memberDetailData.marketing} onChange={useMarketing}>
                  <FormControlLabel
                    value="Y"
                    control={<Radio />}
                    label="동의"
                  />
                  <FormControlLabel
                    value="N"
                    control={<Radio />}
                    label="거부"
                  />
                </S.RadioGroup>
              </S.TableCell>
              <S.TableCell>수신동의(거부)일시</S.TableCell>
              <S.TableCell>{memberDetailData.marketingDate}</S.TableCell>
            </S.TableRow>
            <S.TableRow>
              <S.TableCell>비밀번호</S.TableCell>
              <S.TableCellWithButton>
                재설정 메일 발송<S.NormalButton>전송</S.NormalButton>
              </S.TableCellWithButton>
              <S.TableCell>가입승인일시</S.TableCell>
              <S.TableCell>{memberDetailData.permissionDate}</S.TableCell>
            </S.TableRow>
          </S.TableBody>
        </S.Table>
      </S.TableContainer>
      <Box>
        <InputLabel
          style={{
            marginTop: "50px",
            marginBottom: "10px",
            width: "150px",
            color: "#000000",
            fontWeight: "700",
          }}
        >
          • 관리정보
        </InputLabel>
        <S.TableContainer>
          <S.Table aria-label="simple table">
            <S.TableBody>
              <S.TableRow>
                <S.TableCell>관리자 메모</S.TableCell>
                <S.TableCell colSpan={3} style={{ width: "80%" }}>
                  <S.TextField
                    multiline
                    rows={4}
                    onChange={useAdminMemo}
                    error={adminMemo.length > 200} // 200자는 임시로 설정
                    placeholder="Enter your text here"
                    variant="outlined"
                    defaultValue={memberDetailData.adminMemo}
                  ></S.TextField>
                </S.TableCell>
              </S.TableRow>
              <S.TableRow>
                <S.TableCell style={{ width: "20%" }}>수정일시</S.TableCell>
                <S.TableCell style={{ width: "30%" }}>{memberDetailData.adminMemoDate}</S.TableCell>
                <S.TableCell style={{ width: "20%" }}>수정자</S.TableCell>
                <S.TableCell style={{ width: "30%" }}>{memberDetailData.adminMemoId}</S.TableCell>
              </S.TableRow>
            </S.TableBody>
          </S.Table>
          <S.BtnBox>
            <S.NormalButton onClick={() => navigate("/member")}>목록</S.NormalButton>
            <S.SaveButton onClick={() => onClickSave()}>수정저장</S.SaveButton>
          </S.BtnBox>
        </S.TableContainer>
      </Box>
      <Popup {...popupOptions} />
    </S.Container > : <CircularIndeterminate />
  );
}
