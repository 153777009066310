import React from "react";
import { request } from './axios-utils';
import { useMutation, useQuery } from "@tanstack/react-query";

// 공지 목록
const getNoticeList = (boardPk: Object) => {
    return request({
        url: "/notice/list",
        method: "POST",
        data: { ...boardPk }
    })
}

export const useNoticeList = (
    onSuccess: any
) => {
    return useMutation({
        mutationFn: getNoticeList,
        onSuccess
    })
}


// 공지 등록
const addNotice = async (noticeData: any): Promise<any> => {
    // console.log(noticeData);
    return await request({
        url: "/notice/write",
        method: "POST",
        data: { ...noticeData }
    })
}

export const useAddNotice = (onSuccess: any) => {
    return useMutation({
        mutationFn: addNotice,
        onSuccess
    })
}



// 공지 상세
const getNoticeDetail = async (noticePk: any): Promise<any> => {
    return await request({
        url: `/notice/detail/${noticePk}`,
        method: "GET"
    })
}

export const useNoticeDetail = (noticePk: any) => {
    return useQuery({
        queryKey: ['getNoticeDetail'],
        queryFn: () => getNoticeDetail(noticePk),
    })
}


// 공지 수정
const getNoticeUpdate = async (noticeData: any): Promise<any> => {
    console.log(noticeData);
    return await request({
        url: "/notice/update",
        method: "PUT",
        data: { ...noticeData }
    })
}

export const useUpdateNotice = (onSuccess: any) => {
    return useMutation({
        mutationFn: getNoticeUpdate,
        onSuccess
    })
}

