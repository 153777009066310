import React, { useState, useCallback, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Box, InputLabel, SelectChangeEvent } from "@mui/material";
import * as S from "../styles/PostReportDetailStyles";
import {
  useQuestionDetail,
  useQuestionUpdate,
} from "../../../utils/apiHooks/useQuestionData";
import { useSearchOptions } from "../../../utils/customHooks/useInputData";
import { Popup } from "../../../components/popup/Popup";
import { useReportedContentDetail, useUpdateReport } from "../../../utils/apiHooks/useReportData";
import { OnSuccessReport, ResReportedContentDetails, UpdateReportParameters } from "../../../interfaces/reportInterface";
import { successResponseData, successResponseUpdate } from "../../../interfaces/axios";

export default function PostReportDetail() {
  const { idx } = useParams<{ idx: string }>();
  const [postReportDetail, setPostReportDetail] = useState<ResReportedContentDetails | null>(null);
  const [isSaveBtnRemove, setIsSaveBtnRemove] = useState(false);
  const { options, updateOptions } = useSearchOptions({
    idx: idx as string,
    status: "",
    adminMemo: "",
  });
  const navigate = useNavigate();
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [popupOptions, setPopupOptions] = useState({
    content: "",
    type: "confirm",
    callback: () => {
      console.log("popup closed!");
    },
    isOpenPopup,
    setIsOpenPopup,
  });

  // ----------useMutation : 게시물 신고 상세----------//
  const onSuccessPostReportDetail = useCallback((data: successResponseData<ResReportedContentDetails>) => {
    const { result } = data.data;

    setPostReportDetail(result);

    // updateOptions
    if (result && options) {
      Object.keys(options).forEach((key): void => {
        const k = key as keyof typeof options;
        updateOptions(k, result[k]);
      })
    };


  }, []);

  const onErrorPostReportDetail = (error: any) => {
    console.log("error: ", error);
  };

  const { mutate: getPostReportDetail } = useReportedContentDetail(
    onSuccessPostReportDetail,
    onErrorPostReportDetail
  );

  //----------useMutation : 게시글 신고 수정-------------//
  const onSuccessReportedContentUpdate = (data: successResponseData<successResponseUpdate>) => {
    console.log(data);
    setPopupOptions((prevOptions) => ({
      ...prevOptions,
      content: "수정이 완료되었습니다.",
      type: "info",
      callback: handleBackClick,
    }));
    setIsOpenPopup(true);
  };

  const onErrorReportedContentUpdate = useCallback((error: any) => {
    console.log(error);
  }, []);

  const { mutate: updatePostReport } = useUpdateReport(
    onSuccessReportedContentUpdate,
    onErrorReportedContentUpdate
  );

  //-------------------functions-------------------//

  const updateReportDetail = (key: keyof typeof options, value: string) => {
    if (postReportDetail) {
      const data: ResReportedContentDetails = { ...postReportDetail, [key]: value };
      setPostReportDetail(data);
      updateOptions(key, value);
    }
  };

  const handleBackClick = () => {
    navigate("/post-report");
  };

  const save = () => {
    const ops = { ...options };

    setPopupOptions({
      ...popupOptions,
      content: "해당 내용으로 수정하시겠습니까?",
      type: "confirm",
      callback: () => updatePostReport(ops),
    });
    setIsOpenPopup(true);
  };


  //------------------ useEffect ------------------//
  useEffect(() => {
    idx && getPostReportDetail(idx);
  }, [idx, getPostReportDetail]);

  return (
    <S.Container>
      <S.Typography>문의관리 {">"} 게시물신고</S.Typography>
      <Box>
        <InputLabel
          style={{
            marginTop: "20px",
            marginBottom: "10px",
            color: "#000000",
            fontWeight: "700",
          }}
        >
          • 피신고 회원계정 정보
        </InputLabel>
      </Box>
      <S.TableContainer>
        <S.Table aria-label="simple table">
          <S.TableBody>
            <S.TableRow>
              <S.TableCell style={{ width: "15%" }}>이메일</S.TableCell>
              <S.TableCell style={{ width: "35%", padding: "0 0 0 10px" }}>{postReportDetail?.email}</S.TableCell>
              <S.TableCell style={{ width: "15%" }}>회원정보</S.TableCell>
              <S.TableCell style={{ width: "35%" }}>{postReportDetail?.userCode}</S.TableCell>
            </S.TableRow>
            <S.TableRow>
              <S.TableCell>이름</S.TableCell>
              <S.TableCell colSpan={3} style={{ width: "80%" }}>{postReportDetail?.name}</S.TableCell>
            </S.TableRow>
          </S.TableBody>
        </S.Table>
      </S.TableContainer>
      <Box>
        <InputLabel
          style={{
            marginTop: "60px",
            marginBottom: "10px",
            color: "#000000",
            fontWeight: "700",
          }}
        >
          • 게시물 신고 내역
        </InputLabel>
      </Box>
      <S.TableContainer>
        <S.Table aria-label="simple table">
          <S.TableBody>
            <S.TableRow>
              <S.TableCell>제목</S.TableCell>
              <S.TableCell colSpan={3} style={{ width: "80%" }}>{postReportDetail?.title}</S.TableCell>
            </S.TableRow>
            <S.TableRow>
              <S.TableCell>게시판 분류</S.TableCell>
              <S.TableCell colSpan={3} style={{ width: "80%" }}>{postReportDetail?.boardType}</S.TableCell>
            </S.TableRow>
            <S.TableRow>
              <S.TableCell style={{ width: "15%" }}>신고건수</S.TableCell>
              <S.TableCell style={{ width: "35%", padding: "0 0 0 10px" }}>{postReportDetail?.cIdxCount}</S.TableCell>
              <S.TableCell style={{ width: "15%" }}>최초 신고일시</S.TableCell>
              <S.TableCell style={{ width: "35%" }}>{postReportDetail?.earliestCreateDate}</S.TableCell>
            </S.TableRow>
          </S.TableBody>
        </S.Table>
      </S.TableContainer>
      <Box>
        <InputLabel
          style={{
            marginTop: "60px",
            marginBottom: "10px",
            color: "#000000",
            fontWeight: "700",
          }}
        >
          • 관리 정보
        </InputLabel>
      </Box>
      <S.TableContainer>
        <S.Table aria-label="simple table">
          <S.TableBody>
            <S.TableRow>
              <S.TableCell>신고상태</S.TableCell>
              <S.TableCell colSpan={3} style={{ width: "80%" }}>
                <S.Select style={{ width: "180px" }} value={postReportDetail ? postReportDetail.status : ""} onChange={(e) => updateReportDetail("status", (e.target as HTMLSelectElement).value)}>
                  <S.MenuItem value="pending">접수</S.MenuItem>
                  <S.MenuItem value="Y">처리완료</S.MenuItem>
                  <S.MenuItem value="N">비처리</S.MenuItem>
                </S.Select>
              </S.TableCell>
            </S.TableRow>
            <S.TableRow>
              <S.TableCell>관리자 메모</S.TableCell>
              <S.TableCell colSpan={3} style={{ width: "80%" }}>
                <S.TextField
                  multiline
                  rows={4}
                  variant="outlined"
                  defaultValue={postReportDetail?.adminMemo}
                  onChange={(e) => updateReportDetail("adminMemo", (e.target as HTMLInputElement).value)}
                ></S.TextField>
              </S.TableCell>
            </S.TableRow>
            <S.TableRow>
              <S.TableCell style={{ width: "15%" }}>처리일시</S.TableCell>
              <S.TableCell style={{ width: "35%", padding: "0 0 0 10px" }}>{postReportDetail?.updateDate}</S.TableCell>
              <S.TableCell style={{ width: "15%" }}>처리자</S.TableCell>
              <S.TableCell style={{ width: "35%" }}>{postReportDetail?.admin}</S.TableCell>
            </S.TableRow>
          </S.TableBody>
        </S.Table>
      </S.TableContainer>
      <S.BtnBox>
        <S.NormalButton onClick={handleBackClick}>목록</S.NormalButton>
        <S.SaveButton
          onClick={() => save()}
          style={{ display: isSaveBtnRemove ? "none" : "block" }}
        >
          수정저장
        </S.SaveButton>
      </S.BtnBox>
      <Popup
        {...popupOptions}
        isOpenPopup={isOpenPopup}
        setIsOpenPopup={setIsOpenPopup}
      />
    </S.Container>
  );
}
