import React, { useState } from "react";
import { Box, InputLabel, FormControlLabel, Radio } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import * as S from "../styles/AddAdminStyles";
import { Popup } from "../../../components/popup/Popup";
import { useAddAdmin } from "../../../utils/apiHooks/useAdminData";
import { usePopupOptions } from "../../../utils/customHooks/usePopupOptions";
import { useNavigate } from "react-router-dom";

interface FormData {
  id: string;
  name: string;
  pw: string;
  type: string;
  email: string;
  phone: string;
  status: string;
  permissions: object;
}

interface Permissions {
  [key: string]: string;
}

const initialPermissions: Permissions = {
  MN0001: "N",
  MN0002: "N",
  MN0003: "N",
  MN0004: "N",
  MN0005: "N",
  MN0006: "N",
  MN0007: "N",
  MN0008: "N",
  MN0009: "N",
  MN0010: "N",
  MN0011: "N",
  MN0012: "N",
  MN0013: "N",
};

export default function AddAdmin() {
  const navigate = useNavigate();
  const { setIsOpenPopup, popupOptions, setPopup } = usePopupOptions();
  const [formData, setFormData] = useState<FormData>({
    id: "",
    name: "",
    pw: "",
    type: "select", // 초기값 설정
    email: "",
    phone: "",
    status: "비활성화", // 초기 상태 설정
    permissions: {}, // 초기 상태 설정
  });
  const [permissions, setPermissions] =
    useState<Permissions>(initialPermissions);

  const handleAccountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    console.log(name, value);

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAccountSelectChange = (
    event: SelectChangeEvent<string>,
    child?: React.ReactNode
  ) => {
    const name = event.target.name as keyof FormData;
    const value = event.target.value;

    console.log(name, value);

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePermissionChange = (
    event: SelectChangeEvent<string>,
    permissionKey: keyof Permissions
  ) => {
    const value = event.target.value;
    console.log(`Key: ${permissionKey}, Value: ${value}`);

    setPermissions((prev) => ({
      ...prev,
      [permissionKey]: value,
    }));
  };

  const validateForm = (): boolean => {
    if (
      !formData.id ||
      !formData.name ||
      !formData.pw ||
      formData.type === "select" ||
      !formData.email ||
      !formData.phone
    ) {
      setPopup("content", "모든 필드를 채워주세요.");

      setIsOpenPopup(true);
      return false;
    }
    // 추가적인 유효성 검사 로직
    return true;
  };


  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      const newObj = { ...permissions };

      for (const key in newObj) {
        if (newObj[key] === "N") {
          delete newObj[key]
        }
      };

      formData.permissions = { ...newObj };

      setPopup("content", "수정이 완료되었습니다.");
      setPopup("type", "confirm");
      setPopup("callback", () => addAdmin(formData));

      setIsOpenPopup(true);
    }
  };


  const onSuccessAddAdmin = (res: any) => {
    if (res.status) {
      setPopup("content", "운영자 등록이 완료되었습니다.");
      setPopup("type", "info");
      setPopup("callback", () => navigate("/admin"));

      setIsOpenPopup(true);
    } else {
      setPopup("content", "오류가 발생했습니다. 다시 시도해주세요.");
      setPopup("type", "info");
      setPopup("callback", () => null);

      setIsOpenPopup(true);
    }
  }

  const { mutate: addAdmin } = useAddAdmin(onSuccessAddAdmin)

  return (
    <S.Container>
      <S.Typography>운영자관리 {">"} 운영자조회/수정</S.Typography>
      <Box>
        <InputLabel
          style={{
            marginTop: "20px",
            marginBottom: "10px",
            width: "150px",
            color: "#000000",
            fontWeight: "700",
          }}
        >
          • 운영자계정 정보
        </InputLabel>
      </Box>
      <S.TableContainer>
        <S.Table aria-label="simple table">
          <S.TableBody>
            <S.TableRow>
              <S.TableCell style={{ width: "15%" }}>아이디</S.TableCell>
              <S.TableCell>
                <S.Input
                  name="id"
                  value={formData.id}
                  onChange={handleAccountChange}
                />
              </S.TableCell>
              <S.TableCell style={{ width: "15%" }}>이름</S.TableCell>
              <S.TableCell>
                <S.Input
                  name="name"
                  value={formData.name}
                  onChange={handleAccountChange}
                />
              </S.TableCell>
            </S.TableRow>
            <S.TableRow>
              <S.TableCell>비밀번호</S.TableCell>
              <S.TableCell>
                <S.Input
                  style={{ marginRight: "10px" }}
                  name="pw"
                  value={formData.pw}
                  onChange={handleAccountChange}
                />
                <S.NormalButton>등록</S.NormalButton>
              </S.TableCell>
              <S.TableCell>권한</S.TableCell>
              <S.TableCell>
                <S.Select
                  name="type"
                  value={formData.type}
                  onChange={
                    handleAccountSelectChange as unknown as (
                      event: SelectChangeEvent<unknown>,
                      child: React.ReactNode
                    ) => void
                  }
                >
                  <S.MenuItem value="select">선택</S.MenuItem>
                  <S.MenuItem value="97">일반관리자</S.MenuItem>
                  <S.MenuItem value="99">슈퍼관리자</S.MenuItem>
                  <S.MenuItem value="98">질병관리사</S.MenuItem>
                </S.Select>
              </S.TableCell>
            </S.TableRow>
            <S.TableRow>
              <S.TableCell>이메일</S.TableCell>
              <S.TableCell>
                <S.Input
                  name="email"
                  value={formData.email}
                  onChange={handleAccountChange}
                />
              </S.TableCell>
              <S.TableCell>연락처</S.TableCell>
              <S.TableCell>
                <S.Input
                  name="phone"
                  value={formData.phone}
                  onChange={handleAccountChange}
                />
              </S.TableCell>
            </S.TableRow>
            <S.TableRow>
              <S.TableCell>계정상태</S.TableCell>
              <S.TableCell>
                <S.RadioGroup row onChange={handleAccountChange}>
                  <FormControlLabel
                    name="status"
                    value="비활성화"
                    control={<Radio />}
                    label="비활성화"
                    checked={formData.status === "비활성화"}
                  />
                  <FormControlLabel
                    name="status"
                    value="활성화"
                    control={<Radio />}
                    label="활성화"
                    checked={formData.status === "활성화"}
                  />
                </S.RadioGroup>
              </S.TableCell>
            </S.TableRow>
          </S.TableBody>
        </S.Table>
      </S.TableContainer>
      <Box>
        <InputLabel
          style={{
            marginTop: "20px",
            marginBottom: "10px",
            width: "150px",
            color: "#000000",
            fontWeight: "700",
          }}
        >
          • 운영자 권한 설정
        </InputLabel>
      </Box>
      <S.TableContainer style={{ height: "48vh" }}>
        <S.Table aria-label="simple table">
          <S.TableBody>
            <S.TableRow
              style={{
                backgroundColor: "#f7f7f7",
                border: "1px solid #ebebebeb",
              }}
            >
              <S.AuthTitleCell
                style={{
                  width: "35%",
                }}
                colSpan={2}
              >
                접근메뉴
              </S.AuthTitleCell>
              <S.AuthTitleCell style={{ width: "15%", textAlign: "center" }}>
                권한구분
              </S.AuthTitleCell>
              <S.AuthTitleCell
                style={{ width: "35%", textAlign: "center" }}
                colSpan={2}
              >
                접근메뉴
              </S.AuthTitleCell>
              <S.AuthTitleCell style={{ width: "15%", textAlign: "center" }}>
                권한구분
              </S.AuthTitleCell>
            </S.TableRow>
            <S.TableRow>
              <S.AuthTableCell rowSpan={4} style={{ textAlign: "center" }}>
                콘텐츠관리
              </S.AuthTableCell>
              <S.AuthTableCell>AI검사관리</S.AuthTableCell>
              <S.AuthTableCell>
                <S.Select
                  value={permissions.MN0003}
                  onChange={(e: SelectChangeEvent<unknown>) =>
                    handlePermissionChange(
                      e as SelectChangeEvent<string>,
                      "MN0003"
                    )
                  }
                >
                  <S.MenuItem value="N">없음</S.MenuItem>
                  <S.MenuItem value="R">조회</S.MenuItem>
                  <S.MenuItem value="U">조회+수정</S.MenuItem>
                </S.Select>
              </S.AuthTableCell>
              <S.AuthTableCell rowSpan={4} style={{ textAlign: "center" }}>
                게시판관리
              </S.AuthTableCell>
              <S.AuthTableCell>공지관리</S.AuthTableCell>
              <S.AuthTableCell>
                <S.Select
                  value={permissions.MN0008}
                  onChange={(e: SelectChangeEvent<unknown>) =>
                    handlePermissionChange(
                      e as SelectChangeEvent<string>,
                      "MN0008"
                    )
                  }
                >
                  <S.MenuItem value="N">없음</S.MenuItem>
                  <S.MenuItem value="R">조회</S.MenuItem>
                  <S.MenuItem value="U">조회+수정</S.MenuItem>
                </S.Select>
              </S.AuthTableCell>
            </S.TableRow>
            <S.TableRow>
              <S.AuthTableCell>백과사전관리</S.AuthTableCell>
              <S.AuthTableCell>
                <S.Select
                  value={permissions.MN0004}
                  onChange={(e: SelectChangeEvent<unknown>) =>
                    handlePermissionChange(
                      e as SelectChangeEvent<string>,
                      "MN0004"
                    )
                  }
                >
                  <S.MenuItem value="N">없음</S.MenuItem>
                  <S.MenuItem value="R">조회</S.MenuItem>
                  <S.MenuItem value="U">조회+수정</S.MenuItem>
                </S.Select>
              </S.AuthTableCell>
              <S.AuthTableCell>배너관리</S.AuthTableCell>
              <S.AuthTableCell>
                <S.Select
                  value={permissions.MN0007}
                  onChange={(e: SelectChangeEvent<unknown>) =>
                    handlePermissionChange(
                      e as SelectChangeEvent<string>,
                      "MN0007"
                    )
                  }
                >
                  <S.MenuItem value="N">없음</S.MenuItem>
                  <S.MenuItem value="R">조회</S.MenuItem>
                  <S.MenuItem value="U">조회+수정</S.MenuItem>
                </S.Select>
              </S.AuthTableCell>
            </S.TableRow>
            <S.TableRow>
              <S.AuthTableCell>지도관리</S.AuthTableCell>
              <S.AuthTableCell>
                <S.Select
                  value={permissions.MN0005}
                  onChange={(e: SelectChangeEvent<unknown>) =>
                    handlePermissionChange(
                      e as SelectChangeEvent<string>,
                      "MN0005"
                    )
                  }
                >
                  <S.MenuItem value="N">없음</S.MenuItem>
                  <S.MenuItem value="R">조회</S.MenuItem>
                  <S.MenuItem value="U">조회+수정</S.MenuItem>
                </S.Select>
              </S.AuthTableCell>
              <S.AuthTableCell>FAQ관리</S.AuthTableCell>
              <S.AuthTableCell>
                <S.Select
                  value={permissions.MN0009}
                  onChange={(e: SelectChangeEvent<unknown>) =>
                    handlePermissionChange(
                      e as SelectChangeEvent<string>,
                      "MN0009"
                    )
                  }
                >
                  <S.MenuItem value="N">없음</S.MenuItem>
                  <S.MenuItem value="R">조회</S.MenuItem>
                  <S.MenuItem value="U">조회+수정</S.MenuItem>
                </S.Select>
              </S.AuthTableCell>
            </S.TableRow>
            <S.TableRow>
              <S.AuthTableCell>캘린더관리</S.AuthTableCell>
              <S.AuthTableCell>
                <S.Select
                  value={permissions.MN0006}
                  onChange={(e: SelectChangeEvent<unknown>) =>
                    handlePermissionChange(
                      e as SelectChangeEvent<string>,
                      "MN0006"
                    )
                  }
                >
                  <S.MenuItem value="N">없음</S.MenuItem>
                  <S.MenuItem value="R">조회</S.MenuItem>
                  <S.MenuItem value="U">조회+수정</S.MenuItem>
                </S.Select>
              </S.AuthTableCell>
              <S.AuthTableCell>약관관리</S.AuthTableCell>
              <S.AuthTableCell>
                <S.Select
                  value={permissions.MN0010}
                  onChange={(e: SelectChangeEvent<unknown>) =>
                    handlePermissionChange(
                      e as SelectChangeEvent<string>,
                      "MN0010"
                    )
                  }
                >
                  <S.MenuItem value="N">없음</S.MenuItem>
                  <S.MenuItem value="R">조회</S.MenuItem>
                  <S.MenuItem value="U">조회+수정</S.MenuItem>
                </S.Select>
              </S.AuthTableCell>
            </S.TableRow>
            <S.TableRow>
              <S.AuthTableCell rowSpan={2} style={{ textAlign: "center" }}>
                문의관리
              </S.AuthTableCell>
              <S.AuthTableCell>1:1문의</S.AuthTableCell>
              <S.AuthTableCell>
                <S.Select
                  value={permissions.MN0011}
                  onChange={(e: SelectChangeEvent<unknown>) =>
                    handlePermissionChange(
                      e as SelectChangeEvent<string>,
                      "MN0011"
                    )
                  }
                >
                  <S.MenuItem value="N">없음</S.MenuItem>
                  <S.MenuItem value="R">조회</S.MenuItem>
                  <S.MenuItem value="U">조회+수정</S.MenuItem>
                </S.Select>
              </S.AuthTableCell>
              <S.AuthTableCell style={{ textAlign: "center" }}>
                운영자관리
              </S.AuthTableCell>
              <S.AuthTableCell>운영자조회/수정</S.AuthTableCell>
              <S.AuthTableCell>
                <S.Select
                  value={permissions.MN0013}
                  onChange={(e: SelectChangeEvent<unknown>) =>
                    handlePermissionChange(
                      e as SelectChangeEvent<string>,
                      "MN0013"
                    )
                  }
                >
                  <S.MenuItem value="N">없음</S.MenuItem>
                  <S.MenuItem value="R">조회</S.MenuItem>
                  <S.MenuItem value="U">조회+수정</S.MenuItem>
                </S.Select>
              </S.AuthTableCell>
            </S.TableRow>
            <S.TableRow>
              <S.AuthTableCell>신고관리</S.AuthTableCell>
              <S.AuthTableCell>
                <S.Select
                  value={permissions.MN0012}
                  onChange={(e: SelectChangeEvent<unknown>) =>
                    handlePermissionChange(
                      e as SelectChangeEvent<string>,
                      "MN0012"
                    )
                  }
                >
                  <S.MenuItem value="N">없음</S.MenuItem>
                  <S.MenuItem value="R">조회</S.MenuItem>
                  <S.MenuItem value="U">조회+수정</S.MenuItem>
                </S.Select>
              </S.AuthTableCell>
            </S.TableRow>
            <S.TableRow>
              <S.AuthTableCell rowSpan={2} style={{ textAlign: "center" }}>
                회원관리
              </S.AuthTableCell>
              <S.AuthTableCell>회원조회/수정</S.AuthTableCell>
              <S.AuthTableCell>
                <S.Select
                  value={permissions.MN0001}
                  onChange={(e: SelectChangeEvent<unknown>) =>
                    handlePermissionChange(
                      e as SelectChangeEvent<string>,
                      "MN0001"
                    )
                  }
                >
                  <S.MenuItem value="N">없음</S.MenuItem>
                  <S.MenuItem value="R">조회</S.MenuItem>
                  <S.MenuItem value="U">조회+수정</S.MenuItem>
                </S.Select>
              </S.AuthTableCell>
            </S.TableRow>
            <S.TableRow>
              <S.AuthTableCell>탈퇴회원관리</S.AuthTableCell>
              <S.AuthTableCell>
                <S.Select
                  value={permissions.MN0002}
                  onChange={(e: SelectChangeEvent<unknown>) =>
                    handlePermissionChange(
                      e as SelectChangeEvent<string>,
                      "MN0002"
                    )
                  }
                >
                  <S.MenuItem value="N">없음</S.MenuItem>
                  <S.MenuItem value="R">조회</S.MenuItem>
                  <S.MenuItem value="U">조회+수정</S.MenuItem>
                </S.Select>
              </S.AuthTableCell>
            </S.TableRow>
          </S.TableBody>
        </S.Table>
      </S.TableContainer>
      <S.BtnBox>
        <S.NormalButton>목록</S.NormalButton>
        <S.SaveButton onClick={handleSubmit}>저장</S.SaveButton>
      </S.BtnBox>
      <Popup {...popupOptions} />
    </S.Container>
  );
}
