// StyledComponents.js
import styled from "styled-components";
import * as M from "@mui/material";

export const Container = styled(M.Container)`
  && {
    padding: 0 20px;
  }
`;

export const Typography = styled(M.Typography)`
  && {
    font-size: 23px;
    margin: 20px 0 15px 0;
    font-weight: 700;
  }
`;

export const Paper = styled(M.Paper)`
  && {
    margin-bottom: 20px;
    // min-height: 200px;
    background-color: #f9f9f9;
    border: 1px solid #ededed;
  }
`;

export const Grid = styled(M.Grid)`
  && {
    display: flex;
    padding: 10px;
    margin-top: 10px;
    justify-content: flex-start;
    align-items: center;
    height: 55px;
  }
`;

export const Input = styled(M.Input)`
  width: 100%;
  height: 2.5em;
  border-radius: 4px;
  border: 1px solid lightgrey;
  padding: 0 10px;
  background-color: #ffffff;

  &&::before {
    content: none; // 기본 밑줄 제거
  }

  &&::after {
    content: none; // 포커스 시 밑줄 제거
  }
`;

export const InputLabel = styled(M.InputLabel)`
  && {
    width: 100px;
    margin-right: 15px;
    font-family: NanumGothic;
    font-size: 15px;
    color: #000000;
    font-weight: 700;
  }
`;

export const SearchButton = styled(M.Button)`
  && {
    height: 2.5em;
    border: none;
    background-color: #8989db;
    margin-bottom: 25px;
  }

  &&:hover {
    background-color: #6565d1; // 호버 시 배경색 변경
  }
`;

export const NormalButton = styled(M.Button)<{ active: boolean }>`
  && {
    height: 2.5em;
    border: none;
    background-color: ${(props) => (props.active ? "#8989db" : "#e0e0e0")};
    color: ${(props) => (props.active ? "white" : "#000000")};
    box-shadow: none;
  }

  &&:hover {
    background-color: #8989db; // 호버 시 배경색 변경
    color: white; // 호버 시 글자색 변경
  }
`;

export const Select = styled(M.Select)`
  && {
    height: 2.5em;
    background-color: #ffffff;
  }
`;

export const RowSelect = styled(M.Select)`
  && {
    height: 2.2em;
    padding: 0;
    background-color: #ffffff;
  }
`;

export const RadioGroup = styled(M.RadioGroup)`
  && {
    align-items: center;
  }
`;

export const MenuItem = styled(M.MenuItem)``;

export const TableContainer = styled(M.TableContainer)``;

export const Table = styled(M.Table)``;

export const TableHead = styled(M.TableHead)`
  &:hover {
    cursor: default;
    background-color: "";
  }
`;

export const TableHeadRow = styled.tr``;

export const TableRow = styled(M.TableRow)`
  && {
    background-color: #ffffff;
  }

  &:hover {
    cursor: pointer;
    background-color: #e5deec;
  }
`;

export const TableCell = styled(M.TableCell)`
  && {
    text-align: ${(props) =>
      props.align || "left"}; // props에 따라 텍스트 정렬 변경
  }
`;

export const TableHeadCell = styled(TableCell)`
  && {
    font-weight: bold;
  }
`;

export const TableSortLabel = styled(M.TableSortLabel)``;

export const TableBody = styled(M.TableBody)``;
